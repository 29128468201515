import React from "react";
import { workOrderStates } from "../../Helpers/Factory/workOrderFactory";
import { emptyField } from "../../resources/constants/messages";
import InputIcon from "./b_InputIcon";

function ViewWorkOrderForm({ form, piece }) {
  return (
    <div>
      <div className="columns">
        <div className="column is-half">
          <InputIcon
            key="0"
            name="piece"
            type={emptyField}
            placeholder="Pieza"
            isValid={true}
            icon="puzzle-piece"
            value={!piece ? form.piece : form.description}
            decimals={false}
            disabled={true}
            isTextArea={false}
          />
        </div>
        <div className="column">
          <InputIcon
            key="1"
            name="rev"
            type={emptyField}
            placeholder={!piece ? "Revisión" : "Proyecto"}
            isValid={true}
            icon="spell-check"
            value={!piece ? form.rev : form.projectId}
            decimals={false}
            disabled={true}
            isTextArea={false}
          />
        </div>
        <div className="column">
          <InputIcon
            key="2"
            name="quantity"
            type={emptyField}
            placeholder="Cantidad"
            isValid={true}
            icon="tasks"
            value={form.quantity}
            decimals={true}
            disabled={true}
            isTextArea={false}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-half">
          <InputIcon
            key="3"
            name="code"
            type={emptyField}
            placeholder="Código"
            isValid={true}
            icon="barcode"
            value={form.code}
            decimals={true}
            disabled={true}
            isTextArea={false}
          />
        </div>
        <div className="column">
          <InputIcon
            key="4"
            name="thickness"
            type={emptyField}
            placeholder="Grosor"
            isValid={true}
            icon="circle"
            value={form.thickness}
            decimals={true}
            disabled={true}
            isTextArea={false}
          />
        </div>
        <div className="column">
          <InputIcon
            key="5"
            name="width"
            type={emptyField}
            placeholder="Ancho"
            isValid={true}
            icon="arrows-alt-h"
            value={form.width}
            decimals={true}
            disabled={true}
            isTextArea={false}
          />
        </div>
        <div className="column">
          <InputIcon
            key="6"
            name="height"
            type={emptyField}
            placeholder="Altura"
            isValid={true}
            icon="arrows-alt-v"
            value={form.height}
            decimals={true}
            disabled={true}
            isTextArea={false}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <InputIcon
            key="7"
            name="pieceMaterial"
            type={emptyField}
            placeholder="Material"
            isValid={true}
            icon="sticky-note"
            value={form.pieceMaterial}
            decimals={false}
            disabled={true}
            isTextArea={false}
          />
        </div>
        <div className="column">
          <InputIcon
            key="8"
            name="pieceMaterialKind"
            type={emptyField}
            placeholder="Tipo de material"
            isValid={true}
            icon="sticky-note"
            value={form.pieceMaterialKind}
            decimals={false}
            disabled={true}
            isTextArea={false}
          />
        </div>
        <div className="column">
          <InputIcon
            key="9"
            name="metallicFinish"
            type={emptyField}
            placeholder="Acabado metálico"
            isValid={true}
            icon="sticky-note"
            value={form.metallicFinish}
            decimals={false}
            disabled={true}
            isTextArea={false}
          />
        </div>
        <div className="column is-half">
          <label className="label is-small mt-0">Operaciones</label>
          {form?.operations
            ? form.operations.map((item, index) => (
                <span className="tag is-dark mr-5" key={index}>
                  {!piece ? item.name : item.title}
                </span>
              ))
            : null}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        {form.productionTime ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "20px",
            }}
          >
            <label className="label is-small mt-0">Tiempo de producción</label>
            <div style={{ display: "flex" }}>
              {form.productionTime.split(".").length > 1 ? (
                <div>
                  <span>{form.productionTime.split(".")[0]} día, </span>
                  <span>
                    {form.productionTime.split(".")[1].split(":")[0]} hr,{" "}
                  </span>
                  <span>
                    {form.productionTime.split(".")[1].split(":")[1]} min
                  </span>
                </div>
              ) : (
                <div>
                  <span>{form.productionTime.split(":")[0]} hr, </span>
                  <span>{form.productionTime.split(":")[1]} min</span>
                </div>
              )}
            </div>
          </div>
        ) : null}
        {!piece ? (
          <div>
            <label className="label is-small mt-0">Estado</label>
            <span className={`tag is-${workOrderStates[form.status].class}`}>
              {workOrderStates[form.status].name}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ViewWorkOrderForm;
