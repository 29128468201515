export const removeIsSelectedClass = (table, className) => {
  if (table.current) {
    for (let index = 0; index <= table.current.rows.length; index++) {
      if (table.current.rows[index]) {
        if (table.current.rows[index].className === className) {
          table.current.rows[index].className = "";
        }
      }
    }
  }
};

const scrollIntoView = (table, counter) => {
  if (table.current) {
    table.current.rows[counter].scrollIntoView({
      block: "center",
      behavior: "auto",
      inline: "center",
    });
  }
};

export const onDownKey = (table, counter, key, className) => {
  if (table.current) {
    removeIsSelectedClass(table, className);
    if (counter === -1 && key === "up") counter = -2;
    if (counter === -1) counter = 0;
    if (key === "up") counter += 2;
    if (table.current.rows[counter]) {
      table.current.rows[counter].className = className;
      scrollIntoView(table, counter);
    }
  }

  return counter + 1;
};

export const onUpKey = (table, counter, key, className) => {
  if (table.current) {
    removeIsSelectedClass(table, className);
    if (key === "down") counter -= 2;
    if (table.current.rows[counter]) {
      table.current.rows[counter].className = className;
      scrollIntoView(table, counter);
    }
  }

  return counter - 1;
};
