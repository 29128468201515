import React, { useEffect, useState } from "react";
import { GetProjectsApi } from "../../Services/ProjectService";
import SearchBar from "../1-blocks/b_searchBar";
import { OK } from "../../resources/constants/messages";
import authService from "../api-authorization/AuthorizeService";
import * as roles from "../../resources/constants/roles";
import { useSelector } from "react-redux";

export default function ProjectFilter({
  onSelect,
  className = "",
  title = "Seleccionar proyecto",
  useDefault = false,
}) {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [defaultSelection, setDefaultSelection] = useState("");
  const projectId = useSelector((state) => state.projectId);

  useEffect(() => {
    const user = authService.getUser();
    GetProjectsApi().then((result) => {
      if (result.status === OK) {
        let projects = result.data;
        if (user.role === roles.Design) {
          // filtrar por proyecto de diseñador
          projects = result.data.filter((p) =>
            p.designers.find((d) => d.email === user.email)
          );
        }

        const data = projects.map((item) => ({
          id: item.code,
          name: `${item.projectCode}`,
        }));
        setFilterData(data);
        setUnfilteredData(data);
        setProjects(projects);
      }

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    // selecciona el proyecto por default
    if (!useDefault) return;
    if (projects.length > 0) {
      if (projects.find((f) => f.code === projectId.id)) {
        setDefaultSelection(projectId.id);
        setFilterData(() => {
          return unfilteredData.filter((p) =>
            p.name.toLowerCase().includes(projectId.code.toLowerCase())
          );
        });
      } else onSelect();
    }
  }, [projectId, projects]);

  const onKeyUp = ({ value }) => {
    if (value)
      setFilterData(() => {
        return unfilteredData.filter((p) =>
          p.name.toLowerCase().includes(value.toLowerCase())
        );
      });
    else setFilterData(unfilteredData);
  };

  const onSelectChildren = (value) => {
    if (value) {
      const project = projects.find((p) => p.code === value.id);
      onSelect({
        id: value.id,
        code: project.projectCode,
        name: project.title,
        clientCode: project.clientCode,
      });
    } else onSelect(value);
  };

  return (
    <div className={className}>
      {!loading ? (
        <>
          <label className="label is-small">{title}</label>
          <SearchBar
            className="mb-0"
            name="searchBar"
            onKeyUp={onKeyUp}
            errorMessage="No se han encontrado resultados"
            placeholder="Filtro por proyecto"
            filterData={filterData}
            onSelect={onSelectChildren}
            defaultSelection={defaultSelection}
          />
        </>
      ) : (
        <div>Cargando...</div>
      )}
    </div>
  );
}
