export const UserObject = {
  phoneNumber: "",
  normalizedEmail: "",
  email: "",
  normalizedUserName: "",
  userName: "",
  id: "",
  fullName: "",
  role: "",
};

export const UpdateRoleObject = {
  userId: "",
  role: "",
};

export const buildRoleForSelect = (role) => ({
  id: role.value,
  name: role.text,
});

export const buildRoleForGroupedSelect = (role) => ({
  label: role[0].group.name,
  options: role.map((rolsito) => buildRoleForSelect(rolsito)),
});

export const buildUserForSelect = (user) => ({
  id: user.id,
  name: user.userName,
});

export const buildUserFromToken = (user) => ({
  name: user.FullName,
  sub: user[
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
  ],
  role: user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
  email: user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
  jti: user.jti,
});

export const buildUserForTable = (user) => ({
  Id: user.id,
  Nombre: user.fullName ? user.fullName : "---",
  Email: user.email,
  Rol: user.role,
});

export const sortingUser = {
  Id: ["idAsc", "idDesc"],
  Nombre: ["nameAsc", "nameDesc"],
  Email: ["emailAsc", "emailDesc"],
  Rol: ["roleAsc", "roleDesc"],
};
