import {
  authorizationError,
  BAD_GATEWAY,
  BAD_REQUEST,
  comunicationError,
  NO_AUTH,
  OK,
} from "../../resources/constants/messages";
import authService from "../../components/api-authorization/AuthorizeService";
import { asyncResponse, DELETE, FORM, GET, POST } from "./serviceConstants";
import { GetUrl } from "./urlConstants";

const RequestOptions = (type, data, token = "") => {
  let requestOptions = {};
  switch (type) {
    case GET:
      requestOptions = {
        method: GET,
      };
      break;
    case POST:
      requestOptions = {
        method: POST,
        headers: {
          "Content-Type": "application/problem+json; charset=utf-8",
        },
        body: JSON.stringify(data),
      };
      break;
    case DELETE:
      requestOptions = {
        method: DELETE,
      };
      break;
    case FORM:
      requestOptions = {
        method: POST,
        body: data,
      };
      break;
    default:
      break;
  }
  if (token !== "") {
    requestOptions.headers = {
      ...requestOptions.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return requestOptions;
};

export const fetchMethod = async (
  url,
  type = GET,
  data = {},
  isAuthenticated = true
) => {
  let token = "";
  if (isAuthenticated) token = await authService.getAccessToken();
  let request = RequestOptions(type, data, token);
  const urlHost = GetUrl();
  try {
    const response = await fetch(urlHost + url, request);
    if (!response.ok) throw response;
    return asyncResponse(OK, await response.json());
  } catch (err) {
    if (err.status === BAD_REQUEST) {
      let errorMessage = Promise.resolve(
        err.text().then((errorMessage) => {
          return errorMessage;
        })
      );
      return asyncResponse(BAD_REQUEST, {}, await errorMessage);
    } else if (err.status === NO_AUTH) {
      return asyncResponse(NO_AUTH, {}, authorizationError);
    } else {
      return asyncResponse(BAD_GATEWAY, {}, comunicationError);
    }
  }
};
