import React, { useEffect } from "react";
import DataTable from "../../2-systems/s_DataTable";
import {
  buildWorkOrderForTable,
  sortingWorkOrder,
  workOrderStates,
} from "../../../Helpers/Factory/workOrderFactory";
import { GetFileMid } from "../../../redux/middleware/file.mid";
import WithTablesData from "../../HOC/WithTableData";
import { GetWorkOrderByProjectWithSpecApi } from "../../../Services/WorkOrderService";
import { useHistory } from "react-router-dom";
import { refresh_route } from "../../../redux/ducks/route";
import { links } from "../../../resources/constants/links";
import routes from "../../../resources/constants/routes";
import ButtonIcon from "../../1-blocks/b_ButtonIcon";
import { GetFileWorkOrder } from "../../../redux/middleware/workOrders.mid";
import { useSelector } from "react-redux";
import SelectProjectMessage from "../../1-blocks/b_selectProjectMessage";

function WorkOrders({
  tableContent,
  onSort,
  pageCount,
  onChangeQuery,
  dispatch,
  count,
}) {
  const history = useHistory();
  const projectId = useSelector((state) => state.projectId);

  useEffect(() => {
    dispatch(refresh_route(routes[links.workOrders.name]));
  }, []);

  const onClick = async (items, name) => {
    switch (name) {
      case "Ver orden de trabajo":
        history.push(`/workOrder/${items[0]}`);
        break;
      case "Descargar pdf":
        dispatch(GetFileMid(items[0]));
        break;
      default:
        break;
    }
  };

  const onDownloadFile = () => {
    dispatch(GetFileWorkOrder(projectId.id));
  };

  if (projectId.id) {
    return (
      <div className="is-flex is-justify-content-center">
        <DataTable
          data={tableContent}
          count={count}
          onClick={onClick}
          hasCheckbox={false}
          onChangeQuery={onChangeQuery}
          onSort={onSort}
          pageCount={pageCount}
          buttons={[
            { name: "Ver orden de trabajo", class: "info", icon: "eye" },
            { name: "Descargar pdf", class: "dark", icon: "file-pdf" },
          ]}
          states={workOrderStates}
          columnState={5}
        >
          <div className="is-flex is-flex-direction-column is-align-items-center mb-5">
            <h4 className="title is-4 mb-0">{projectId.code}</h4>
            <label className="label has-text-grey-light">
              {projectId.name}
            </label>
          </div>

          <div className="is-flex is-flex-direction-row-reverse">
            <div className="is-flex is-justify-content-flex-end mb-3">
              <ButtonIcon
                icon={"file-excel"}
                name="Descargar"
                className="is-dark mr-2 is-small"
                onClick={onDownloadFile}
              />
              <ButtonIcon
                icon="building"
                onClick={() => history.push(links.createWorkOrder.to)}
                className="is-primary is-small"
                name="Crear orden de trabajo"
              />
            </div>
          </div>
        </DataTable>
      </div>
    );
  } else return <SelectProjectMessage />;
}

export default WithTablesData(
  WorkOrders,
  GetWorkOrderByProjectWithSpecApi,
  buildWorkOrderForTable,
  sortingWorkOrder,
  "projectId"
);
