import { buildDate, buildNormalDate } from "../index";

export const projectStates = [
  { name: "Activo", class: "primary" },
  { name: "En pausa", class: "warning" },
  { name: "Cerrado", class: "danger" },
];

export const ProjectObject = {
  code: "",
  title: "",
  description: "",
  managerId: 0,
  startDate: null,
  plannedFinishDate: buildNormalDate(new Date()),
  createDate: buildNormalDate(new Date()),
};

export const buildProjectForTable = (project) => ({
  id: project?.code,
  Código: project?.projectCode,
  Titulo: project.title,
  Inicio: buildDate(project.startDate),
  "Posible término": buildDate(project.plannedFinishDate),
  Estado: project.status,
});

export const buildProjectForSelect = (project) => ({
  id: project.code,
  name: project.title,
});

export const buildProject = (project) => ({
  code: project.code,
  title: project.title,
  description: project.description,
  designers: project.designers,
  manager: project.manager.userName,
  startDate: buildDate(project.startDate),
  plannedFinishDate: buildDate(project.plannedFinishDate),
  createDate: buildDate(project.createDate),
  closeDate:
    project.closeDate !== null
      ? buildDate(project.closeDate)
      : "El proyecto aún sigue en curso",
  status: projectStates[project.status],
});

export const sortingProject = {
  Código: ["idAsc", "idDesc"],
  Titulo: ["titleAsc", "titleDesc"],
  Inicio: ["startDateAsc", "startDateDesc"],
  "Posible término": ["CloseDateAsc", "CloseDateDesc"],
  Estado: ["statusAsc", "statusDesc"],
};
