import React, { useEffect, useState } from "react";
import WithContextData from "../../HOC/WithContextData";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import { buildUserSelect } from "../../../Helpers/Factory/selectFactory";
import ProjectForm from "../../5-forms/projectForm";
import { GetApi, PostApi } from "../../../redux/middleware/httpMethodApi";
import { GetUsersByRoleApi } from "../../../Services/SuperUserService";
import { ProjectManager } from "../../../resources/constants/roles";
import { Toast } from "../../../Helpers";
import { CreateProjectApi } from "../../../Services/ProjectService";
import { buildNormalDate } from "../../../Helpers";
import { confirmMessage } from "../../../Helpers/sweetAlertHandler";

function CreateProject({
  apiData,
  dispatch,
  refresh_route,
  _routes,
  links,
  history,
}) {
  const [managers, setManagers] = useState([]);

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    dispatch(refresh_route(_routes[links.createProject.name]));
    dispatch(GetApi([ProjectManager], GetUsersByRoleApi));
  }, []);

  useEffect(() => {
    if (Array.isArray(apiData.data)) {
      let users = apiData.data.map((item, index) =>
        buildUserSelect(item, index)
      );
      setManagers(users);
    }
  }, [apiData.data]);
  /* #endregion */

  /* #region  CONFIRMACIÓN DE CREAR */
  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      Toast.fire(ToastObject("Se ha creado con éxito su proyecto", "success"));
      history.goBack();
    }
  }, [apiData.data]);
  /* #endregion */

  const onSubmit = async (project) => {
    const result = await confirmMessage(
      `¿Estas seguro de crear el proyecto ${project.projectCode}?`,
      `El código de proyecto debe de ser único`,
      "warning"
    );
    if (result) {
      dispatch(
        PostApi(
          [{ ...project, createDate: buildNormalDate(new Date()) }],
          CreateProjectApi
        )
      );
    }
  };

  return (
    <ProjectForm
      onSubmit={onSubmit}
      title="Crear proyecto"
      managers={managers}
    />
  );
}

export default WithContextData(CreateProject);
