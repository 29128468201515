/* #region  IMPORTS */
import React, { useEffect, useState } from "react";
import logo from "../../resources/images/zamtest/blue.png";
import { refresh_route } from "../../redux/ducks/route";
import routes from "../../resources/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  NotificationSubscribeMid,
  NotificationUnsubscribeMid,
} from "../../redux/middleware/notification";
import authService from "../api-authorization/AuthorizeService";
import { getNotificationPermissions } from "../../Helpers/notification";
import { useHistory } from "react-router";
import * as roles from "../../resources/constants/roles";
import { links } from "../../resources/constants/links";
/* #endregion */

function NavBar(props) {
  const history = useHistory();
  const route = useSelector((state) => state.route),
    dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const user = authService.getUser();
    if (user) {
      setUserId(user.sub);
      setUserName(user.role === roles.Root ? user.role : user.name);
    }
  }, [route]);

  const onChange = (e) => {
    let op;
    if (e.target.checked) {
      op = window.confirm("Deseas activar las notificaciones?");
      if (op) dispatch(NotificationSubscribeMid(userId));
      else e.target.checked = false;
    } else {
      op = window.confirm("Deseas desactivar las notificaciones?");
      if (op) dispatch(NotificationUnsubscribeMid(userId));
      else e.target.checked = true;
    }
  };

  return (
    <div className="navbar navbar_eqspaced">
      <div style={{ width: "100%" }}>
        <div
          className="is-flex is-justify-content-center"
          style={{
            height: "80px",
          }}
        >
          <img className="logo_navbar" src={logo} alt="" />

          <div
            className="dropdown is-hoverable"
            style={{ position: "absolute", right: "60px", top: "20px" }}
          >
            <div className="dropdown-trigger">
              <button
                className="button is-info button-options is-outlined"
                aria-haspopup="true"
                aria-controls="dropdown-menu3"
              >
                <span>Hola, {userName ? userName : "usuario"}!</span>
                <span className="icon is-small">
                  <i className="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div
              className="dropdown-menu"
              id="dropdown-menu3"
              role="menu"
              style={{
                marginTop: "0px",
                position: "absolute",
                right: "0",
                left: "auto",
              }}
            >
              <div className="dropdown-content has-background-white has-text-color-black">
                {props.isRoot ? (
                  <a className="dropdown-item" href={links.createUser.to}>
                    Registrar usuario
                  </a>
                ) : null}

                <a className="dropdown-item" href={links.changePassword.to}>
                  Cambiar contraseña
                </a>

                <div className="dropdown-item">
                  <label className="m-0">
                    <input
                      style={{ margin: "5px 0 0 30px", float: "right" }}
                      type="checkbox"
                      onChange={onChange}
                      defaultChecked={
                        getNotificationPermissions() === "granted"
                      }
                    ></input>
                    Notificaciones
                  </label>
                </div>
                <a className="dropdown-item" onClick={props.onLogout}>
                  <p>Salir</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="link-pages">
          {route
            ? route.map((element, index) =>
                index === 0 ? (
                  <span
                    style={{ cursor: "pointer" }}
                    key={index}
                    onClick={() => {
                      history.push(element[1]);
                      dispatch(refresh_route(routes[element[0]]));
                    }}
                    className={"route_link"}
                  >
                    {element[0]}
                  </span>
                ) : (
                  <div style={{ display: "flex" }} key={index}>
                    <span> / </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push(element[1]);
                        dispatch(refresh_route(routes[element[0]]));
                      }}
                      className={"route_link"}
                    >
                      {element[0]}
                    </span>
                  </div>
                )
              )
            : []}
        </div>
      </div>
    </div>
  );
}

export default NavBar;
