/* #region  IMPORTS */
import React, { useEffect, useState } from "react";
import {
  projectStates,
  sortingProject,
} from "../../../Helpers/Factory/projectFactory";
import { GetProjectsMid } from "../../../redux/middleware/projects.mid";
import ButtonContainer from "../../1-blocks/b_ButtonContainer";
import authService from "../../api-authorization/AuthorizeService";
import DataTable from "../../2-systems/s_DataTable";
import WithContextData from "../../HOC/WithContextData";
import useMounted from "../../../hooks/useMounted";
import { useSelector } from "react-redux";
/* #endregion */

function Projects({
  apiData,
  history,
  dispatch,
  refresh_route,
  _routes,
  links,
  helpers,
}) {
  const [tableData, setTableData] = useState();
  const [role, setRole] = useState();
  const [queryParams, setQueryParams] = useState({
    pageSize: 5,
    pageIndex: 1,
    search: "",
  });
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const mounted = useMounted();
  const dataTableSelection = useSelector((state) => state.dataTableSelection);

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    if (mounted) {
      dispatch(refresh_route(_routes[links.projects.name]));
      setTableData([]);
      return () => {
        setTableData([]);
      };
    }
  }, [mounted]);

  useEffect(() => {
    if (
      Array.isArray(apiData.data?.data) &&
      apiData.data.data.length > 0 &&
      apiData.data.data[0]?.Inicio
    ) {
      setTableData(apiData.data.data);
      setPageCount(apiData.data.pageCount);
      setCount(apiData.data.count);
    } else {
      setTableData([]);
    }
  }, [apiData.data]);

  useEffect(() => {
    if (mounted && queryParams.pageSize === parseInt(dataTableSelection)) {
      (async () => {
        const user = await authService.getUser();
        if (queryParams && user) {
          dispatch(GetProjectsMid(queryParams, user.name, user.role));
          setRole(user.role);
        }
      })();
    }
  }, [queryParams, mounted]);

  /* #endregion */

  const onClickDataTableButton = (rowData, item) => {
    let [id] = rowData;
    switch (item) {
      case "Editar":
        history.push(links.editProject.to + "/" + id);
        break;
      default:
        break;
    }
  };

  const onSort = (column, upDown, name) => {
    let variables = Object.keys(sortingProject);
    variables.forEach((item) => {
      if (item === name) {
        let position = sortingProject[item];
        setQueryParams({
          ...queryParams,
          sort: !upDown ? position[0] : position[1],
        });
      }
    });
  };

  const onChangeQuery = (query) => setQueryParams(query);

  if (mounted) {
    return (
      <div className="is-flex is-justify-content-center">
        <DataTable
          data={tableData}
          count={count}
          onClick={onClickDataTableButton}
          onChangeQuery={onChangeQuery}
          pageCount={pageCount}
          buttons={[{ name: "Editar", class: "info", icon: "edit" }]}
          states={projectStates}
          columnState={5}
          visibleColumn={false}
          onSort={onSort}
        >
          <h1 className="title is-4 is-flex is-justify-content-center">
            Proyectos
          </h1>
          {helpers.isProjectManager(role) ? (
            <ButtonContainer>
              <button
                className="button is-info"
                onClick={() => history.push(links.createProject.to)}
              >
                Crear proyecto
              </button>
            </ButtonContainer>
          ) : null}
        </DataTable>
      </div>
    );
  } else return null;
}

export default WithContextData(Projects);
