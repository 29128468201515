import React from "react";

export default function PasswordValidator({ text, isValid }) {
  return (
    <div className="is-flex is-align-items-center">
      <span className="icon is-medium">
        <span className="fa-stack small">
          <i
            className={`fas fa-circle fa-stack-2x has-text-${
              isValid ? "success" : "danger"
            }`}
          ></i>
          <i
            className={`fas fa-${
              isValid ? "check" : "times"
            } fa-stack-1x fa-inverse`}
          ></i>
        </span>
      </span>

      <span className="ml-3">{text}</span>
    </div>
  );
}
