export const aLengthMenu = [
  [5, 10, 25, 50, 100, -1],
  [5, 10, 25, 50, 100, "Todas"],
];

export const iDisplayLength = 5;

export const language = {
  lengthMenu: "Mostradas _MENU_ entradas",
  zeroRecords: "Ups... Parece que aquí no está lo que buscabas",
  info: "Página _PAGE_ de _PAGES_, total: _MAX_ entradas",
  infoEmpty: "No hay entradas disponibles",
  infoFiltered: "(filtrado de un total de _MAX_ entradas)",
  search: "Buscar",
  paginate: {
    previous:
      '<span class="fas fa-chevron-circle-left" style="color: #3e8ed0"></span>',
    next: '<span class="fas fa-chevron-circle-right" style="color: #3e8ed0"></span>',
  },
};

export const dom =
  '<"dt-top-container"<l><"dt-center-in-div"B><f>r>t<"dt-filter-spacer"><ip>';

export const buttons = [
  {
    extend: "excelHtml5",
    text: '<span class="icon is-small"><i class="fas fa-file-excel"></i></span><span>Exportar a excel</span>',
    className: "button is-dark",
  },
];
//buttons: 1
//  buttons= {[{ name: "Aceptar", class: "primary", icon: "check" }]}
//buttons: > 1
//  buttons={[
//   { name: "Aceptar", class: "primary", icon: "check" },
//   { name: "Borrar", class: "danger", icon: "window-close" },
// ]}
//states
// let states = [
//   ["Trabajando", "warning"],
//   ["Completada", "primary"],
// ];

export const selectOptions = [
  { name: "5", id: 1 },
  { name: "10", id: 2 },
  { name: "25", id: 3 },
  { name: "50", id: 4 },
];
