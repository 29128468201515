import { buildUserFromToken } from "../../Helpers/Factory/userFactory";

export class AuthorizeService {
  _callbacks = [];
  _nextSubscriptionId = 0;
  _user = null;
  _isAuthenticated = false;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;

  saveCookie(token, expiration) {
    document.cookie = `Zamtest_access_token=${token};expires=${expiration}`;
  }

  deleteCookie() {
    document.cookie = `Zamtest_access_token=;expires=01 Jan 1970 00:00:00 UTC`;
  }

  isAuthenticated() {
    let token = this.getAccessToken();
    if (token) {
      let exp = this.getUser().exp * 1000;
      let date = new Date(exp);
      if (date < new Date()) {
        return false;
      } else return true;
    } else return false;
  }

  getUser() {
    let user = this.parseJwt();
    user = buildUserFromToken(user);
    return user;
  }

  getAccessToken() {
    return this.ensureUserManagerInitialized();
  }

  ensureUserManagerInitialized() {
    const cookies = document.cookie.split(";");
    let token = "";
    cookies.forEach((item) => {
      if (item.includes("Zamtest_access_token")) {
        token = item.split("=")[1];
        if (token === "undefined") token = "";
      }
    });
    return token;
  }

  parseJwt() {
    let token = this.getAccessToken();
    if (token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    }
    return false;
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
  Redirect: "redirect",
  Success: "success",
  Fail: "fail",
};
