import React from "react";

function UploadFile({
  handleFileUpload,
  accept = ".csv",
  className = "",
  title = "Subir archivo",
  loading = false,
}) {
  return (
    <>
      {!loading ? (
        <div className={`file is-warning ${className}`}>
          <label className="file-label">
            <input
              className="file-input"
              type="file"
              name="resume"
              accept={accept}
              onChange={handleFileUpload}
            />
            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload"></i>
              </span>
              <span className="file-label">{title}</span>
            </span>
          </label>
        </div>
      ) : (
        <div className="button is-warning is-loading">Cargando...</div>
      )}
    </>
  );
}

export default UploadFile;
