import * as urlConstants from "./helpers/urlConstants";
import authService from "../components/api-authorization/AuthorizeService";
import { fetchMethod } from "./helpers/fetchMethod";
import { GET, POST } from "./helpers/serviceConstants";
import setDataTableQuery from "./helpers/setDataTableQuery";

export const CreatePieceApi = async (data) => {
  return await fetchMethod(urlConstants.CreatePiece, POST, data);
};

export const UpdatePieceApi = async (data) => {
  return await fetchMethod(urlConstants.UpdatePiece, POST, data);
};

export const GetPieceApi = async (id) => {
  return await fetchMethod(urlConstants.GetPiece + id);
};

export const GetPiecesApi = async () => {
  return await fetchMethod(urlConstants.GetPieces);
};

export const GetPiecesByProjectApi = async (projectId) => {
  return await fetchMethod(urlConstants.GetPiecesByProject + projectId);
};

export const GetPiecesWithSpecApi = async (query, projectId) => {
  let queryString = setDataTableQuery(query);
  queryString = queryString + "&ProjectId=" + projectId;
  return await fetchMethod(urlConstants.GetPiecesWithSpec + queryString);
};

export const DeletePieceApi = async (id) =>
  await fetchMethod(urlConstants.DeletePiece + id, POST);

const RequesOptions = (token) => {
  return {
    method: GET,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const GetPiecesFileByProjectApi = async (projectId) => {
  const token = await authService.getAccessToken();
  let request = RequesOptions(token);

  const urlHost = urlConstants.GetUrl();
  const response = await fetch(
    urlHost + urlConstants.GetPiecesFileByProject + projectId,
    request
  );
  try {
    if (!response.ok) throw response;
    const data = await response.blob();
    var url = window.URL.createObjectURL(data);
    let fileName = "Piezas_" + projectId + ".xlsx";
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.removeChild();
    return true;
  } catch (err) {
    return false;
  }
};
