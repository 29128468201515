export const Director = "Director";
export const AdminProjectManager = "ProjectManagerGerency";
export const ProjectManager = "ProjectManager";
export const AdminQuality = "QualityCntrlManager";
export const Quality = "QualityCntrl";
export const AdminDesign = "DesignerManager";
export const Design = "Designer";
export const AdminPlanning = "PlanningManager";
export const Planning = "Planning";
export const Metrology = "Metrology";
export const Warehouse = "Storer";
export const Operations = "MaqZamOp";
export const Root = "SuperUser";
export const Roles = [
  Director,
  AdminProjectManager,
  ProjectManager,
  AdminQuality,
  Quality,
  AdminDesign,
  Design,
  AdminPlanning,
  Planning,
  Metrology,
  Warehouse,
  Operations,
  Root,
];
