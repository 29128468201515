import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "../../../0-atoms/a_formInput";
import { useEffect } from "react";
import { refresh_route } from "../../../../redux/ducks/route";
import routes from "../../../../resources/constants/routes";
import { links } from "../../../../resources/constants/links";
import { isEmpty, isZamtestEmail } from "../../../../Helpers/Validators";
import styles from "./createUser.module.scss";
import { GetApi, PostApi } from "../../../../redux/middleware/httpMethodApi";
import { GetRolesApi } from "../../../../Services/SuperUserService";
import { buildRoleForGroupedSelect } from "../../../../Helpers/Factory/userFactory";
import GroupedSelect from "../../../0-atoms/a_groupedSelect";
import PasswordInput from "../../../1-blocks/b_passwordInput";
import ButtonIcon from "../../../1-blocks/b_ButtonIcon";
import { ToastError } from "../../../../Helpers/sweetAlertHandler";
import { RegisterAdminApi } from "../../../../Services/authenticationService";
import WithContextData from "../../../HOC/WithContextData";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Toast } from "../../../../Helpers";
import { ToastObject } from "../../../../Helpers/Factory/swalFactory";

const CreateUser = () => {
  const dispatch = useDispatch();
  const apiData = useSelector((state) => state.apiData);
  const [roles, setRoles] = useState([]);
  const [form, setForm] = useState({
    email: "",
    userRoleName: "",
    password: "",
    confirmPassword: "",
    fullName: "",
  });
  const history = useHistory();

  useEffect(() => {
    dispatch(refresh_route(routes[links.createUser.name]));
    dispatch(GetApi([], GetRolesApi));
  }, []);

  useEffect(() => {
    if (!apiData.loading && apiData.ok && Array.isArray(apiData.data)) {
      var roles = apiData.data.map((item) => buildRoleForGroupedSelect(item));
      setRoles(roles);
    }
  }, [apiData]);

  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      Toast.fire(
        ToastObject(
          `Se ha creado su usuario ${form.fullName} con éxito`,
          "success"
        )
      );
      history.push(links.users.to);
    }
  }, [apiData.data]);

  const onChange = (value, name) => {
    // console.log(value);
    setForm({ ...form, [name]: value });
  };

  const onSelectChange = (value) => {
    if (value !== "Selecciona un rol")
      setForm({ ...form, userRoleName: value });
    else setForm({ ...form, userRoleName: "" });
  };

  const onSubmit = () => {
    if (form?.password !== form?.confirmPassword) {
      ToastError("La confirmación de contraseña no es correcta");
      return;
    }

    if (form?.userRoleName === "") {
      ToastError("Debes de seleccionar un rol antes de enviar el formulario");
      return;
    }

    if (form?.fullName === "" || !isZamtestEmail(form?.email)) {
      ToastError(
        "Completa el formulario correctamente, todos los campos son obligatorios"
      );
      return;
    }

    const data = {
      email: form.email,
      password: form.password,
      userRoleName: form.userRoleName,
      fullName: form.fullName,
    };
    dispatch(PostApi([data], RegisterAdminApi));
  };

  return (
    <div className="is-flex is-justify-content-center">
      <div className={`box p-5 m-5 has-background-white ${styles.container}`}>
        <header className="is-flex is-justify-content-center">
          <h2 className="title is-4">Crear usuario</h2>
        </header>
        <br />
        <div className="columns">
          <div className="column is-two-thirds">
            <FormInput
              title={"Nombre completo"}
              name="fullName"
              icon="user"
              placeholder="Federico Gonzales"
              onCheckError={isEmpty}
              errorMessage={"Debes de colocar el nombre completo"}
              onChange={onChange}
            />
          </div>
          <div className="column">
            <label className="label">Selecciona un rol</label>
            <GroupedSelect
              className="is-fullwidth"
              title="Selecciona un rol"
              name="roleName"
              icon="users"
              data={roles}
              onChange={onSelectChange}
            />
          </div>
        </div>
        <FormInput
          title={"Correo"}
          name="email"
          icon="user"
          placeholder="Correo@zamtest.com"
          onCheckError={isZamtestEmail}
          errorMessage={
            "Debes de colocar un correo valido ejemplo: correo@zamtest.com"
          }
          onChange={onChange}
        />

        <PasswordInput onChange={onChange} name="password" onEnter={onSubmit} />

        <footer className="is-flex is-justify-content-center mt-5">
          <ButtonIcon
            className={"is-success"}
            name="Crear usuario"
            icon="user"
            onClick={onSubmit}
          />
        </footer>
      </div>
    </div>
  );
};

export default WithContextData(CreateUser);
