import React, { useEffect, useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";
import { buildDataForChart } from "../../Helpers/Factory/chartFactory";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={8} dy={8} textAnchor="middle" fill={fill}>
        {payload.name} {(percent * 100).toFixed(2)}%
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {value}
      </text>
    </g>
  );
};

function PieCharts({ data, colors, stack }) {
  const [state, setState] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [pieStack, setPieStack] = useState(1);
  const [dataOk, setDataOk] = useState(false);
  useEffect(() => {
    let counter = 0;
    setChartData(buildDataForChart(data, colors));
    Object.values(data).map(function (value) {
      if (value !== 0) counter++;
      return true;
    });
    if (counter) setDataOk(true);
    else setDataOk(false);
    if (stack) setPieStack(stack);
  }, [data, stack, colors]);
  const onPieEnter = (_, index) => {
    setState(index);
  };
  return dataOk ? (
    //////////Necesita un contenedor padre llamado ResponsiveContainer, parte de recharts/////////
    <ResponsiveContainer width={"99%"} height={`${99 / pieStack}%`}>
      <PieChart>
        <Pie
          activeIndex={state}
          activeShape={renderActiveShape}
          data={chartData}
          cx="50%"
          cy="50%"
          innerRadius={"33%"}
          outerRadius={"50%"}
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  ) : (
    <h1 className="title is-5 is-flex is-justify-content-center py-5">
      No hay datos disponibles
    </h1>
  );
}

export default PieCharts;
