import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 10,
      error: null,
      errorInfo: null,
    };
  }
  setCounter(number) {
    setTimeout(() => {
      number -= 1;
      this.setState({
        counter: number,
        error: null,
        errorInfo: null,
      });
      if (number > 0) this.setCounter(number);
    }, 1000);
  }
  componentDidMount() {
    let number = 10;
    this.setCounter(number);
  }

  componentWillUnmount() {
    this.setState({
      counter: 10,
      error: null,
      errorInfo: null,
    });
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div style={{ height: "100vh" }}>
          <h1 className="is-flex is-justify-content-center title pt-5">
            Algo salió mal
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className="button is-dark"
              onClick={() => document.location.reload(true)}
            >
              <span className="icon is-small">
                <i className={`fas fa-undo`}></i>
              </span>
              <span>Recargar página</span>
            </button>

            <div
              style={{
                fontSize: "25px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Ocurrió un problema dentro de la aplicación</p>
              <p>Te robaremos tu IP para rastrearte en:</p>
              <p style={{ fontSize: "65px" }}>{this.state.counter}</p>
              {parseInt(this.state.counter) === 0 ? (
                <>
                  <p>Te la creiste wei, no sabemos hacer eso...</p>
                  <p>
                    <strong>aún.</strong>
                  </p>
                </>
              ) : null}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
