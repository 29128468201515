import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types"; // ES6
import { Link } from "react-router-dom";
import ButtonIcon from "../1-blocks/b_ButtonIcon";
import Select from "../0-atoms/a_select";
import Input from "../0-atoms/a_input";
import Pagination from "../1-blocks/b_pagination";
import { info } from "../../resources/constants/colors";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSelection } from "../../redux/ducks/dataTableSelection";
import useMounted from "../../hooks/useMounted";

const DataTable = forwardRef(
  (
    {
      data,
      className,
      hasCheckbox = false,
      onClick,
      buttons = [],
      visibleColumn = true,
      states = [],
      columnState,
      onSort,
      onChangeQuery,
      pageCount,
      rowColors,
      columnColor,
      count = 0,
      hasBox = true,
      onClickLink = () => {},
      FilterComponent = null,
      ...props
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const mainCheckBoxRef = useRef(null);
    const dataTableSelection = useSelector((state) => state.dataTableSelection);
    const [hasData, setHasData] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [queryParams, setQueryParams] = useState({
      pageSize: dataTableSelection,
      pageIndex: 1,
      search: "",
    });
    const selectOptions = [
      { name: "5", id: 1 },
      { name: "10", id: 2 },
      { name: "25", id: 3 },
      { name: "50", id: 4 },
      { name: "100", id: 5 },
    ];
    const [selected, setSelected] = useState(1);
    const mounted = useMounted();

    useEffect(() => {
      const id = selectOptions.find(
        (x) => parseInt(x.name) === dataTableSelection
      ).id;
      setSelected(id);
      setQueryParams({ ...queryParams, pageSize: dataTableSelection });
    }, [dataTableSelection]);

    const getColumns = () => {
      let columns = [];
      if (Array.isArray(data) && data.length > 0) {
        if (hasCheckbox) columns.push("");
        Object.keys(data[0]).forEach((item) => {
          columns.push(item);
        });
        if (!hasCheckbox && buttons.length > 0) columns.push("");
      }
      return columns;
    };

    const getData = () => {
      let dataTable = [];
      if (Array.isArray(data) && data.length > 0) {
        data.forEach((item) => {
          let properties = Object.keys(item);
          let newData = [];
          properties.forEach((property) => {
            newData.push(item[property]);
          });
          if (!hasCheckbox && buttons.length > 0) newData.push("");

          dataTable.push(newData);
        });
      }
      return dataTable;
    };

    const [columns, setColumns] = useState(getColumns());
    const [content, setContent] = useState(getData());

    useEffect(() => {
      setColumns(getColumns());
      setContent(getData());
      setHasData(Array.isArray(data) && data.length > 0 ? true : false);

      return () => {
        setColumns([]);
        setContent([]);
        setHasData(false);
      };
    }, [data]);

    const UnselectAllTable = () => {
      mainCheckBoxRef.current.checked = false;
      content.forEach((i) => {
        const element = document.getElementById(i);
        if (element) {
          element.classList.remove("is-selected");
          const checkbox = element.children[0].children[0];
          checkbox.checked = false;
        }
      });
      setSelectedItems([]);
    };

    useImperativeHandle(ref, () => ({
      UnselectAllTable() {
        UnselectAllTable();
      },
    }));

    const onCheckboxChanged = (value, row) => {
      let element = document.getElementById(row);
      if (value) {
        setSelectedItems([...selectedItems, row]);
        element.classList.add("is-selected");
      } else {
        element.classList.remove("is-selected");
        setSelectedItems(selectedItems.filter((item) => item !== row));
      }
    };

    const rowData = (item, index, rowCount, row) => {
      return item?.name ? (
        <td key={index}>
          <Link
            to={item.to}
            style={{ color: info }}
            onClick={() => onClickLink(row)}
          >
            {item.name}
          </Link>
        </td>
      ) : !hasCheckbox &&
        buttons.length > 0 &&
        index === content[rowCount].length - 1 ? (
        <td key={index}>
          {buttons.map((button, buttonIndex) => (
            <ButtonIcon
              key={button.name}
              icon={button.icon}
              className={`${rowColors ? "" : "is-outlined"} ${
                buttonIndex === 0 ? "mr-1" : "ml-1"
              } is-small is-${button.class} has-tooltip-${button.class}`}
              data-tooltip={button.name}
              onClick={() => onClick(row, button.name)}
            />
          ))}
        </td>
      ) : states.length > 0 && columnState === index ? (
        <td key={index}>
          <span className={`tag is-${states[item]?.class}`}>
            {states[item]?.name}
          </span>
        </td>
      ) : (
        <td key={index}>{item}</td>
      );
    };

    useEffect(() => {
      if (mounted) {
        onChangeQuery(queryParams);
      }
    }, [queryParams, mounted]);

    const onPagination = (pagination) =>
      setQueryParams({ ...queryParams, pageIndex: pagination });
    const onSearch = (value) =>
      setQueryParams({ ...queryParams, search: value });
    const onChangeSize = (value) => {
      if (value === "notValue") value = 1;
      value = parseInt(value);
      if (value === 0) value = 1;
      const option = selectOptions.find(
        (item) => item.id === parseInt(value)
      )?.name;
      dispatch(setSelection(parseInt(option)));
      setQueryParams({ ...queryParams, pageSize: parseInt(option) });
      setSelected(parseInt(value));
    };

    const paginationMethod = (page) => {
      setSelectedItems([]);
      onPagination(page);
    };

    const onKeyUpSearch = ({ value }) => onSearch(value);

    const sortUpDown = (name, index) => {
      let element = document.getElementById(name);
      let className = element.classList;
      if (className.contains("fa-sort-down")) {
        element.classList.remove("fa-sort-down");
        element.classList.add("fa-sort-up");
        onSort(index - 1, true, name);
      } else if (className.contains("fa-sort-up")) {
        element.classList.remove("fa-sort-up");
        element.classList.add("fa-sort-down");
        onSort(index - 1, false, name);
      }
    };

    const SelectAllTable = () => {
      content.forEach((i) => {
        const element = document.getElementById(i);
        if (element) {
          element.classList.add("is-selected");
          const checkbox = element.children[0].children[0];
          checkbox.checked = true;
        }
      });
      setSelectedItems(content);
    };

    const onCheck = ({ target }) => {
      const checked = target.checked;
      if (checked) SelectAllTable();
      else UnselectAllTable();
    };

    return (
      <div
        className={`${hasBox ? "box has-background-white" : ""} `}
        style={{ width: "100%" }}
      >
        {props.children}
        {FilterComponent && (
          <div className="is-flex is-justify-content-space-between">
            {FilterComponent}
            <div>
              <label className="label is-small">Buscar</label>
              <Input
                className="is-dark"
                onKeyUp={onKeyUpSearch}
                icon="search"
                placeholder="Buscar"
                name="search"
              />
            </div>
          </div>
        )}
        {hasCheckbox && (
          <div className="is-flex is-flex-direction-row-reverse">
            {buttons.map((item, index) => (
              <ButtonIcon
                key={index}
                icon={item.icon}
                name={item.name}
                className={`is-big is-${item.class} m-1 has-tooltip-${item.class}`}
                data-tooltip={item.name}
                onClick={() => {
                  onClick(selectedItems, item.name);
                  setSelectedItems([]);
                }}
              />
            ))}
          </div>
        )}

        <div className="is-flex is-justify-content-space-between is-flex-wrap-wrap">
          <div className="is-flex is-align-items-center">
            {hasCheckbox && (
              <div className="is-flex is-align-items-center">
                <input
                  ref={mainCheckBoxRef}
                  type="checkbox"
                  className="is-clickable m-0"
                  onClick={onCheck}
                />
                <span className="mx-5">|</span>
              </div>
            )}
            <p className="mr-2">Mostrar: </p>
            <Select
              onChange={onChangeSize}
              title="Tamaño"
              select={selected}
              data={selectOptions}
              icon="sitemap"
            />
            <p className="ml-2">de {count} entradas totales</p>
          </div>

          {!FilterComponent && (
            <Input
              className="is-dark"
              onKeyUp={onKeyUpSearch}
              icon="search"
              placeholder="Buscar"
              name="search"
            />
          )}
        </div>

        {hasData && content.length > 0 ? (
          <div className="dataTable-content my-5">
            <table
              className={`table is-bordered is-striped is-narrow is-hoverable is-fullwidth ${className}`}
            >
              <thead className="has-brackground-white">
                <tr>
                  {columns.map((item, index) =>
                    (hasCheckbox && index === 1 && !visibleColumn) ||
                    (!hasCheckbox && index === 0 && !visibleColumn) ? null : (
                      <th key={index}>
                        {item ? (
                          <div className="is-flex">
                            <span>{item}</span>
                            <span
                              className="icon is-clickable"
                              name={item}
                              onClick={() => sortUpDown(item, index)}
                            >
                              <i className={`fas fa-sort-down`} id={item}></i>
                            </span>
                          </div>
                        ) : null}
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {content.map((i, ind) => (
                  <tr
                    key={i}
                    id={i}
                    style={{
                      background: rowColors?.find(
                        (r) => r.value === i[columnColor]
                      )
                        ? rowColors.find((r) => r.value === i[columnColor])
                            .color
                        : "",
                      border: rowColors?.find((r) => r.value === i[columnColor])
                        ? `solid ${
                            rowColors.find((r) => r.value === i[columnColor])
                              .borderColor
                          } 2px`
                        : "",
                    }}
                  >
                    {hasCheckbox ? (
                      <td>
                        <input
                          type="checkbox"
                          className="is-clickable m-0"
                          style={{ height: "16px", width: "16px" }}
                          onClick={(e) =>
                            onCheckboxChanged(e.target.checked, i)
                          }
                        />
                      </td>
                    ) : null}
                    {content[ind].map((item, index) =>
                      index === 0 && !visibleColumn
                        ? null
                        : rowData(item, index, ind, i)
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="dataTable-content-empty">
            No hay datos que mostrar
          </div>
        )}

        <Pagination pageCount={pageCount} paginationMethod={paginationMethod} />
      </div>
    );
  }
);

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  hasCheckbox: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      class: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  states: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      class: PropTypes.string,
    })
  ),
  onChangeQuery: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  columnState: PropTypes.number,
  pageCount: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  rowColors: PropTypes.array,
  columnColor: PropTypes.number,
  onClickLink: PropTypes.func,
};

DataTable.displayName = "DataTable";

export default DataTable;
