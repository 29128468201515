import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useMounted from "../../hooks/useMounted";
import { GetApi } from "../../redux/middleware/httpMethodApi";

import WithContextData from "./WithContextData";

const WithTablesData = (
  Component,
  ApiMethod,
  filterFunction,
  Sorting,
  param = ""
) => {
  const WrappedComponent = ({ dispatch, apiData }) => {
    const [tableContent, setTableContent] = useState([]);
    const [queryParams, setQueryParams] = useState();
    const [pageCount, setPageCount] = useState(1);
    const [count, setCount] = useState(0);
    const mounted = useMounted();
    const { place } = useParams();
    const dataTableSelection = useSelector((state) => state.dataTableSelection);
    const projectId = useSelector((state) => state.projectId);

    /* #region  CONSTRUCTOR */
    useEffect(() => {
      if (apiData.data?.pageIndex) {
        let filterData = [];
        if (param === "projectId" || param === "")
          filterData = apiData.data.data.map((item) => filterFunction(item));
        else filterData = apiData.data.data;
        setTableContent(filterData);
        setPageCount(apiData.data.pageCount);
        setCount(apiData.data.count);
      }
    }, [apiData.data]);
    /* #endregion */

    const onCallApi = useCallback(() => {
      if (queryParams?.pageSize === dataTableSelection) {
        if (param === "") dispatch(GetApi([queryParams], ApiMethod));
        else if (param === "projectId") {
          dispatch(GetApi([queryParams, projectId.id], ApiMethod));
        } else if (param === "ApiMethod") {
          dispatch(ApiMethod(queryParams, place));
        }
      }
    }, [queryParams, dispatch, place, dataTableSelection, projectId]);

    useEffect(() => {
      if (mounted && queryParams?.pageSize === dataTableSelection) {
        onCallApi();
      }
    }, [queryParams, mounted, place, onCallApi, dataTableSelection, projectId]);

    useEffect(() => {
      setTableContent([]);
      setPageCount(1);
    }, [place]);

    const onSort = (column, upDown, name) => {
      let variables = Object.keys(Sorting);
      variables.forEach((item) => {
        if (item === name) {
          let position = Sorting[item];
          setQueryParams({
            ...queryParams,
            sort: !upDown ? position[0] : position[1],
          });
        }
      });
    };

    const onChangeQuery = (query, qSpecial) => {
      if (mounted) {
        if (qSpecial) query = { ...query, ...qSpecial };
        setQueryParams(query);
      }
    };

    return (
      <>
        <Component
          apiData={apiData}
          tableContent={tableContent}
          dispatch={dispatch}
          onSort={onSort}
          onChangeQuery={onChangeQuery}
          query={queryParams}
          pageCount={pageCount}
          onCallApi={onCallApi}
          count={count}
        />
      </>
    );
  };

  return WithContextData(WrappedComponent);
};

export default WithTablesData;
