const getRefInput = (refs, name) =>
  refs.find((ref) => ref.current?.name === name)?.current;

const inputsWithRef = (refs, method = () => {}) =>
  refs.map((ref) => {
    return method(ref.current);
  });

export const validateInputWithRef = (Validator, refs, name, exclude = "") => {
  const input = getRefInput(refs, name);
  if (input) {
    if (input.name === exclude) return;
    if (Validator[input.name](input.value)) {
      input.classList.remove("is-danger");
      input.classList.add("is-success");
      return true;
    } else {
      input.classList.add("is-danger");
      input.classList.remove("is-success");
      return false;
    }
  }
};

export const cleanRefInputs = (refs) => {
  inputsWithRef(refs, (input) => {
    if (input) {
      input?.classList.remove("is-danger");
      input?.classList.remove("is-success");
      input.value = "";
    }
  });
};

export const validateRefInputs = (Validator, refs, skipProperties = []) =>
  inputsWithRef(refs, (input) => {
    const skip = skipProperties.includes(input?.name);
    if (!skip) return validateInputWithRef(Validator, refs, input?.name);
    else return true;
  });

export const validForm = (array) => {
  const result = array.find((response) => response === false);
  if (result === undefined) return true;
  else if (result === false) return false;
};
