import React, { useEffect, useState } from "react";
import DataTable from "../../2-systems/s_DataTable";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import * as places from "../../../resources/constants/places";
import * as traceableFactory from "../../../Helpers/Factory/traceableFactory";
import {
  GetInitDataForSendPiecesByPlace,
  swalConfirmation,
} from "../../../Helpers/Factory/SendPiecesFactory";
import * as moveTraceableMid from "../../../redux/middleware/moveTraceable.mid";
import useMounted from "../../../hooks/useMounted";
import { refresh_route } from "../../../redux/ducks/route";
import routes from "../../../resources/constants/routes";
import { links } from "../../../resources/constants/links";
import { Toast } from "../../../Helpers";
import Tab from "../../0-atoms/a_tab";
import WithTabsDataTable from "../../HOC/WithTabsDataTable";
import ProjectFilter from "../../2-systems/s_projectFilter";
import { GetFileMid } from "../../../redux/middleware/file.mid";
import { ToastError } from "../../../Helpers/sweetAlertHandler";
import { buildRecordForReject } from "../../../Helpers/Factory/recordFactory";
import * as messages from "../../../resources/constants/messages";

function ProductionSendPieces({
  apiData,
  tableContent,
  dispatch,
  onCallApi,
  onSort,
  count,
  pageCount,
  onChangePlace,
  onChangeQuery,
}) {
  const mounted = useMounted();
  const [buttons, setButtons] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (mounted) {
      dispatch(
        refresh_route(routes[links.productionSendPieces.name], places.Planning)
      );

      let initData = GetInitDataForSendPiecesByPlace(places.Production);
      setTitle(initData.title);
      setButtons(initData.buttons);
    }
  }, [mounted]);

  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      Toast.fire(ToastObject("Se han enviado con éxito las piezas", "success"));
      onCallApi();
    }
  }, [apiData.data?.postConfirmation]);

  const onTabSelected = (name) => {
    if (name === "Ordenes en MaqZam") {
      setTitle("Ordenes en MaqZam");
      onChangePlace(places.Production);
    } else if (name === "Ordenes en proveedor externo") {
      setTitle("Ordenes en proveedor externo");
      onChangePlace(places.ExternalMachining);
    }
  };

  const handleSubmit = async (rowData, item) => {
    const [id, workOrder, quantity] = rowData;
    // eslint-disable-next-line no-unused-vars
    let sendObject = {
      ZamtestTraceableTag: id,
      QuantityToMove: quantity,
    };

    if (item === "Recibir") {
      await onReceive(sendObject, quantity);
    } else if (item === "Rechazar") {
      await onReject(sendObject, quantity, workOrder);
    } else dispatch(GetFileMid(workOrder)); // VER PDF
  };

  const onReceive = async (sendObject, quantity) => {
    const result = await swalConfirmation("yesnoProduction");
    if (result) {
      if (title === "Ordenes en MaqZam") {
        const [quantityToMove, time] = await swalConfirmation(
          "numberProduction"
        );
        if (!quantityToMove || !time) {
          ToastError("No puedes dejar ni la cantidad ni el tiempo vacíos.");
          return;
        }
        if (quantityToMove > parseInt(quantity)) {
          Toast.fire(ToastObject(messages.quantityError, "warning"));
          return;
        }
        sendObject.QuantityToMove = quantityToMove;
        let productionTime = {
          traceableTag: sendObject.ZamtestTraceableTag,
          productionTiem: time,
        };
        dispatch(moveTraceableMid.MoveAndSendTime(sendObject, productionTime));
      } else {
        if (quantity > 1) {
          let value = await swalConfirmation("number");
          if (value && parseInt(value) > parseInt(quantity)) {
            Toast.fire(ToastObject(messages.quantityError, "warning"));
            return;
          }
          quantity = parseInt(value);
        }
        sendObject.QuantityToMove = quantity;
        dispatch(
          moveTraceableMid.MoveAndChangeRejectedStatus(sendObject, null, false)
        );
      }
    }
  };

  const onReject = async (sendObject, quantity, workOrder) => {
    let messageForRecord = "";
    const result = await swalConfirmation("reject", { workOrder });
    if (!result) return;

    if (parseInt(quantity) > 1) {
      const [number, message] = await swalConfirmation("numberMessage");
      if (!number || !message) {
        ToastError(
          "No puedes dejar ni la cantidad ni el mensaje de rechazo vacíos."
        );
        return;
      }
      sendObject.QuantityToMove = number;
      // eslint-disable-next-line no-unused-vars
      messageForRecord = message;
    } else {
      const [, message] = await swalConfirmation("message");
      if (!message) {
        ToastError("No puedes dejar la razón de rechazo vacía");
        return;
      }
      // eslint-disable-next-line no-unused-vars
      messageForRecord = message;
    }
    const place =
      title === "Ordenes en MaqZam"
        ? places.Production
        : places.ExternalMachining;
    let record = buildRecordForReject(
      sendObject.ZamtestTraceableTag,
      place,
      messageForRecord,
      sendObject.QuantityToMove
    );
    record.title =
      title === "Ordenes en MaqZam"
        ? "Rechazó de MaqZam"
        : "Rechazo de proveedor externo";
    dispatch(moveTraceableMid.MoveAndChangeRejectedStatus(sendObject, record));
  };

  const onSelect = (value) => {
    // filtrar por orden de trabajo
    if (value) onChangeQuery({ projectId: value.id });
    else onChangeQuery({ projectId: "" });
  };

  return (
    <>
      <Tab
        tabs={[
          { name: "Ordenes en MaqZam", icon: "puzzle-piece" },
          { name: "Ordenes en proveedor externo", icon: "times" },
        ]}
        onClick={onTabSelected}
      >
        <DataTable
          data={tableContent}
          count={count}
          visibleColumn={false}
          onClick={handleSubmit}
          buttons={buttons}
          states={traceableFactory.routeStates}
          columnState={8}
          onSort={onSort}
          onChangeQuery={onChangeQuery}
          pageCount={pageCount}
          hasCheckbox={false}
          hasBox={false}
          FilterComponent={
            <ProjectFilter title="Filtrar por proyecto" onSelect={onSelect} />
          }
        >
          <h1 className="title is-4 is-flex is-justify-content-center">
            {title}
          </h1>
        </DataTable>
      </Tab>

      <br />
    </>
  );
}

export default WithTabsDataTable(ProductionSendPieces, places.Production);
