/* #region  IMPORTS */
import React, { useEffect, useState } from "react";
import WithContextData from "../../HOC/WithContextData";
import { useParams } from "react-router";
import TimeLine from "../../2-systems/s_TimeLine";
import { GetWorkOrderMid } from "../../../redux/middleware/workOrders.mid";
import {
  WorkOrderObject,
  workOrderStatus,
} from "../../../Helpers/Factory/workOrderFactory";
import Tabs from "../../1-blocks/b_Tabs";
import ButtonContainer from "../../1-blocks/b_ButtonContainer";
import { PostApi } from "../../../redux/middleware/httpMethodApi";
import { CancelWorkOrderApi } from "../../../Services/WorkOrderService";
import { AlertObject, ToastObject } from "../../../Helpers/Factory/swalFactory";
import Swal from "sweetalert2";
import { danger, dark } from "../../../Helpers/Factory/recordFactory";
import ViewWorkOrderForm from "../../1-blocks/b_ViewWorkOrder";
import { GetFileMid } from "../../../redux/middleware/file.mid";
/* #endregion */

function WorkOrder({
  apiData,
  dispatch,
  refresh_route,
  _routes,
  links,
  helpers,
}) {
  const { woId } = useParams();
  const [timeLineData, setTimeLineData] = useState([]);
  const [form, setForm] = useState(WorkOrderObject);

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    dispatch(refresh_route(_routes[links.workOrder.name]));
    dispatch(GetWorkOrderMid(woId));
  }, []);

  useEffect(() => {
    if (!apiData.loading && apiData.ok && apiData.data?.records) {
      setForm(apiData.data.workOrder);
      setTimeLineData(apiData.data.records);
    }
  }, [apiData.data]);
  /* #endregion */

  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      helpers.Toast.fire(
        ToastObject("Se han enviado con éxito las piezas", "success")
      );
      dispatch(GetWorkOrderMid(woId));
    }
  }, [apiData.data]);

  const cancelWorkOrder = async () => {
    let result = await Swal.fire(
      AlertObject(
        {
          title: `¿Seguro de cancelar la orden ${form.code}?`,
          text: "No serás capaz de revertir esta acción",
        },
        "warning",
        danger,
        dark,
        "Si, Cancelar",
        "No"
      )
    );
    if (result.isConfirmed) {
      dispatch(PostApi([form.code], CancelWorkOrderApi));
    }
  };

  let tabs = {
    0: {
      header: "Orden de trabajo",
      body: (
        <div className="box whiteBox">
          <ButtonContainer>
            <button
              className="button is-dark is-small"
              onClick={() => dispatch(GetFileMid(form.code))}
            >
              Descargar PDF
            </button>
          </ButtonContainer>
          <ViewWorkOrderForm form={form} piece={false} />
        </div>
      ),
    },
    1: {
      header: "Seguimiento",
      body: (
        <div className="box p-5 has-background-white">
          <h4 className="title is-4">Línea del tiempo</h4>
          <TimeLine data={timeLineData} />
        </div>
      ),
    },
  };

  return (
    <div>
      {form.status === workOrderStatus.created ||
      form.status === workOrderStatus.inProduction ? (
        form.code ? (
          <ButtonContainer>
            <button
              className="button is-danger is-small"
              onClick={cancelWorkOrder}
            >
              Cancelar orden
            </button>
          </ButtonContainer>
        ) : null
      ) : null}

      <Tabs tabs={tabs} activeColor={"#3273dc"} className="is-small"></Tabs>
    </div>
  );
}

export default WithContextData(WorkOrder);
