/* #region  IMPORTS */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ValidInputs } from "../../../Helpers";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import { buildRoleForGroupedSelect } from "../../../Helpers/Factory/userFactory";
import { UpdateRoleForm } from "../../../Helpers/Forms/UpdateRoleForm";
import { GetApi, PostApi } from "../../../redux/middleware/httpMethodApi";
import { invalidForm } from "../../../resources/constants/messages";
import {
  GetRolesApi,
  GetUserApi,
  UpdateUserRoleApi,
} from "../../../Services/SuperUserService";
import WithContextData from "../../HOC/WithContextData";
import GroupedSelect from "../../0-atoms/a_groupedSelect";
import FormInput from "../../0-atoms/a_formInput";
import ButtonIcon from "../../1-blocks/b_ButtonIcon";
/* #endregion */

function UpdateUserRole({
  apiData,
  history,
  dispatch,
  refresh_route,
  _routes,
  links,
  helpers,
}) {
  const { userId } = useParams();
  const [managers, setManagers] = useState([]);
  const [form, setForm] = useState({});

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    if (!userId) {
      dispatch(refresh_route(_routes[links.editUser.name]));
      dispatch(GetApi([userId], GetRolesApi));
    } else {
      dispatch(GetApi([userId], GetUserApi));
      dispatch(GetApi([userId], GetRolesApi));
      dispatch(
        refresh_route(
          _routes[links.editUser.name],
          `${links.editUser.to}/${userId}`
        )
      );
      ValidInputs(UpdateRoleForm);
    }
    return () => {
      helpers.CleanInputs(UpdateRoleForm);
    };
  }, []);

  useEffect(() => {
    if (!apiData.loading && apiData.ok && apiData.data[0]) {
      var roles = apiData.data.map((item) => buildRoleForGroupedSelect(item));
      setManagers(roles);
    } else if (!apiData.loading && apiData.ok && apiData.data?.id) {
      setForm({
        userId: apiData.data.id,
        email: apiData.data.email,
        actualRole: apiData.data.role,
      });
    }
  }, [apiData.data]);

  /* #region  CONFIRMACIÓN DE CREAR */
  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      helpers.Toast.fire(
        ToastObject("Se ha editado el rol con éxito", "success")
      );
      history.push(links.users.to);
    }
  }, [apiData.data]);
  /* #endregion */
  const onChange = (value, isOk) => {
    if (isOk) setForm({ ...form, roleName: value });
    else setForm({ ...form, roleName: "" });
  };
  const handleSubmit = async (valid) => {
    if (valid && form.roleName !== "") {
      let data = {
        userId: form.userId,
        role: form.roleName,
      };
      dispatch(PostApi([data], UpdateUserRoleApi));
    } else {
      helpers.Toast.fire(ToastObject(invalidForm, "error"));
    }
  };
  return (
    <div className="form-container">
      <div className="box p-5 has-background-white">
        <header className="is-flex is-justify-content-center">
          <h2 className="title is-4">Editar rol de usuario</h2>
        </header>
        <br />
        {form.email ? (
          <>
            <FormInput
              className="is-success"
              title={"Correo"}
              name="email"
              icon="user"
              placeholder="correo electrónico"
              disabled={true}
              defaultValue={form.email}
            />
            <FormInput
              className="is-success"
              title={"Rol actual"}
              name="rol"
              icon="user"
              placeholder="Rol actual"
              disabled={true}
              defaultValue={form.actualRole}
            />
            <label className="label is-small">Nuevo rol</label>
            <GroupedSelect
              className="is-fullwidth"
              title="Selecciona un nuevo rol"
              name="roleName"
              icon="users"
              data={managers}
              onChange={onChange}
            />
          </>
        ) : (
          <div className="">Cargando...</div>
        )}
        <br />
        <footer className="is-flex is-justify-content-center mt-5">
          <ButtonIcon
            className={"is-success"}
            name="Editar rol"
            icon="user"
            onClick={handleSubmit}
          />
        </footer>
      </div>
    </div>
  );
}

export default WithContextData(UpdateUserRole);
