import { buildRecordForReject } from "../../Helpers/Factory/recordFactory";
import { routeStates } from "../../Helpers/Factory/traceableFactory";
import { workOrderStatus } from "../../Helpers/Factory/workOrderFactory";
import { BAD_REQUEST, OK } from "../../resources/constants/messages";
import { Planning } from "../../resources/constants/places";
import { OrderNoCompleted } from "../../resources/constants/routeNames";
import {
  DoubleMoveApi,
  MoveNextApi,
  TripleMoveApi,
} from "../../Services/MoveTraceableService";
import { GetFileApi } from "../../Services/WorkOrderFileService";
import * as WorkOrderService from "../../Services/WorkOrderService";
import {
  ChangeRejectedStatusApi,
  CreateCustomRecordApi,
} from "../../Services/ZamtestTraceableService";
import { apiFail, apiRequest, apiSuccess } from "../ducks/apiData";
import {
  ChangeRouteAndMove,
  ChangeRouteOrderNoCompleted,
  ChangeRouteQualityNotPass,
} from "./changeRoute.mid";

export const VerifyTraceable = (data) => {
  if (data.item2) return data.item2;
  else return data.item1;
};

export const buildZamtestTraceable = (tuple, status) => {
  const tag = VerifyTraceable(tuple);
  return {
    traceableTag: tag,
    status,
  };
};

export const DoubleMoveMid = (sendObject) => async (dispatch) => {
  dispatch(apiRequest());
  var result = await DoubleMoveApi(sendObject);
  if (result.status === OK) dispatch(apiSuccess({ postConfirmation: true }));
  else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const TripleMoveMid = (sendObject) => async (dispatch) => {
  dispatch(apiRequest());
  var result = await TripleMoveApi(sendObject);
  if (result.status === OK) dispatch(apiSuccess({ postConfirmation: true }));
  else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const ReceivePartPiecesMid =
  (sendObject, status) => async (dispatch) => {
    dispatch(apiRequest());
    var result = await MoveNextApi(sendObject);
    if (result.status === OK) {
      const traceable = buildZamtestTraceable(result.data, status);
      result = await WorkOrderService.ChangeWorkOrderStatusFromTraceableApi(
        traceable
      );
      if (result.status === OK) {
        dispatch(apiSuccess({ received: true }));
      } else dispatch(apiFail({ error: result.error, status: result.status }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  };

export const MoveAndChangeRejectedStatus =
  (sendObject, record = null, isRejected = true) =>
  async (dispatch) => {
    dispatch(apiRequest());
    var result = await DoubleMoveApi(sendObject);
    if (result.status === OK) {
      let obj = {
        tag: sendObject.ZamtestTraceableTag,
        isRejected,
      };
      const newValue = VerifyTraceable(result.data);
      obj.tag = newValue;
      result = await ChangeRejectedStatusApi(obj);
      if (result.status === OK) {
        if (!record) {
          dispatch(apiSuccess({ postConfirmation: true }));
          return;
        }
        record.traceableTag = newValue;
        result = await CreateCustomRecordApi(record);
        if (result.status === OK) {
          dispatch(apiSuccess({ postConfirmation: true }));
          return;
        }
      }
    }
    dispatch(apiFail({ error: result.error, status: result.status }));
  };

export const MoveAndSendTime =
  (sendObject, productionTime) => async (dispatch) => {
    dispatch(apiRequest());
    var result = await DoubleMoveApi(sendObject);
    if (result.status === OK) {
      let obj = {
        tag: sendObject.ZamtestTraceableTag,
        isRejected: false,
      };
      let newValue = VerifyTraceable(result.data);
      obj.tag = newValue;
      productionTime.traceableTag = newValue;
      result = await ChangeRejectedStatusApi(obj);
      if (result.status === OK) {
        result =
          await WorkOrderService.ChangeWorkOrderProductionTimeFromTraceableApi(
            productionTime
          );
        if (result.status === OK) {
          dispatch(apiSuccess({ postConfirmation: true }));
          return;
        }
      }
    }
    dispatch(apiFail({ error: result.error, status: result.status }));
  };

/* #region  METODOS PARA SELECCIÓN MULTIPLE */
export const CancelMultipleTraceableFromPlanning =
  (data, message) => async (dispatch) => {
    dispatch(apiRequest());
    let error = false;
    await Promise.all(
      data.map(async (item) => {
        const [id, workOrder, quantity] = item;
        var sendObject = {
          ZamtestTraceableTag: id,
          QuantityToMove: quantity,
        };
        let record = buildRecordForReject(
          sendObject.ZamtestTraceableTag,
          Planning,
          message,
          sendObject.QuantityToMove
        );
        record.title = "Rechazo de planeación";
        let result = await CreateCustomRecordApi(record);
        if (result.status !== OK) error = true;
        result = await WorkOrderService.CancelWorkOrderApi(workOrder);
        if (result.status !== OK) error = true;
      })
    );

    if (error)
      dispatch(
        apiFail({
          error: "Hubo un error al enviar las ordenes de trabajo",
          status: BAD_REQUEST,
        })
      );
    else {
      dispatch(apiSuccess({ postConfirmation: true }));
    }
  };

export const SendMultipleTraceablesMid = (data) => async (dispatch) => {
  dispatch(apiRequest());
  if (Array.isArray(data) && data.length > 0) {
    let error = false;
    await Promise.all(
      data.map(async (item) => {
        const [id, , quantity] = item;
        const route = routeStates[item[8]].name;
        var sendObject = {
          ZamtestTraceableTag: id,
          QuantityToMove: quantity,
        };
        let result = {};
        if (route === OrderNoCompleted) {
          result = await ChangeRouteAndMove(sendObject);
          if (result.status !== OK) error = true;
        } else {
          result = await DoubleMoveApi(sendObject);
          if (result.status !== OK) error = true;
        }
        const newStatus = buildZamtestTraceable(
          result.data,
          workOrderStatus.planning
        );
        result = await WorkOrderService.ChangeWorkOrderStatusFromTraceableApi(
          newStatus
        );
        if (result.status !== OK) error = true;
      })
    );

    if (error)
      dispatch(
        apiFail({
          error: "Hubo un error al enviar las ordenes de trabajo",
          status: BAD_REQUEST,
        })
      );
    else {
      dispatch(apiSuccess({ postConfirmation: true }));
    }
  } else
    dispatch(
      apiFail({ error: "Los datos son incorrectos", status: BAD_REQUEST })
    );
};

export const RejectMultipleTraceablesMid =
  (data, message, place) => async (dispatch) => {
    dispatch(apiRequest());
    if (Array.isArray(data) && data.length > 0) {
      let error = false;
      await Promise.all(
        data.map(async (item) => {
          var sendObject = {
            ZamtestTraceableTag: item[0],
            QuantityToMove: item[2],
          };
          let record = buildRecordForReject(
            sendObject.ZamtestTraceableTag,
            place,
            message,
            sendObject.QuantityToMove
          );
          record.title = "Rechazo de calidad";
          let result = await ChangeRouteQualityNotPass(sendObject, record);
          if (result.status !== OK) error = true;
        })
      );
      if (error)
        dispatch(
          apiFail({
            error: "Hubo un error al enviar las ordenes de trabajo",
            status: BAD_REQUEST,
          })
        );
      else {
        dispatch(apiSuccess({ postConfirmation: true }));
      }
    } else
      dispatch(
        apiFail({ error: "Los datos son incorrectos", status: BAD_REQUEST })
      );
  };

export const ChangeMultipleTraceablesMid = (data) => async (dispatch) => {
  dispatch(apiRequest());
  if (Array.isArray(data) && data.length > 0) {
    let error = false;
    await Promise.all(
      data.map(async (item) => {
        var sendObject = {
          ZamtestTraceableTag: item[0],
          QuantityToMove: item[2],
        };
        let result = await ChangeRouteOrderNoCompleted(sendObject);
        if (result.status !== OK) error = true;
      })
    );
    if (error)
      dispatch(
        apiFail({
          error: "Hubo un error al enviar las ordenes de trabajo",
          status: BAD_REQUEST,
        })
      );
    else {
      dispatch(apiSuccess({ postConfirmation: true }));
    }
  } else
    dispatch(
      apiFail({ error: "Los datos son incorrectos", status: BAD_REQUEST })
    );
};

export const VisualizeMultipleFilesMid = (data) => async (dispatch) => {
  dispatch(apiRequest());
  if (Array.isArray(data) && data.length > 0) {
    await Promise.all(
      data.map(async (item) => {
        const [, workOrder] = item;
        await GetFileApi(workOrder);
      })
    );
    dispatch(apiSuccess());
  } else
    dispatch(
      apiFail({ error: "Los datos son incorrectos", status: BAD_REQUEST })
    );
};

export const SendMultipleTraceableForPlanningMid =
  (data) => async (dispatch) => {
    dispatch(apiRequest());
    if (Array.isArray(data) && data.length > 0) {
      let error = false;
      await Promise.all(
        data.map(async (item) => {
          const sendObject = {
            ZamtestTraceableTag: item[0],
            QuantityToMove: item[2],
          };
          let result = await DoubleMoveApi(sendObject);
          if (result.status !== OK) error = true;
          const newStatus = buildZamtestTraceable(
            result.data,
            workOrderStatus.maqZam
          );
          result = await WorkOrderService.ChangeWorkOrderStatusFromTraceableApi(
            newStatus
          );
          if (result.status !== OK) error = true;
        })
      );

      if (error)
        dispatch(
          apiFail({
            error: "Hubo un error al enviar las ordenes de trabajo",
            status: BAD_REQUEST,
          })
        );
      else {
        dispatch(apiSuccess({ postConfirmation: true }));
      }
    } else
      dispatch(
        apiFail({ error: "Los datos son incorrectos", status: BAD_REQUEST })
      );
  };
/* #endregion */
