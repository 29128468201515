export const PieceObject = {
  id: 0,
  projectId: "",
  code: "",
  description: "",
  quantity: 0,
  pieceMaterial: "",
  pieceMaterialKind: "",
  metallicFinish: "",
  thickness: 0,
  width: 0,
  height: 0,
  operationsIds: [],
};

export const buildPieceForTable = (piece) => ({
  Id: piece.id,
  Código: piece.code,
  Descripción: piece.description,
  Cantidad: piece.quantity,
  Ancho: piece.width,
  Largo: piece.height,
  Espesor: piece.thickness,
});

export const sortingPiece = {
  Id: ["idAsc", "idDesc"],
  Código: ["codeAsc", "codeDesc"],
  Descripción: ["descAsc", "descDesc"],
  Cantidad: ["quantityAsc", "quantityDesc"],
  Ancho: ["widthAsc", "widthDesc"],
  Largo: ["heightAsc", "heightDesc"],
  Espesor: ["thicknessAsc", "thicknessDesc"],
};

export const buildPieceForSelect = (piece) => ({
  id: piece.id,
  name: piece.code,
  quantity: piece.quantity,
});
