import React, { useEffect, useRef, useState } from "react";

export default function GroupedSelect({
  icon,
  title,
  data,
  className,
  onChange,
  ...props
}) {
  const select = useRef();
  const [optionSelected, setOptionSelected] = useState(false);

  useEffect(() => {
    if (props?.select) select.current.value = props.select;
  }, [props.data]);

  const onChangeChildren = ({ target }) => {
    const value = target.value;
    setOptionSelected(value !== title);
    onChange(value, value !== title);
  };

  if (data.length <= 0) {
    return <h1>No hay datos por seleccionar</h1>;
  } else {
    return (
      <div className="control has-icons-left is-right mb-2">
        <div
          className={`select ${className} ${
            !optionSelected ? "is-danger" : "is-primary"
          }`}
          onChange={onChangeChildren}
        >
          <select ref={select} {...props}>
            <option value={title} key={title}>
              {title}
            </option>
            {data.map((element) => (
              <optgroup label={element.label} key={element.label}>
                {element.options.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </div>
        <div className="icon is-small is-left">
          <i className={`fas fa-${icon}`}></i>
        </div>
      </div>
    );
  }
}
