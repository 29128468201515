import React, { useEffect, useRef, useState } from "react";
import NavBarSimple from "../1-blocks/b_NavBarSimple";

function LoggedOut(props) {
  const navBarRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(navBarRef.current.offsetHeight);
  }, []);

  return (
    <div className="is-fullheight">
      <NavBarSimple ref={navBarRef} />

      <div
        id="page-wrap"
        className="p-5"
        style={{ height: `calc(100% - ${height}px` }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default LoggedOut;
