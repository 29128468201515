export const OperationObject = {
  id: 0,
  title: "",
  description: "",
};

export const buildOperationForTable = (operation) => ({
  Id: operation.id,
  Titulo: operation.title,
  Descripción: operation.description,
});

export const buildOperationForSelect = (operation) => ({
  id: operation.id,
  name: operation.title,
});
