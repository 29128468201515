import { buildOperationForTable } from "../../Helpers/Factory/operationFactory";
import { OK } from "../../resources/constants/messages";
import { GetOperationsApi } from "../../Services/OperationService";
import { apiFail, apiRequest, apiSuccess } from "../ducks/apiData";

const CreateData = (data) => {
  if (data.length > 0) {
    return data.map((operation) => buildOperationForTable(operation));
  } else return [];
};

export const GetOperationsMid = () => async (dispatch) => {
  dispatch(apiRequest());
  var result = await GetOperationsApi();
  if (result.status === OK) {
    dispatch(apiSuccess({ operationsTable: CreateData(result.data) }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};
