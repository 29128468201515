import React, { useState } from "react";
import { CheckErrorsForm } from "../../Helpers";
import ButtonIcon from "../1-blocks/b_ButtonIcon";
import InputIcon from "../1-blocks/b_InputIcon";

function Form({
  inputs,
  inputsValidator,
  state,
  button,
  handleSubmit,
  ...props
}) {
  const [rerender, setRerender] = useState(false);
  const [formData, setFormData] = state;

  const onType = (e) => {
    let validator = inputsValidator[e.target.name],
      isValid = validator(e.target.value);
    inputs[e.target.name].isValid = isValid;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submit = (event) => {
    event.preventDefault();
    let error = !CheckErrorsForm(inputs, formData);
    setRerender(!rerender);
    handleSubmit(error);
  };

  return (
    <div className="box" style={{ background: "#fff", marginBottom: "10px" }}>
      {props.isUp ? props.children : null}
      <form>
        {Object.values(inputs).map((input, i) => (
          <InputIcon
            key={i}
            name={input.name}
            OnType={onType}
            type={input.type}
            placeholder={input.placeholder}
            isValid={input.isValid}
            icon={input.icon}
            errorMessage={input.errorMessage}
            value={formData[input.name]}
            decimals={input.decimals}
            disabled={input.disabled}
            isTextArea={input.isTextArea}
          />
        ))}
        {!props.isUp ? props.children : null}

        {button === undefined ? null : (
          <>
            <br />
            <ButtonIcon
              name={button.name}
              className="is-fullwidth is-primary"
              icon={button.icon}
              onClick={submit}
            />
          </>
        )}
      </form>
    </div>
  );
}

export default Form;
