import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ReceiveSendDataFactory } from "../../Helpers/Factory/receiveSendDataFactory";
import { ReceiveSendDataForm } from "../../Helpers/Forms/ReceiveSendDataForm";
import { isEmpty } from "../../Helpers/Validators";
import { emptyField } from "../../resources/constants/messages";
import ButtonIcon from "../1-blocks/b_ButtonIcon";
import InputIcon from "../1-blocks/b_InputIcon";
import Form from "./s_Form";
import * as helpers from "../../Helpers";
import { ToastObject } from "../../Helpers/Factory/swalFactory";

const ReceiveSendData = forwardRef(
  ({ data = ReceiveSendDataFactory, setData, onSearch, onSend }, ref) => {
    const searchButton = useRef();
    const inputQuantity = useRef();
    const [form, setForm] = useState();
    const [searched, setSearched] = useState(false);
    const [isValidSearch, setIsValidSearch] = useState(null);
    const [isValidAmount, setIsValidAmount] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [amount, setAmount] = useState(0);

    useImperativeHandle(ref, () => ({
      onClean() {
        onClose();
      },
    }));

    useEffect(() => {
      setTimeout(() => {
        searchButton.current.focus();
      }, 0);
    }, []);

    useEffect(() => {
      setForm(data);
      if (data.code) {
        setSearched(true);
        setTimeout(() => {
          inputQuantity.current.focus();
        }, 0);
      } else setSearched(false);
    }, [data]);

    const onTypeSearch = (e) => {
      setSearchText(e.target.value);
      let valid = isEmpty(e.target.value);
      setIsValidSearch(valid);
    };

    const onTypeAmount = (e) => {
      setAmount(parseInt(e.target.value));
      let valid = isEmpty(e.target.value);
      setIsValidAmount(valid);
    };

    const onClose = () => {
      setData(ReceiveSendDataFactory);
      setSearchText("");
      setIsValidSearch(null);
      setIsValidAmount(null);
      setAmount(0);
      setSearched(false);
    };

    const onSearchLocal = () => {
      if (searchText === "") {
        setIsValidSearch(false);
        helpers.Toast.fire(
          ToastObject("No puedes dejar el campo de busqueda vacío", "error")
        );
      } else {
        if (isValidSearch) onSearch(searchText);
      }
    };

    const onSendLocal = () => {
      if (
        amount === "" ||
        amount === 0 ||
        amount === null ||
        amount === undefined
      ) {
        setIsValidAmount(false);
      } else {
        if (isValidAmount) onSend(amount);
      }
    };

    const onKeyDown = (e, method = () => {}) => {
      if (e.key === "Enter") method();
    };

    const onFocus = () => {
      searchButton.current.select();
    };

    return (
      <div className="box has-background-white">
        <div className="is-flex is-justify-content-center is-align-items-flex-end p-3">
          <div style={{ width: "100%" }}>
            <InputIcon
              ref={searchButton}
              name="Buscar orden de trabajo"
              OnType={onTypeSearch}
              placeholder="Buscar orden de trabajo"
              isValid={isValidSearch}
              icon="search"
              value={searchText}
              isBig={true}
              onKeyDown={(e) => onKeyDown(e, onSearchLocal)}
              onFocus={onFocus}
            />
          </div>
          <ButtonIcon
            className="is-info has-tooltip-info ml-3"
            aria-label="search"
            data-tooltip="Buscar"
            onClick={onSearchLocal}
            icon="search"
          />
        </div>

        {searched ? (
          <div className={`mt-5`}>
            <div className="card cardColor">
              <div className="card-header">
                <div className="card-header-title">Datos de la pieza</div>
                <div className="card-header-icon">
                  <span
                    data-testid="icon"
                    className="icon is-small closeBtn has-tooltip-left"
                    data-tooltip="Coloca la cantidad de piezas que deseas mover en la entrada de texto"
                  >
                    <i className="fas fa-info-circle"></i>
                  </span>
                </div>
              </div>
              <div className="card-content">
                <div className="columns">
                  <div className="column is-two-thirds">
                    <div className="columns">
                      <div className="column">
                        <Form
                          inputs={ReceiveSendDataForm}
                          state={[form, setForm]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column columnFlex">
                    <div>
                      <InputIcon
                        ref={inputQuantity}
                        name="Cantidad a mover"
                        OnType={onTypeAmount}
                        type="number"
                        decimals={false}
                        placeholder="Cantidad a mover"
                        isValid={isValidAmount}
                        icon="hashtag"
                        errorMessage={emptyField}
                        value={amount}
                        onKeyDown={(e) => onKeyDown(e, onSendLocal)}
                        onFocus={(e) => e.target.select()}
                      />
                    </div>
                    <div className="sendCloseBtns">
                      <ButtonIcon
                        aria-label="cancel"
                        className="is-danger has-tooltip-danger"
                        data-tooltip="Cancelar"
                        icon="window-close"
                        name="Cancelar"
                        onClick={onClose}
                      />
                      <ButtonIcon
                        aria-label="send"
                        className="is-success has-tooltip-success"
                        data-tooltip="Recibir"
                        icon="paper-plane"
                        name="Recibir"
                        onClick={onSendLocal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
);

ReceiveSendData.displayName = "ReceiveSendData";

export default ReceiveSendData;
