/* #region  IMPORTS */
import React, { useEffect, useState } from "react";
import WithContextData from "../HOC/WithContextData";
import DataTable from "../2-systems/s_DataTable";
import {
  GetTraceablesForDesign,
  GetTraceablesToReceiveMid,
} from "../../redux/middleware/traceables.mid";
import { PostApi } from "../../redux/middleware/httpMethodApi";
import { MoveNextApi } from "../../Services/MoveTraceableService";
import { AlertObject, ToastObject } from "../../Helpers/Factory/swalFactory";
import { useParams } from "react-router";
import {
  routeStates,
  sortingTraceable,
} from "../../Helpers/Factory/traceableFactory";
import * as places from "../../resources/constants/places";
import { GetFileMid, UpdateFileMid } from "../../redux/middleware/file.mid";
import {
  GetInitDataForReceivePiecesByPlace,
  swalReceive,
} from "../../Helpers/Factory/ReceivePiecesFactory";
import Swal from "sweetalert2";
import { danger, dark, primary } from "../../Helpers/Factory/recordFactory";
import { swalConfirmation } from "../../Helpers/Factory/SendPiecesFactory";
import { CancelWorkOrderApi } from "../../Services/WorkOrderService";
import { UpdateObsoleteWorkOrderMid } from "../../redux/middleware/workOrders.mid";
import ProjectFilter from "../2-systems/s_projectFilter";
/* #endregion */

const swalAttributes = {
  title: "Selecciona el plano",
  input: "file",
  showCloseButton: true,
  showCancelButton: true,
  inputAttributes: {
    accept: ".pdf",
    "aria-label": "Sube un PDF",
  },
  cancelButtonText: "Cancelar",
  confirmButtonText: "Enviar",
  reverseButtons: true,
  confirmButtonColor: primary,
  cancelButtonColor: danger,
};
function ReceivePieces({
  apiData,
  dispatch,
  refresh_route,
  _routes,
  links,
  helpers,
}) {
  /* #region  VARIABLES */
  const [tableData, setTableData] = useState([]);
  const { place } = useParams();
  const [title, setTitle] = useState("");
  const [buttons, setButtons] = useState([]);
  const [queryParams, setQueryParams] = useState({
    pageSize: 5,
    pageIndex: 1,
    search: "",
  });
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  /* #endregion */

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    let pl = place.split("-")[0];
    dispatch(
      refresh_route(
        _routes[links.receivePieces.name],
        `/receiveTraceables/${place}`
      )
    );
    setButtons(GetInitDataForReceivePiecesByPlace(place).buttons);
    if (pl !== places.Planning) {
      setTitle(GetInitDataForReceivePiecesByPlace(place).title);
    } else {
      setTitle("Recibir piezas de acabados");
    }

    if (pl === places.Design)
      dispatch(GetTraceablesForDesign(queryParams, place));
    else dispatch(GetTraceablesToReceiveMid(queryParams, pl));
  }, [place]);

  useEffect(() => {
    if (apiData.ok && !apiData.loading && apiData.data?.traceables) {
      setTableData(apiData.data.traceables.data);
      setPageCount(apiData.data.traceables.pageCount);
      setCount(apiData.data.count);
    }
  }, [apiData.data?.traceables]);

  useEffect(() => {
    let pl = place.split("-")[0];
    if (pl === places.Design)
      dispatch(GetTraceablesForDesign(queryParams, place));
    else dispatch(GetTraceablesToReceiveMid(queryParams, pl));
  }, [queryParams]);

  useEffect(() => {
    setTableData([]);
  }, [place]);
  /* #endregion */

  /* #region  CONFIRMACIÓN DE RECIBIR */
  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      helpers.Toast.fire(
        ToastObject("Se han recibido con éxito las piezas", "success")
      );
      let pl = place.split("-")[0];
      if (pl === places.Design)
        dispatch(GetTraceablesForDesign(queryParams, place));
      else dispatch(GetTraceablesToReceiveMid(queryParams, pl));
    }
  }, [apiData.data]);
  /* #endregion */

  const handleSubmit = async (rowData, item) => {
    let sendObject = {
      ZamtestTraceableTag: rowData[0],
      QuantityToMove: rowData[2],
    };
    switch (item) {
      case "Enviar":
        let result = await swalConfirmation("yesno");
        if (result) {
          dispatch(PostApi([sendObject], MoveNextApi));
        }
        break;
      case "Detener orden":
        //cambiar de estado, uno atrás
        break;
      case "Rechazar":
        //cancelar
        result = await Swal.fire(
          AlertObject(
            {
              title: `¿Seguro de cancelar la orden ${rowData[1]}?`,
              text: "No serás capaz de revertir esta acción",
            },
            "warning",
            danger,
            dark,
            "Si, Cancelar",
            "No"
          )
        );
        if (result.isConfirmed) {
          dispatch(PostApi([rowData[1]], CancelWorkOrderApi));
        }
        break;
      case "Recibir":
        result = await swalReceive(rowData[1]);
        if (result) dispatch(PostApi([sendObject], MoveNextApi));
        break;
      case "Descargar PDF":
        dispatch(GetFileMid(rowData[1]));
        break;
      case "Subir PDF":
        //Fabricar workOrder y mandarla a calidad
        swalAttributes.title = `Selecciona el plano de la pieza ${rowData[1]}`;
        swalAttributes.text =
          "No olvides que debes de subir el archivo con su mismo nombre, ¡NO CAMBIAR LA REVISIÓN!";
        const { value: file } = await Swal.fire(swalAttributes);
        if (file) {
          const fileName = file.name.toUpperCase()?.split(".PDF")[0];
          const rev_1 = fileName.split("REV_")[1];
          const rev_2 = rowData[1].split("REV_")[1];
          if (fileName !== rowData[1]) {
            helpers.Toast.fire(
              ToastObject(
                "El archivo no corresponde al código de la orden de trabajo.",
                "error"
              )
            );
            return false;
          }
          if (rev_1 !== rev_2) {
            helpers.Toast.fire(
              ToastObject(
                "La revisión del plano debe de ser la misma que la de la orden de trabajo",
                "error"
              )
            );
            return;
          }
          const formData = new FormData();
          formData.append("WorkOrderCode", rowData[1]);
          formData.append("file", file);
          dispatch(UpdateFileMid(formData, sendObject));
        } else {
          helpers.Toast.fire(ToastObject("Debes cargar un archivo", "info"));
        }
        break;
      case "Actualizar PDF":
        swalAttributes.title = `Selecciona el plano de la pieza ${rowData[1]}`;
        swalAttributes.text =
          "Deberás de subir la revisión de este plano ya que fué rechazada la orden de trabajo anterior";
        const { value: newFile } = await Swal.fire(swalAttributes);
        if (newFile) {
          let rev = newFile.name.toUpperCase().split("REV_")[1][0];
          let previouesRev = rowData[1].split("REV_")[1];
          const fileName = newFile.name.toUpperCase()?.split(".PDF")[0];
          if (fileName !== rowData[1]) {
            helpers.Toast.fire(
              ToastObject(
                "El archivo no corresponde al código de la orden de trabajo.",
                "error"
              )
            );
            return false;
          }
          if (rev.charCodeAt() - previouesRev.charCodeAt() > 0) {
            dispatch(
              UpdateObsoleteWorkOrderMid(rowData[0], rowData[1], newFile, rev)
            );
          } else if (rev.charCodeAt() - previouesRev.charCodeAt() === 0) {
            helpers.Toast.fire(
              ToastObject(
                "Ya existe una orden de trabajo con esta revisión",
                "error"
              )
            );
          } else if (rev.charCodeAt() - previouesRev.charCodeAt() < 0) {
            helpers.Toast.fire(
              ToastObject(
                "Ya existe una orden de trabajo con una revisión más reciente",
                "error"
              )
            );
          }
        }
        break;
      default:
        break;
    }
  };

  const onSort = (column, upDown, name) => {
    let variables = Object.keys(sortingTraceable);
    variables.forEach((item) => {
      if (item === name) {
        let position = sortingTraceable[item];
        setQueryParams({
          ...queryParams,
          sort: !upDown ? position[0] : position[1],
        });
      }
    });
  };

  const onChangeQuery = (query) => setQueryParams(query);

  const onSelect = (value) => {
    // filtrar por orden de trabajo
    if (value) setQueryParams({ ...queryParams, projectId: value.id });
    else setQueryParams({ ...queryParams, projectId: "" });
  };

  return (
    <div className="is-flex is-justify-content-center">
      <DataTable
        data={tableData}
        count={count}
        onClick={handleSubmit}
        buttons={buttons}
        states={routeStates}
        columnState={3}
        onSort={onSort}
        onChangeQuery={onChangeQuery}
        pageCount={pageCount}
        visibleColumn={false}
        FilterComponent={
          <ProjectFilter title="Filtrar por projecto" onSelect={onSelect} />
        }
      >
        <h1 className="title is-4 mb-3 is-flex is-justify-content-center">
          {title}
        </h1>
      </DataTable>
    </div>
  );
}

export default WithContextData(ReceivePieces);
