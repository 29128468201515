import React from "react";

function Operations({ operations, checkedOperations, onChange }) {
  return (
    <div>
      <label className="label">Operaciones</label>
      <div className="operation-container">
        {operations.map((operation, index) => (
          <label className="checkbox operation" key={index}>
            <input
              type="checkbox"
              name={operation.id}
              defaultChecked={checkedOperations.includes(
                parseInt(operation.id)
              )}
              onChange={onChange}
            />
            <span className="tag is-dark">{operation.name}</span>
          </label>
        ))}
      </div>
    </div>
  );
}

export default Operations;
