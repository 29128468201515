/* #region  IMPORTS */
import { buildHour } from "../../Helpers";
import { buildOperationForSelect } from "../../Helpers/Factory/operationFactory";
import {
  buildPieceForSelect,
  buildPieceForTable,
} from "../../Helpers/Factory/pieceFactory";
import { buildRecord } from "../../Helpers/Factory/recordFactory";
import {
  buildWorkOrder,
  buildWorkOrderForTable,
  workOrderStatus,
} from "../../Helpers/Factory/workOrderFactory";
import { BAD_REQUEST, OK } from "../../resources/constants/messages";
import { DoubleMoveApi } from "../../Services/MoveTraceableService";
import { GetOperationsApi } from "../../Services/OperationService";
import { GetPiecesByProjectApi } from "../../Services/PiecesService";
import { GetRecordsApi } from "../../Services/RecordService";
import {
  CreateWorkOrderApi,
  GetWorkOrderApi,
  GetWorkOrdersByProjectApi,
} from "../../Services/WorkOrderService";
import { apiFail, apiRequest, apiSuccess } from "../ducks/apiData";
import { CreateData } from "./httpMethodApi";
import * as WorkOrderService from "../../Services/WorkOrderService";
/* #endregion */

export const GetWorkOrdersMid = (projectId) => async (dispatch) => {
  dispatch(apiRequest());
  var result = await GetWorkOrdersByProjectApi(projectId);
  if (result.status === OK) {
    dispatch(
      apiSuccess({
        workOrdersTable: CreateData(result.data, buildWorkOrderForTable),
      })
    );
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const GetWorkOrderInfoForCreateMid = (projectId) => async (dispatch) => {
  dispatch(apiRequest());
  var response = {
    piecesSelect: [],
    operations: [],
  };
  var result = await GetOperationsApi();
  if (result.status === OK) {
    response.operations = CreateData(result.data, buildOperationForSelect);
    result = await GetWorkOrdersByProjectApi(projectId);
    if (result.status === OK) {
      let pieces = result.data.map((workOrder) => {
        return workOrder.piece;
      });
      response.piecesSelect = CreateData(pieces, buildPieceForSelect);
      dispatch(apiSuccess(response));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const GetWorkOrderMid = (workOrderId) => async (dispatch) => {
  dispatch(apiRequest());
  var response = {
    workOrder: {},
    records: {},
  };
  var result = await GetWorkOrderApi(workOrderId);
  if (result.status === OK) {
    response.workOrder = buildWorkOrder(result.data);
    response.workOrder.operations = CreateData(
      result.data.piece.operations,
      buildOperationForSelect
    );
    result = await GetRecordsApi(workOrderId);
    if (result.status === OK) {
      response.records = CreateData(result.data, buildRecord);
      buildHour(result.data[0].date);
      dispatch(apiSuccess(response));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

const getNoMatches = (pieces, workOrders) => {
  var final = pieces.filter(function (piece) {
    for (var i = 0; i < workOrders.length; i++) {
      if (workOrders[i].piece.id === piece.id) return false;
    }
    return true;
  });

  return final;
};

export const GetPendantsWorkOrders = (projectId) => async (dispatch) => {
  dispatch(apiRequest());
  var pieces,
    workOrders,
    pendantWorkOrders = [];
  let result = await GetPiecesByProjectApi(projectId);
  if (result.status === OK) {
    pieces = result.data;
    result = await GetWorkOrdersByProjectApi(projectId);
    if (result.status === OK) {
      workOrders = result.data;
      pendantWorkOrders = getNoMatches(pieces, workOrders);
      dispatch(
        apiSuccess({
          workOrders: CreateData(pendantWorkOrders, buildPieceForTable),
        })
      );
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const CreateWorkOrderMid = (formData, quantity) => async (dispatch) => {
  dispatch(apiRequest());
  let result = await CreateWorkOrderApi(formData);
  if (result.status === OK) {
    let sendObject = {
      ZamtestTraceableTag: result.data.tag,
      QuantityToMove: quantity,
    };
    result = await DoubleMoveApi(sendObject);
    if (result.status === OK) dispatch(apiSuccess({ postConfirmation: true }));
    else dispatch(apiFail({ error: result.error, status: result.status }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const CreateMultipleWorkOrdersMid = (upload) => async (dispatch) => {
  dispatch(apiRequest());
  let errorCounter = [];
  let okCounter = 0;
  await Promise.all(
    upload.map(async (item) => {
      const workOrderCode = item.formData.get("WorkOrder.Code");
      let result = await CreateWorkOrderApi(item.formData);
      if (result.status === OK) {
        let sendObject = {
          ZamtestTraceableTag: result.data.tag,
          QuantityToMove: item.quantity,
        };
        result = await DoubleMoveApi(sendObject);
        if (result.status === OK) okCounter++;
        else errorCounter.push(workOrderCode);
      } else errorCounter.push(workOrderCode);
    })
  );
  if (okCounter === upload.length)
    dispatch(apiSuccess({ postConfirmation: true }));
  else if (errorCounter.length !== 0)
    dispatch(
      apiFail({
        error: errorCounter + " órdenes no pudieron ser creadas",
        status: BAD_REQUEST,
      })
    );
  else
    dispatch(
      apiFail({ error: "Ninguna orden pudo ser creada", status: BAD_REQUEST })
    );
};

export const UpdateObsoleteWorkOrderMid =
  (traceableTag, woId, file, rev) => async (dispatch) => {
    dispatch(apiRequest());
    let newStatus = {
      traceableTag: traceableTag,
      status: workOrderStatus.obsolete,
    };
    let result = await WorkOrderService.ChangeWorkOrderStatusFromTraceableApi(
      newStatus
    );
    if (result.status === OK) {
      result = await WorkOrderService.GetWorkOrderApi(woId);
      if (result.status === OK) {
        let pieceId = result.data.pieceId;
        let sendObject = {
          ZamtestTraceableTag: traceableTag,
          QuantityToMove: result.data.piece.quantity,
        };
        const formData = new FormData();
        formData.append("WorkOrder.Code", file.name.split(".")[0]);
        formData.append("WorkOrder.Rev", rev);
        formData.append("WorkOrder.PieceId", pieceId);
        formData.append("file", file);
        result = await WorkOrderService.CreateWorkOrderApi(formData);
        if (result.status === OK) {
          sendObject.ZamtestTraceableTag = result.data.tag;
          result = await DoubleMoveApi(sendObject);
          if (result.status === OK)
            dispatch(apiSuccess({ postConfirmation: true }));
          else
            dispatch(apiFail({ error: result.error, status: result.status }));
        } else
          dispatch(apiFail({ error: result.error, status: result.status }));
      } else dispatch(apiFail({ error: result.error, status: result.status }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  };

export const GetFileWorkOrder = (projectId) => async (dispatch) => {
  dispatch(apiRequest());
  const result = await WorkOrderService.GetWorkOrdersFileByProjectApi(
    projectId
  );
  if (result.status === BAD_REQUEST) {
    dispatch(apiFail({ error: result.error, status: result.status }));
    return;
  }
  dispatch(apiSuccess());
};
