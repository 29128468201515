import React from "react";
import useTabs from "./TabsReducer";
import Headers from "./Headers";
import { Header, TabsContainer, TabsItems, Item } from "./Library";

function Tabs({ tabs = {}, activeColor, ...props }) {
  const [activeTabs, setActiveTabs] = useTabs();

  return (
    <TabsContainer {...props}>
      <Headers>
        {Object.values(tabs).map((tab, index) => (
          <Header
            key={tab.header}
            onClick={() => setActiveTabs(index)}
            active={activeTabs.includes(index)}
            activeColor={activeColor}
          >
            {tab.header}
          </Header>
        ))}
      </Headers>
      <TabsItems>
        {Object.values(tabs).map((tab, index) => (
          <Item key={tab.header + index} active={activeTabs.includes(index)}>
            {tab.body}
          </Item>
        ))}
      </TabsItems>
    </TabsContainer>
  );
}

export default Tabs;
