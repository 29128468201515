/* #region  IMPORTS */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { danger, primary } from "../../../Helpers/Factory/recordFactory";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import { isWOFileName } from "../../../Helpers/Validators";
import {
  CreateWorkOrderMid,
  CreateMultipleWorkOrdersMid,
  GetPendantsWorkOrders,
  GetWorkOrdersMid,
} from "../../../redux/middleware/workOrders.mid";
import ButtonContainer from "../../1-blocks/b_ButtonContainer";
import Empty from "../../1-blocks/b_Empty";
import DataTable from "../../dataTable/DataTable";
import WithContextData from "../../HOC/WithContextData";
/* #endregion */

function PendantWorkOrders({
  apiData,
  dispatch,
  refresh_route,
  _routes,
  links,
  helpers,
}) {
  /* #region  VARIABLES */
  const [tableData, setTableData] = useState([]);
  const projectId = useSelector((state) => state.projectId);
  const [fileErrors, setFileErrors] = useState([]);
  const [type, setType] = useState("");
  /* #endregion */

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    dispatch(refresh_route(_routes[links.createWorkOrder.name]));
    dispatch(GetPendantsWorkOrders(projectId.id));
    dispatch(GetWorkOrdersMid(projectId.id));
  }, []);

  useEffect(() => {
    if (!apiData.loading && apiData.ok && apiData.data?.workOrders) {
      setTableData(apiData.data.workOrders);
    }
  }, [apiData.data]);
  /* #endregion */

  /* #region  CONFIRMACIÓN AL CREAR */
  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      if (type === "multiple") {
        if (fileErrors.length > 0) {
          Swal.fire({
            icon: "info",
            title: "Planos enviados",
            text:
              "Los planos " +
              fileErrors.map((item) => item.split(".")[0]).join(", ") +
              " no corresponden a las piezas o al proyecto",
          });
        } else {
          helpers.Toast.fire(
            ToastObject(
              `Se han enviado todas tus ordenes hacia revisión de jefe de diseño`,
              "success"
            )
          );
        }
      } else {
        helpers.Toast.fire(
          ToastObject(
            `Se ha enviado la orden ${type} hacia revisión de jefe de diseño`,
            "success"
          )
        );
      }
      dispatch(GetPendantsWorkOrders(projectId.id));
    }
  }, [apiData.data?.postConfirmation]);
  /* #endregion */

  const createWorkOrderFormData = (file, pieceId, pieceCode) => {
    const formData = new FormData();
    let rev = file.name.toUpperCase().split("REV_")[1][0];
    const name = projectId.clientCode ? projectId.clientCode : projectId.code;
    formData.append("WorkOrder.Code", name + " " + pieceCode + "_REV_" + rev);
    formData.append("WorkOrder.Rev", rev);
    formData.append("WorkOrder.PieceId", pieceId);
    formData.append("file", file);
    return formData;
  };

  /* #region  CREAR ORDEN DE TRABAJO  */
  const handleSubmit = async (rowData, item) => {
    // eslint-disable-next-line no-unused-vars
    const [id, code, _, quantity] = rowData;
    switch (item) {
      case "Subir PDF":
        //Fabricar workOrder y mandarla a calidad
        const { value: file } = await Swal.fire({
          title: "Selecciona el plano de la pieza " + code,
          text: `Formato '${
            projectId.clientCode ? projectId.clientCode : projectId.code
          } ${code}_REV_A.pdf'`,
          input: "file",
          showCloseButton: true,
          showCancelButton: true,
          inputAttributes: {
            accept: ".pdf",
            "aria-label": "Sube un PDF",
          },
          cancelButtonText: "Cancelar",
          confirmButtonText: "Enviar",
          reverseButtons: true,
          confirmButtonColor: primary,
          cancelButtonColor: danger,
        });

        if (file) {
          if (isWOFileName(file.name, projectId, code)) {
            setType(file.name);
            const formData = createWorkOrderFormData(file, id, code);
            dispatch(CreateWorkOrderMid(formData, quantity));
          } else {
            helpers.Toast.fire(
              ToastObject(
                "El archivo no coincide con el proyecto y/o la pieza",
                "error"
              )
            );
          }
        }

        break;

      default:
        break;
    }
  };
  /* #endregion */

  /* #region CARGAR MULTIPLES ARCHIVOS */
  const uploadFiles = async () => {
    let files = [];
    const { value: file } = await Swal.fire({
      title: "Selecciona los planos",
      text: `Formato ${
        projectId.clientCode ? projectId.clientCode : projectId.code
      } ${projectId.clientCode ? "B" : "P"}0000_REV_X.pdf`,
      input: "file",
      showCloseButton: true,
      showCancelButton: true,
      inputAttributes: {
        accept: ".pdf",
        multiple: "multiple",
        "aria-label": "Sube un PDF",
      },
      cancelButtonText: "Cancelar",
      confirmButtonText: "Enviar",
      reverseButtons: true,
      confirmButtonColor: primary,
      cancelButtonColor: danger,
    });
    if (file) {
      let errors = [];
      let upload = [];
      files = Object.values(file);
      files.forEach(function (file) {
        let found = false;
        for (var i = 0; i < tableData.length; i++) {
          if (isWOFileName(file.name, projectId, tableData[i].Código)) {
            found = true;
            const id = tableData[i].Id;
            const code = tableData[i].Código;
            const quantity = tableData[i].Cantidad;
            const formData = createWorkOrderFormData(file, id, code);
            upload.push({
              formData: formData,
              quantity: quantity,
            });
          }
        }
        if (!found && !errors.includes(file.name)) errors.push(file.name);
      });
      setType("multiple");
      setFileErrors(errors);
      if (upload.length > 0) dispatch(CreateMultipleWorkOrdersMid(upload));
      else
        helpers.Toast.fire(
          ToastObject("No se encontró ninguna coincidencia", "error")
        );
    } else {
      helpers.Toast.fire(
        ToastObject("Debes seleccinar al menos un archivo", "info")
      );
    }
  };
  /* #endregion */

  return (
    <div>
      {tableData.length > 0 ? (
        <DataTable
          data={tableData}
          handleSubmit={handleSubmit}
          columnName="Acción"
          buttons={[{ name: "Subir PDF", class: "link", icon: "file-pdf" }]}
        >
          <div className="is-flex is-flex-direction-column is-align-items-center mb-5">
            <h4 className="title is-4 mb-0">Crear ordenes de trabajo</h4>
            <h4 className="title is-5 mb-0">{projectId.code}</h4>
            <label className="label has-text-grey-light">
              {projectId.name}
            </label>
          </div>
          <ButtonContainer>
            <button className="button is-info" onClick={uploadFiles}>
              Cargar múltiples planos
            </button>
          </ButtonContainer>
        </DataTable>
      ) : (
        <Empty>
          <p>No tienes ordenes de trabajo pendientes</p>
        </Empty>
      )}
    </div>
  );
}

export default WithContextData(PendantWorkOrders);
