import React, { useEffect, useState } from "react";
import styles from "./changePassword.module.scss";
import WithContextData from "../../HOC/WithContextData";
import FormInput from "../../0-atoms/a_formInput";
import { isPassword } from "../../../Helpers/Validators";
import PasswordInput from "../../1-blocks/b_passwordInput";
import ButtonIcon from "../../1-blocks/b_ButtonIcon";
import { ToastError } from "../../../Helpers/sweetAlertHandler";
import { PostApi } from "../../../redux/middleware/httpMethodApi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Toast } from "../../../Helpers";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import { links } from "../../../resources/constants/links";
import { refresh_route } from "../../../redux/ducks/route";
import routes from "../../../resources/constants/routes";
import { apiSuccess } from "../../../redux/ducks/apiData";
import { ChangePasswordApi } from "../../../Services/authenticationService";
import { AuthorizeService } from "../../api-authorization/AuthorizeService";

const ChangePassword = () => {
  const [form, setForm] = useState({
    id: "",
    password: "",
    confirmPassword: "",
    currentPassword: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const apiData = useSelector((state) => state.apiData);

  useEffect(() => {
    dispatch(refresh_route(routes[links.changePassword.name]));
    dispatch(apiSuccess());
    const auth = new AuthorizeService();
    const user = auth.getUser();
    setForm({ ...form, id: user.sub });
  }, []);

  useEffect(() => {
    if (apiData.data.postConfirmation) {
      Toast.fire(
        ToastObject("Se ha cambiado su contraseña con éxito", "success")
      );
      history.push(links.home.to);
    }
  }, [apiData.data]);

  const onChange = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const onSubmit = () => {
    if (form.password !== form.confirmPassword) {
      ToastError("La confirmación de contraseña no es correcta");
      return;
    }
    const data = {
      id: form.id,
      password: form.currentPassword,
      newPassword: form.password,
    };
    dispatch(PostApi([data], ChangePasswordApi));
  };

  return (
    <div className="is-flex is-justify-content-center">
      <div className={`box ${styles.container} has-background-white`}>
        <header className="is-flex is-justify-content-center">
          <h4 className="title is-4">Cambiar contraseña</h4>
        </header>
        <br />
        <FormInput
          title={"Contraseña actual"}
          placeholder={"Colocar contraseña actual"}
          name="currentPassword"
          onChange={onChange}
          icon="lock"
          type="password"
          onCheckError={isPassword}
          errorMessage="La contraseña debe de contener 7 caracteres, 1 mayúscula, 1 carácter alfanumérico y 1 número"
        />

        <PasswordInput name="password" onChange={onChange} onEnter={onSubmit} />

        <br />
        <footer className="is-flex is-justify-content-center">
          <ButtonIcon
            icon={"lock"}
            onClick={onSubmit}
            className={"is-success"}
            name="Cambiar contraseña"
          />
        </footer>
      </div>
    </div>
  );
};

export default WithContextData(ChangePassword);
