import { workOrderStatus } from "../../Helpers/Factory/workOrderFactory";
import { BAD_REQUEST, OK } from "../../resources/constants/messages";
import * as ChangeRouteService from "../../Services/ChangeRouteService";
import { MoveNextApi } from "../../Services/MoveTraceableService";
import { ChangeWorkOrderStatusFromTraceableApi } from "../../Services/WorkOrderService";
import { CreateCustomRecordApi } from "../../Services/ZamtestTraceableService";
import { apiFail, apiRequest, apiSuccess } from "../ducks/apiData";
import { buildZamtestTraceable, VerifyTraceable } from "./moveTraceable.mid";

export const ChangeRouteQualityNotPass = async (sendObject, record) => {
  var result = await ChangeRouteService.ChangeRouteQualityNotPassApi(
    sendObject
  );
  if (result.status === OK) {
    const newStatus = buildZamtestTraceable(
      result.data,
      workOrderStatus.cancelled
    );
    result = await ChangeWorkOrderStatusFromTraceableApi(newStatus);
    if (result.status === OK) {
      result = await CreateCustomRecordApi(record);
      return result;
    } else return result;
  } else return result;
};

export const ChangeRouteQualityNotPassMid =
  (sendObject, record) => async (dispatch) => {
    dispatch(apiRequest());
    let result = await ChangeRouteQualityNotPass(sendObject, record);
    if (result.status === OK) {
      dispatch(apiSuccess({ postConfirmation: true }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  };

export const ChangeRouteLackInformationMid =
  (sendObject) => async (dispatch) => {
    dispatch(apiRequest());
    var result = await ChangeRouteService.ChangeRouteLackInformationApi(
      sendObject
    );
    if (result.status === OK) {
      const newStatus = buildZamtestTraceable(
        result.data,
        workOrderStatus.pendant
      );
      result = await ChangeWorkOrderStatusFromTraceableApi(newStatus);
      if (result.status === OK) {
        dispatch(apiSuccess({ postConfirmation: true }));
      } else dispatch(apiFail({ error: result.error, status: result.status }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  };

export const ChangeRouteAuxiliarNotPassMid =
  (sendObject, record) => async (dispatch) => {
    dispatch(apiRequest());
    var result = await ChangeRouteService.ChangeRouteAuxiliarNotPassApi(
      sendObject
    );
    if (result.status === OK) {
      const newStatus = buildZamtestTraceable(
        result.data,
        workOrderStatus.cancelled
      );
      result = await ChangeWorkOrderStatusFromTraceableApi(newStatus);
      if (result.status === OK) {
        result = await CreateCustomRecordApi(record);
        if (result.status === OK) {
          dispatch(apiSuccess({ postConfirmation: true }));
        } else
          dispatch(apiFail({ error: result.error, status: result.status }));
      } else dispatch(apiFail({ error: result.error, status: result.status }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  };

export const ChangeRouteTreatmentMid = (sendObject) => async (dispatch) => {
  dispatch(apiRequest());
  var result = await ChangeRouteService.ChangeRouteTreatmentApi(sendObject);
  if (result.status === OK) {
    const tag = VerifyTraceable(result.data);
    sendObject.ZamtestTraceableTag = tag;
    result = await MoveNextApi(sendObject);
    if (result.status === OK) {
      const newStatus = buildZamtestTraceable(
        result.data,
        workOrderStatus.treatment
      );
      result = await ChangeWorkOrderStatusFromTraceableApi(newStatus);
      if (result.status === OK) {
        dispatch(apiSuccess({ postConfirmation: true }));
      } else dispatch(apiFail({ error: result.error, status: result.status }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const ChangeRouteProductionMid = (sendObject) => async (dispatch) => {
  dispatch(apiRequest());
  var result = await ChangeRouteService.ChangeRouteProductionApi(sendObject);
  if (result.status === OK) {
    const tag = VerifyTraceable(result.data);
    sendObject.ZamtestTraceableTag = tag;
    result = await MoveNextApi(sendObject);
    if (result.status === OK) {
      const newStatus = buildZamtestTraceable(
        result.data,
        workOrderStatus.inProduction
      );
      result = await ChangeWorkOrderStatusFromTraceableApi(newStatus);
      if (result.status === OK) {
        dispatch(apiSuccess({ postConfirmation: true }));
      } else dispatch(apiFail({ error: result.error, status: result.status }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const ChangeRouteExternalMachiningMid =
  (sendObject) => async (dispatch) => {
    dispatch(apiRequest());
    var result = await ChangeRouteService.ChangeRouteExternalMachiningApi(
      sendObject
    );
    if (result.status === OK) {
      const tag = VerifyTraceable(result.data);
      sendObject.ZamtestTraceableTag = tag;
      result = await MoveNextApi(sendObject);
      if (result.status === OK) {
        const newStatus = buildZamtestTraceable(
          result.data,
          workOrderStatus.inProduction
        );
        result = await ChangeWorkOrderStatusFromTraceableApi(newStatus);
        if (result.status === OK) {
          dispatch(apiSuccess({ postConfirmation: true }));
        } else
          dispatch(apiFail({ error: result.error, status: result.status }));
      } else dispatch(apiFail({ error: result.error, status: result.status }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  };

export const ChangeRouteMetrologyNotPassMid =
  (sendObject, record) => async (dispatch) => {
    dispatch(apiRequest());
    let result = await ChangeRouteService.ChangeRouteMetrologyNotPassApi(
      sendObject
    );
    if (result.status === OK) {
      result = await CreateCustomRecordApi(record);
      if (result.status === OK) {
        dispatch(apiSuccess({ postConfirmation: true }));
      } else dispatch(apiFail({ error: result.error, status: result.status }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  };

export const ChangeRouteOrderNoCompleted = async (sendObject) => {
  let result = await ChangeRouteService.ChangeRouteOrderNoCompletedApi(
    sendObject
  );
  if (result.status === OK) {
    const newStatus = buildZamtestTraceable(
      result.data,
      workOrderStatus.incomplete
    );
    result = await ChangeWorkOrderStatusFromTraceableApi(newStatus);
    return result;
  } else return result;
};

export const ChangeRouteAndMove = async (sendObject) => {
  let result = await ChangeRouteService.ChangeRouteCompleteApi(sendObject);
  if (result.status === OK) {
    const tag = VerifyTraceable(result.data);
    sendObject.ZamtestTraceableTag = tag;
    result = await MoveNextApi(sendObject);
    return result;
  } else return result;
};

export const MultipleChangeRouteProductionMid =
  (data, internalProduction = true) =>
  async (dispatch) => {
    dispatch(apiRequest());
    if (!Array.isArray(data) || data.length === 0) {
      dispatch(
        apiFail({ error: "Los datos son incorrectos", status: BAD_REQUEST })
      );
      return;
    }
    let error = false;
    await Promise.all(
      data.map(async (item) => {
        const [id, , quantity] = item;
        let sendObject = {
          ZamtestTraceableTag: id,
          QuantityToMove: quantity,
        };
        let result = {};
        if (internalProduction)
          result = await ChangeRouteService.ChangeRouteProductionApi(
            sendObject
          );
        else
          result = await ChangeRouteService.ChangeRouteExternalMachiningApi(
            sendObject
          );
        if (result.status !== OK) {
          error = true;
          return;
        }
        const tag = VerifyTraceable(result.data);
        sendObject.ZamtestTraceableTag = tag;
        result = await MoveNextApi(sendObject);
        if (result.status !== OK) {
          error = true;
          return;
        }
        const newStatus = buildZamtestTraceable(
          result.data,
          workOrderStatus.inProduction
        );
        result = await ChangeWorkOrderStatusFromTraceableApi(newStatus);
        if (result.status !== OK) {
          error = true;
          return;
        }
      })
    );
    if (error)
      dispatch(
        apiFail({
          error: "Hubo un error al enviar las ordenes de trabajo a producción",
          status: BAD_REQUEST,
        })
      );
    else dispatch(apiSuccess({ postConfirmation: true }));
  };
