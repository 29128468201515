import Swal from "sweetalert2";
import {
  emptyError,
  emptyField,
  emptyForm,
} from "../../resources/constants/messages";
import * as places from "../../resources/constants/places";
import { danger, dark, primary, warning } from "./recordFactory";
import { AlertObject, InputObject } from "./swalFactory";

export const GetInitDataForSendPiecesByPlace = (place) => {
  let title = "";
  let buttons = [];
  let inputOptions = {};

  switch (place) {
    case places.Design:
      title = "Enviar a calidad";
      buttons = [
        { name: "Enviar", class: "primary", icon: "share-square" },
        { name: "Rechazar", class: "danger", icon: "times" },
        { name: "Ver PDF", class: "dark", icon: "file-pdf" },
      ];
      break;

    case places.Quality:
      title = "Enviar ordenes de trabajo a planeación";
      buttons = [
        { name: "Aprobar", class: "success", icon: "check" },
        { name: "Orden incompleta", class: "warning", icon: "stop" },
        { name: "Rechazar", class: "danger", icon: "times" },
        { name: "Ver PDF", class: "dark", icon: "file-pdf" },
      ];
      break;

    case places.Planning:
      title = "Enviar piezas para producción";
      buttons = [
        { name: "Aprobar", class: "success", icon: "check" },
        { name: "Rechazar", class: "danger", icon: "times" },
        { name: "Ver PDF", class: "dark", icon: "file-pdf" },
      ];
      inputOptions = {
        planningProduction: "Producción",
        planningFinishes: "Tratamiento",
        warehouse: "Almacén",
      };
      break;

    case places.Auxiliar:
      title = "Ordenes de planeación";
      buttons = [
        { name: "Enviar a", class: "primary", icon: "share-square" },
        { name: "Rechazar", class: "danger", icon: "times" },
        { name: "Ver PDF", class: "dark", icon: "file-pdf" },
        { name: "Detener orden", class: "warning", icon: "stop" },
      ];
      inputOptions = {
        auxiliarProduction: "Producción",
        external: "Productor externo",
        metrology: "Metrología",
      };
      break;

    case places.Production:
      title = "Ordenes en MaqZam";
      buttons = [
        { name: "Recibir", class: "primary", icon: "box-open" },
        { name: "Rechazar", class: "danger", icon: "times" },
        { name: "Ver PDF", class: "dark", icon: "file-pdf" },
      ];
      break;

    case places.ExternalMachining:
      title = "Recibir de externo";
      buttons = [
        { name: "Recibir", class: "primary", icon: "box-open" },
        { name: "Rechazar", class: "danger", icon: "times" },
        { name: "Ver PDF", class: "dark", icon: "file-pdf" },
      ];
      break;

    case places.Metrology:
      title = "Ordenes de trabajo en Metrología";
      buttons = [
        { name: "Aprobar", class: "success", icon: "check" },
        {
          name: "Enviar con fallo",
          class: "warning",
          icon: "exclamation-triangle",
        },
        { name: "Paso sin inspección", class: "link", icon: "unlock" },
        { name: "Rechazar", class: "danger", icon: "times" },
        { name: "Ver PDF", class: "dark", icon: "file-pdf" },
      ];
      break;

    case places.Finished:
      title = "Recibir piezas de tratamiento";
      buttons = [
        { name: "Recibir piezas", class: "success", icon: "hand-paper" },
        { name: "Ver PDF", class: "dark", icon: "file-pdf" },
      ];
      break;

    default:
      title = "Enviar piezas";
      break;
  }

  return {
    title: title,
    buttons: buttons,
    inputOptions: inputOptions,
  };
};

export const swalConfirmation = async (type, inputOptions = {}) => {
  var result = {};
  switch (type) {
    case "yesnoProduction":
      result = await Swal.fire(
        AlertObject(
          {
            title: "¿Seguro de recibir estas piezas?",
            text: "No serás capaz de revertir esta acción",
          },
          "warning"
        )
      );
      return result.isConfirmed;
    case "yesno":
      result = await Swal.fire(
        AlertObject(
          {
            title: "¿Seguro de enviar estas piezas?",
            text: "No serás capaz de revertir esta acción",
          },
          "warning"
        )
      );
      return result.isConfirmed;

    case "number":
      const { value } = await Swal.fire(
        InputObject(
          "Coloca la cantidad de piezas a enviar",
          "number",
          "Cantidad de piezas",
          emptyField
        )
      );
      return value;

    case "numberProduction":
      const { value: formValues } = await Swal.fire({
        title: "Coloca los datos para continuar",
        html:
          '<div class="field" style="overflow: hidden">' +
          '<label class="label">Cantidad de piezas</label>' +
          '<input id="swal-input1" class="input mb-3" type="number" min="0">' +
          '<label class="label">Tiempo de fabricación</label>' +
          '<div class="columns"><div class="column">' +
          '<label class="label is-small">Días</label>' +
          '<input id="input-days" class="input" type="number"  value="0" min="0" onfocus={this.select()}></div>' +
          '<div class="column">' +
          '<label class="label is-small">horas</label>' +
          '<input id="input-hour" class="input" type="number" value="0" min="0" onfocus={this.select()}></div>' +
          '<div class="column">' +
          '<label class="label is-small">Minutos</label>' +
          '<input id="input-minutes" class="input" type="number" value="0" min="0" onfocus={this.select()}></div></div>' +
          "</div>",
        focusConfirm: false,
        confirmButtonColor: primary,
        confirmButtonText: "Enviar",
        preConfirm: () => {
          let quantity = parseInt(document.getElementById("swal-input1").value);
          let hours = parseInt(document.getElementById("input-hour").value);
          let minutes = parseInt(
            document.getElementById("input-minutes").value
          );
          let days = parseInt(document.getElementById("input-days").value);
          if (isNaN(days) || days < 0) days = 0;
          if (
            isNaN(quantity) ||
            isNaN(hours) ||
            isNaN(minutes) ||
            quantity < 0 ||
            hours < 0 ||
            minutes < 0
          )
            Swal.showValidationMessage(emptyForm);
          if (quantity === 0) Swal.showValidationMessage(emptyError);
          if (hours > 23) {
            Swal.showValidationMessage("No puedes colocar más de 23 horas");
          }
          if (minutes > 59) {
            Swal.showValidationMessage("No puedes colocar más de 59 minutos");
          }
          let result = `${days}:${hours}:${minutes}:00`;
          return [quantity, result];
        },
      });
      return formValues ?? [0, ""];

    case "numberMessage":
      const { value: form } = await Swal.fire({
        title: "Coloca los datos para continuar",
        html:
          '<div class="field">' +
          '<label class="label">Cantidad de piezas</label>' +
          '<input id="swal-input1" class="input mb-3" type="number">' +
          '<label class="label">Razón de rechazo</label>' +
          '<input id="swal-input2" class="input" type="text">' +
          "</div>",
        focusConfirm: false,
        confirmButtonColor: danger,
        confirmButtonText: "Rechazar",
        preConfirm: () => {
          let quantity = parseInt(document.getElementById("swal-input1").value);
          let reason = document.getElementById("swal-input2").value;
          if (isNaN(quantity) || !reason) Swal.showValidationMessage(emptyForm);
          if (quantity === 0) Swal.showValidationMessage(emptyError);
          return [quantity, reason];
        },
      });
      return form ?? [0, ""];

    case "message":
      const { value: formMessage } = await Swal.fire({
        title: "Coloca los datos para continuar",
        html:
          '<div class="field">' +
          '<label class="label">Razón de rechazo</label>' +
          '<input id="input-message" class="input" type="text">' +
          "</div>",
        focusConfirm: false,
        confirmButtonColor: danger,
        confirmButtonText: "Rechazar",
        preConfirm: () => {
          let reason = document.getElementById("input-message").value;
          if (!reason) Swal.showValidationMessage(emptyForm);
          return [0, reason];
        },
      });
      return formMessage ?? [0, ""];

    case "reject":
      result = await Swal.fire(
        AlertObject(
          {
            title: `¿Seguro de rechazar ${
              inputOptions.workOrder
                ? `la orden de trabajo ${inputOptions.workOrder}`
                : "esta orden de trabajo"
            }?`,
            text: "No serás capaz de revertir esta acción",
          },
          "error",
          danger,
          dark,
          "Rechazar",
          "Cancelar"
        )
      );
      return result.isConfirmed;

    case "select":
      const { value: option } = await Swal.fire(
        InputObject(
          "Selecciona una opción",
          "select",
          "",
          "",
          inputOptions,
          "Mandar piezas hacia:",
          primary,
          danger,
          "Mandar"
        )
      );
      return option;

    case "failure":
      result = await Swal.fire(
        AlertObject(
          {
            title: `¿Seguro de enviar la orden de trabajo ${inputOptions.workOrder} con fallo?`,
            text: "No serás capaz de revertir esta acción",
          },
          "question"
        )
      );
      return result.isConfirmed;

    case "stop":
      result = await Swal.fire(
        AlertObject(
          {
            title: `¿Seguro de detener la orden de trabajo ${inputOptions.workOrder}?`,
            text: "Se regresará esta orden a diseño para la corrección del plano",
          },
          "warning",
          warning,
          dark,
          "Detener",
          "Cancelar"
        )
      );
      return result.isConfirmed;

    case "pass":
      result = await Swal.fire(
        AlertObject(
          {
            title: `¿Seguro de enviar la orden de trabajo ${inputOptions.workOrder} sin inspección?`,
            text: "No serás capaz de revertir esta acción",
          },
          "info"
        )
      );
      return result.isConfirmed;

    case "confirmMessage":
      result = await Swal.fire(
        AlertObject(
          {
            title: `¿Quieres enviar la orden trabajo ${inputOptions.workOrder} hacia ${inputOptions.place}?`,
            text: "No serás capaz de revertir esta acción",
          },
          "warning"
        )
      );
      return result.isConfirmed;

    default:
      break;
  }
};
