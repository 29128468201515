export const modalData = {
  icon: null,
  title: "",
  body: "",
  to: "",
  link: "",
  button: "",
  loader: false,
  OutsideClick: false,
};

export const loadingData = {
  title: "Cargando",
  body: "",
  loader: true,
  OutsideClick: false,
  icon: null,
};
