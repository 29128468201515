import * as urlConstants from "./helpers/urlConstants";
import { fetchMethod } from "./helpers/fetchMethod";
import { POST } from "./helpers/serviceConstants";

// string ZamtestTraceableTag
// int QuantityToMove

export const ChangeRouteCompleteApi = async (data) => {
  return await fetchMethod(urlConstants.ChangeRouteComplete, POST, data);
};

export const ChangeRouteWarehouseApi = async (data) => {
  return await fetchMethod(urlConstants.ChangeRouteFinished, POST, data);
};

export const ChangeRouteFinishedApi = async (data, isOk) => {
  return await fetchMethod(
    urlConstants.ChangeRouteFinished + `/${isOk ? "true" : "false"}`,
    POST,
    data
  );
};

export const ChangeRouteFinishedWithoutInpection = async (data) => {
  return await fetchMethod(
    urlConstants.ChangeRouteFinishedWithoutInspection,
    POST,
    data
  );
};

export const ChangeRouteProductionApi = async (data) => {
  return await fetchMethod(urlConstants.ChangeRouteProduction, POST, data);
};

export const ChangeRouteQualityNotPassApi = async (data) => {
  return await fetchMethod(urlConstants.ChangeRouteQualityNotPass, POST, data);
};

export const ChangeRouteMetrologyNotPassApi = async (data) => {
  return await fetchMethod(
    urlConstants.ChangeRouteMetrologyNotPass,
    POST,
    data
  );
};

export const ChangeRouteExternalMachiningApi = async (data) => {
  return await fetchMethod(
    urlConstants.ChangeRouteExternalMachining,
    POST,
    data
  );
};

export const ChangeRouteTreatmentApi = async (data) => {
  return await fetchMethod(urlConstants.ChangeRouteTreatment, POST, data);
};

export const ChangeRouteLackInformationApi = async (data) => {
  return await fetchMethod(urlConstants.ChangeRouteLackInformation, POST, data);
};

export const ChangeRouteAuxiliarNotPassApi = async (data) => {
  return await fetchMethod(urlConstants.ChangeRouteAuxiliarNotPass, POST, data);
};

export const ChangeRouteOrderNoCompletedApi = async (data) => {
  return await fetchMethod(
    urlConstants.ChangeRouteOrderNoCompleted,
    POST,
    data
  );
};
