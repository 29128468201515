import React, { createRef, useEffect, useRef } from "react";
import { useState } from "react";
import { ToastError } from "../../Helpers/sweetAlertHandler";
import {
  cleanRefInputs,
  validateInputWithRef,
  validateRefInputs,
  validForm,
} from "../../Helpers/validationsForRefInputs";
import { PieceValidator } from "../../Helpers/Validators/FormsValidation";
import Input from "../0-atoms/a_input";
import ButtonIcon from "../1-blocks/b_ButtonIcon";
import PropTypes from "prop-types";
import useMounted from "../../hooks/useMounted";

export default function PieceForm({
  deafultValues = {},
  readonly = false,
  onSubmit = () => {},
  title,
  children,
}) {
  const refs = [
    "projectId",
    "code",
    "description",
    "quantity",
    "thickness",
    "width",
    "height",
    "pieceMaterial",
    "pieceMaterialKind",
    "metallicFinish",
  ];
  const inputs = useRef(refs.map(() => createRef()));
  const [form, setForm] = useState(deafultValues);
  const mounted = useMounted();

  useEffect(() => {
    if (mounted) {
      validateRefInputs(PieceValidator, inputs.current, [
        "projectId",
        "thickness",
        "width",
        "height",
      ]);
    }
  }, [mounted]);

  const onKeyUp = ({ name, value }) => {
    setForm({ ...form, [name]: value });
    validateInputWithRef(PieceValidator, inputs.current, name);
  };

  const onSend = () => {
    const result = validForm(
      validateRefInputs(PieceValidator, inputs.current, [
        "projectId",
        "thickness",
        "width",
        "height",
      ])
    );
    if (result) {
      onSubmit({ ...form });
      cleanRefInputs(inputs.current);
    } else ToastError("Completa los datos del fomulario correctamente");
  };

  return (
    <div className="card form-container">
      <div className="card-content">
        <h1 className="title is-4 is-flex is-justify-content-center">
          {title}
        </h1>
        <label className="label is-small">Datos generales</label>
        <div className="border-box p-4 mb-3">
          {readonly && (
            <>
              <label className="label is-small">Proyecto</label>
              <Input
                className="is-small is-small"
                icon="spell-check"
                disabled={readonly}
                defaultValue={form?.projectId}
                name="projectId"
                onKeyUp={onKeyUp}
                ref={inputs.current[0]}
                placeholder="Proyecto"
              />
            </>
          )}
          <div className="columns">
            <div className="column">
              <label className="label is-small">Código</label>
              <Input
                className="is-small"
                icon="barcode"
                disabled={readonly}
                defaultValue={form?.code}
                name="code"
                onKeyUp={onKeyUp}
                ref={inputs.current[1]}
                placeholder="Código"
              />
            </div>
            <div className="column">
              <label className="label is-small">Nombre</label>
              <Input
                className="is-small"
                icon="puzzle-piece"
                disabled={readonly}
                defaultValue={form?.description}
                name="description"
                onKeyUp={onKeyUp}
                ref={inputs.current[2]}
                placeholder="Nombre"
              />
            </div>
            <div className="column">
              <label className="label is-small">Cantidad</label>
              <Input
                className="is-small"
                icon="tasks"
                disabled={readonly}
                defaultValue={form?.quantity}
                name="quantity"
                onKeyUp={onKeyUp}
                ref={inputs.current[3]}
                placeholder="Cantidad"
              />
            </div>
          </div>
        </div>

        <label className="label is-small">Dimensiones</label>
        <div className="border-box p-5 mb-5">
          <div className="columns">
            <div className="column">
              <label className="label is-small">Grosor</label>
              <Input
                className="is-small"
                icon="circle"
                disabled={readonly}
                defaultValue={form?.thickness}
                name="thickness"
                onKeyUp={onKeyUp}
                ref={inputs.current[4]}
                placeholder="Grosor"
              />
            </div>
            <div className="column">
              <label className="label is-small">Ancho</label>
              <Input
                className="is-small"
                icon="arrows-alt-h"
                disabled={readonly}
                defaultValue={form?.width}
                name="width"
                onKeyUp={onKeyUp}
                ref={inputs.current[5]}
                placeholder="Ancho"
              />
            </div>
            <div className="column">
              <label className="label is-small">Largo</label>
              <Input
                className="is-small"
                icon="arrows-alt-v"
                disabled={readonly}
                defaultValue={form?.height}
                name="height"
                onKeyUp={onKeyUp}
                ref={inputs.current[6]}
                placeholder="Largo"
              />
            </div>
          </div>
        </div>

        <label className="label is-small">Materiales</label>
        <div className="border-box p-5 mb-5">
          <div className="columns">
            <div className="column">
              <label className="label is-small">Material</label>
              <Input
                className="is-small is-small"
                icon="sticky-note"
                disabled={readonly}
                defaultValue={form?.pieceMaterial}
                name="pieceMaterial"
                onKeyUp={onKeyUp}
                ref={inputs.current[7]}
                placeholder="Material"
              />
            </div>
            <div className="column">
              <label className="label is-small">Tipo de material</label>
              <Input
                className="is-small"
                icon="sticky-note"
                disabled={readonly}
                defaultValue={form?.pieceMaterialKind}
                name="pieceMaterialKind"
                onKeyUp={onKeyUp}
                ref={inputs.current[8]}
                placeholder="Tipo de material"
              />
            </div>
            <div className="column">
              <label className="label is-small">Acabado metálico</label>
              <Input
                className="is-small"
                icon="sticky-note"
                disabled={readonly}
                defaultValue={form?.metallicFinish}
                name="metallicFinish"
                onKeyUp={onKeyUp}
                ref={inputs.current[9]}
                placeholder="Acabado metálico"
              />
            </div>
          </div>
        </div>

        <div>{children}</div>

        {!readonly && (
          <ButtonIcon
            className="is-primary is-fullwidth"
            icon="paper-plane"
            name="Enviar formulario"
            onClick={onSend}
          />
        )}
      </div>
    </div>
  );
}

PieceForm.propTypes = {
  onSubmit: PropTypes.func,
  deafultValues: PropTypes.object,
  readonly: PropTypes.bool,
  title: PropTypes.string.isRequired,
};
