import React, { forwardRef, useEffect, useState } from "react";

const InputIcon = forwardRef(
  (
    {
      value,
      type = "text",
      OnType,
      icon,
      errorMessage,
      isValid,
      decimals = true,
      isTextArea = false,
      isBig = false,
      ...props
    },
    ref
  ) => {
    const [className, setClassName] = useState("");

    useEffect(() => {
      if (!isValid) {
        setClassName("is-danger");
      } else {
        setClassName("is-primary");
      }

      if (isValid === null) {
        setClassName("");
      }
    }, [isValid]);

    const onKeyDown = async (e) => {
      if (decimals) {
        if (e.key === "+") {
          e.preventDefault();
        } else if (e.key === "-") {
          e.preventDefault();
        } else if (e.key === "e") {
          e.preventDefault();
        }
      } else {
        if (e.key === ".") {
          e.preventDefault();
        } else if (e.key === "+") {
          e.preventDefault();
        } else if (e.key === "-") {
          e.preventDefault();
        } else if (e.key === "e") {
          e.preventDefault();
        }
      }
    };

    return (
      <>
        {isTextArea ? (
          <div style={{ marginBottom: "10px" }}>
            <label className="label is-small">{props.placeholder}</label>
            <textarea
              ref={ref}
              className={`textarea ${className} is-small`}
              placeholder={props.placeholder}
              onChange={OnType}
              aria-label={props.placeholder}
              name={props.name}
              value={value}
              disabled={props.disabled}
            ></textarea>
            {className === "is-danger" ? (
              <span
                data-testid={props.placeholder}
                className="tag is-danger"
                style={{
                  width: "100%",
                  height: "auto",
                  whiteSpace: "unset",
                  textAlign: "center",
                }}
              >
                {errorMessage}
              </span>
            ) : (
              <span></span>
            )}
          </div>
        ) : (
          <>
            <label className="label is-small">{props.placeholder}</label>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  ref={ref}
                  aria-label={props.placeholder}
                  name={props.name}
                  className={`input ${className} ${
                    isBig ? "is-big" : "is-small"
                  }`}
                  type={type}
                  onChange={OnType}
                  onKeyDown={type === "number" ? onKeyDown : null}
                  {...props}
                  min="0"
                  disabled={props.disabled}
                  value={value}
                />
                <span className="icon is-small is-left">
                  <i className={`fas fa-${icon}`}></i>
                </span>
                {className === "is-danger" && errorMessage !== undefined ? (
                  <span
                    data-testid={props.placeholder}
                    className="tag is-danger"
                    style={{
                      width: "100%",
                      height: "auto",
                      whiteSpace: "unset",
                      textAlign: "center",
                    }}
                  >
                    {errorMessage}
                  </span>
                ) : null}
              </p>
            </div>
          </>
        )}
      </>
    );
  }
);

InputIcon.displayName = "InputIcon";

export default InputIcon;
