const SELECT = "SELECT";

export const setSelection = (data = 5) => ({
  type: SELECT,
  payload: data,
});

const dataTableSelectionReducer = (state = 5, action) => {
  switch (action.type) {
    case SELECT:
      return action.payload;
    default:
      return state;
  }
};

export default dataTableSelectionReducer;
