import React, { useEffect, useState } from "react";
import styles from "./resetPassword.module.scss";
import ButtonIcon from "../../1-blocks/b_ButtonIcon";
import { ToastError } from "../../../Helpers/sweetAlertHandler";
import { PostApi } from "../../../redux/middleware/httpMethodApi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Toast } from "../../../Helpers";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import { links } from "../../../resources/constants/links";
import { refresh_route } from "../../../redux/ducks/route";
import routes from "../../../resources/constants/routes";
import { apiSuccess } from "../../../redux/ducks/apiData";
import { ResetPasswordApi } from "../../../Services/authenticationService";
import PasswordInput from "../../1-blocks/b_passwordInput";

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [form, setForm] = useState({
    code: "",
    userId: "",
    password: "",
    confirmPassword: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const apiData = useSelector((state) => state.apiData);
  const location = useLocation();

  useEffect(() => {
    const query = location.search;
    let code = "";
    let userId = "";
    if (query) {
      userId = query.split("&")[0].replace("?", "").split("=")[1];
      code = query.split("&")[1].split("=")[1];
    }
    if (code === "" || userId === "") {
      ToastError(
        "No puedes acceder a la página de restablecer contraseña sin código de usuario"
      );
      history.replace(links.login.to);
    } else setForm({ ...form, code, userId });
  }, [location]);

  useEffect(() => {
    dispatch(refresh_route(routes[links.changePassword.name]));
    dispatch(apiSuccess());
  }, []);

  useEffect(() => {
    if (apiData.data.postConfirmation) {
      Toast.fire(
        ToastObject("Se ha restablecido su contraseña con éxito", "success")
      );
      history.push(links.login.to);
    }

    if (!apiData.loading && !apiData.ok) setHasError(true);
    else if (!apiData.loading && apiData.ok) setHasError(false);
  }, [apiData.data, apiData.ok]);

  useEffect(() => {
    setLoading(apiData.loading);
  }, [apiData.loading]);

  const onChange = (value, name) => setForm({ ...form, [name]: value });

  const onSubmit = () => {
    if (form.password !== form.confirmPassword) {
      ToastError("Las contraseñas no coinciden");
      return;
    }

    const data = {
      code: form.code,
      userId: form.userId,
      newPassword: form.password,
    };
    dispatch(PostApi([data], ResetPasswordApi));
  };

  return (
    <div className="is-flex is-justify-content-center is-align-items-center is-fullheight">
      <div className={`${styles.container}`}>
        <div className={`box has-background-white`}>
          <header className="is-flex is-justify-content-center mb-5">
            <h4 className="title is-4">Restablecer contraseña</h4>
          </header>

          <PasswordInput
            onChange={onChange}
            name={"password"}
            onEnter={onSubmit}
          />

          <br />
          <footer className="is-flex is-justify-content-center">
            <ButtonIcon
              icon={"paper-plane"}
              onClick={onSubmit}
              className={`is-success ${loading && "is-loading"}`}
              name="Enviar"
            />
          </footer>
        </div>
        <div
          className={`${styles.text} ${hasError ? styles.show : styles.hide}`}
        >
          <p className="has-text-danger">
            No se logró resetear su contraseña ya que su código ha expirado,
            intente mandar otro correo en la página{" "}
            <strong
              className="has-text-info is-clickable"
              onClick={() => history.replace(links.forgotPassword.to)}
            >
              Olvide mi contraseña
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
}
