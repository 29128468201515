import {
  //alphanumeric,
  noNumbers,
  noSpaces,
  numbersOnly,
  decimalNumbers,
  ProjectName,
  AssemblyName,
  PieceName,
  password,
  email,
} from "../../resources/constants/regex";

export const isOnlyLetters = (data) => {
  return noNumbers.test(data);
};

export const isWithoutSpaces = (data) => {
  return noSpaces.test(data);
};

export const isOnlyNumbers = (data) => {
  if (Number(data)) {
    if (data.length > 0) {
      return numbersOnly.test(data);
    } else return false;
  } else {
    return false;
  }
};

export const isOnlyNumbersDecimal = (data) => {
  if (Number(data)) {
    if (data.length > 0) {
      return decimalNumbers.test(data);
    } else return false;
  } else {
    return false;
  }
};

export const isEmpty = (data) => {
  if (data !== "") return true;
  else return false;
};

export const isProjectName = (data) => {
  if (data.length > 0) {
    if (ProjectName.test(data)) return true;
    else return false;
  } else return false;
};

export const isAssemblyName = (data) => {
  if (data.length > 0) {
    return AssemblyName.test(data);
  } else return false;
};

export const isPieceName = (data) => {
  if (data.length > 0) {
    return PieceName.test(data);
  } else return false;
};

export const isRev = (data) => {
  if (data.length === 1) return noNumbers.test(data);
  else return false;
};

export const isWOFileName = (data, project, pieceName) => {
  data = data.toUpperCase();
  const projectId = project.clientCode ? project.clientCode : project.code;
  const workOrderCode = projectId + " " + pieceName + "_REV_";
  return data.includes(workOrderCode);
};

export const isPassword = (data) => {
  if (isEmpty(data)) return password.test(data);
  return false;
};

export const isEmail = (data) => {
  if (isEmpty(data)) return email.test(data);
  return false;
};

export const isZamtestEmail = (data) => {
  if (isEmpty(data)) {
    if (email.test(data)) {
      const domain = data.split("@")[1];
      return domain === "zamtest.com";
    }
  }
  return false;
};
