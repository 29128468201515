import React, { useEffect, useRef, useState } from "react";
import DataTable from "../../2-systems/s_DataTable";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import * as places from "../../../resources/constants/places";
import * as traceableFactory from "../../../Helpers/Factory/traceableFactory";
import {
  GetInitDataForSendPiecesByPlace,
  swalConfirmation,
} from "../../../Helpers/Factory/SendPiecesFactory";
import * as moveTraceableMid from "../../../redux/middleware/moveTraceable.mid";
import * as changeRouteMid from "../../../redux/middleware/changeRoute.mid";
import useMounted from "../../../hooks/useMounted";
import { refresh_route } from "../../../redux/ducks/route";
import routes from "../../../resources/constants/routes";
import { links } from "../../../resources/constants/links";
import { Toast } from "../../../Helpers";
import Tab from "../../0-atoms/a_tab";
import WithTabsDataTable from "../../HOC/WithTabsDataTable";
import ProjectFilter from "../../2-systems/s_projectFilter";
import { GetFileMid } from "../../../redux/middleware/file.mid";
import { quantityError } from "../../../resources/constants/messages";
import { PostApi } from "../../../redux/middleware/httpMethodApi";
import { ChangeRouteCompleteApi } from "../../../Services/ChangeRouteService";
import { buildRecordForReject } from "../../../Helpers/Factory/recordFactory";

function AuxiliarSendPieces({
  apiData,
  tableContent,
  dispatch,
  onCallApi,
  onChangeQuery,
  onSort,
  count,
  pageCount,
}) {
  const dataTableRef = useRef(null);
  const mounted = useMounted();
  const [buttons, setButtons] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (mounted) {
      dispatch(
        refresh_route(routes[links.auxiliarSendPieces.name], places.Planning)
      );

      let initData = GetInitDataForSendPiecesByPlace(places.Auxiliar);
      setTitle(initData.title);
      setButtons(initData.buttons);
    }
  }, [mounted]);

  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      Toast.fire(ToastObject("Se han enviado con éxito las piezas", "success"));
      onCallApi();
      if (title !== "Maquinado interno" && title !== "Maquinado externo")
        dataTableRef.current.UnselectAllTable();
    }
  }, [apiData.data?.postConfirmation]);

  const onChangeButton = (state = 0) => {
    if (state === 0) {
      const index = buttons.findIndex((b) => b.name === "Enviar a metrología");
      if (index > -1) {
        const button = buttons[index];
        button.name = "Enviar a";
        setButtons([
          ...buttons,
          { name: "Detener orden", class: "warning", icon: "stop" },
        ]);
      }
    } else if (state === 1) {
      const index = buttons.findIndex((b) => b.name === "Enviar a");
      if (index > -1) {
        const button = buttons[index];
        button.name = "Enviar a metrología";
        const i = buttons.findIndex((b) => b.name === "Detener orden");
        buttons.splice(i, 1);
        setButtons([...buttons]);
      }
    }
  };

  const onTabSelected = (name) => {
    if (name === "Ordenes de planeación") {
      // cambiar nombre
      setTitle("Ordenes de planeación");
      onChangeQuery({ routeId: ["1", "8"], isRejected: false });
      onChangeButton();
    } else if (name === "Ordenes rechazadas") {
      setTitle("Ordenes rechazadas");
      onChangeQuery({ routeId: ["5", "6"], isRejected: true });
      onChangeButton();
    } else if (name === "Rechazos de metrología") {
      setTitle("Rechazos de metrología");
      onChangeQuery({ routeId: "3", isRejected: false });
      onChangeButton();
    } else if (name === "Maquinado interno") {
      setTitle("Maquinado interno");
      onChangeQuery({ routeId: "6", isRejected: false });
      onChangeButton(1);
    } else if (name === "Maquinado externo") {
      setTitle("Maquinado externo");
      onChangeQuery({ routeId: "5", isRejected: false });
      onChangeButton(1);
    }
  };

  const handleSubmit = async (rowData, item) => {
    if (Array.isArray(rowData)) {
      if (Array.isArray(rowData) && rowData.length === 0) {
        Toast.fire(
          ToastObject(
            "Debes de seleccionar al menos una orden de trabajo",
            "warning"
          )
        );
        return;
      }

      if (Array.isArray(rowData[0])) onMultipleRow(rowData, item);
      else {
        const [id, workOrder, quantity] = rowData;
        onSingleRow(id, workOrder, quantity, item);
      }
    }
  };

  const onSingleRow = async (id, workOrder, quantity, item) => {
    let sendObject = {
      ZamtestTraceableTag: id,
      QuantityToMove: quantity,
    };

    if (item === "Enviar a metrología") {
      const result = await swalConfirmation("confirmMessage", {
        workOrder,
        place: "Metrología",
      });
      if (!result) return;
      if (quantity > 1) {
        const value = await swalConfirmation("number");
        if (!value) return;
        if (parseInt(value) > parseInt(quantity)) {
          Toast.fire(ToastObject(quantityError, "warning"));
          return;
        }
        quantity = parseInt(value);
      }

      sendObject.QuantityToMove = parseInt(quantity);
      dispatch(PostApi([sendObject], ChangeRouteCompleteApi));
    } else if (item === "Ver PDF") dispatch(GetFileMid(workOrder)); // VER PDF
    else {
      // TODO PROBAR ESTE MÉTODO
      const result = await swalConfirmation("reject", { workOrder });
      if (!result) return;
      const [, message] = await swalConfirmation("message");
      if (!message) return;
      let record = buildRecordForReject(
        sendObject.ZamtestTraceableTag,
        places.Auxiliar,
        message,
        sendObject.QuantityToMove
      );
      record.title = "Rechazo de auxiliar";
      dispatch(
        changeRouteMid.ChangeRouteAuxiliarNotPassMid(sendObject, record)
      );
    }
  };

  const onMultipleRow = async (rows, button) => {
    var result = {};
    if (button === "Ver PDF") {
      dispatch(moveTraceableMid.VisualizeMultipleFilesMid(rows));
      dataTableRef.current.UnselectAllTable();
    } else if (button === "Enviar a") {
      let option = await swalConfirmation("select", {
        auxiliarProduction: "Producción",
        external: "Productor externo",
      });
      if (option === "auxiliarProduction")
        dispatch(changeRouteMid.MultipleChangeRouteProductionMid(rows, true));
      else
        dispatch(changeRouteMid.MultipleChangeRouteProductionMid(rows, false));
    } else if (button === "Rechazar") {
      result = await swalConfirmation("reject");
      if (result) {
        let values = await swalConfirmation("message");
        if (values) {
          let message = values[1];
          dispatch(
            changeRouteMid.ChangeRouteAuxiliarNotPassMid(
              rows,
              message,
              places.Planning
            )
          );
        }
      }
    } else if (button === "Detener orden") {
      if (rows.length > 1) {
        Toast.fire(
          ToastObject(
            "No puedes detener más de una orden de trabajo al mismo tiempo, debes de seleccionar solo una",
            "error"
          )
        );
        dataTableRef.current.UnselectAllTable();
        return;
      }
      const [id, workOrder, quantity] = rows[0];
      const sendObject = {
        zamtestTraceableTag: id,
        QuantityToMove: quantity,
      };
      const result = await swalConfirmation("stop", { workOrder });
      if (result) {
        dispatch(changeRouteMid.ChangeRouteLackInformationMid(sendObject));
      }
    }
  };

  const onSelect = (value) => {
    // filtrar por orden de trabajo
    if (title !== "Maquinado interno" && title !== "Maquinado externo")
      dataTableRef.current.UnselectAllTable();
    if (value) onChangeQuery({ projectId: value.id });
    else onChangeQuery({ projectId: "" });
  };

  return (
    <>
      <Tab
        tabs={[
          { name: "Ordenes de planeación", icon: "puzzle-piece" },
          { name: "Maquinado interno", icon: "toolbox" },
          { name: "Maquinado externo", icon: "toolbox" },
          { name: "Ordenes rechazadas", icon: "times" },
          { name: "Rechazos de metrología", icon: "ruler" },
        ]}
        onClick={onTabSelected}
      >
        <DataTable
          ref={dataTableRef}
          data={tableContent}
          count={count}
          visibleColumn={false}
          onClick={handleSubmit}
          buttons={buttons}
          states={traceableFactory.routeStates}
          columnState={8}
          onSort={onSort}
          onChangeQuery={onChangeQuery}
          pageCount={pageCount}
          hasCheckbox={
            title !== "Maquinado interno" && title !== "Maquinado externo"
          }
          hasBox={false}
          FilterComponent={
            <ProjectFilter title="Filtrar por proyecto" onSelect={onSelect} />
          }
        >
          <h1 className="title is-4 is-flex is-justify-content-center">
            {title}
          </h1>
        </DataTable>
      </Tab>

      <br />
    </>
  );
}

export default WithTabsDataTable(AuxiliarSendPieces, places.Auxiliar, [
  "1",
  "8",
]);
