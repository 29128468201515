import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  buildDataForChart,
  buildDataForLineChartMultiple,
} from "../../Helpers/Factory/chartFactory";
import { danger, info } from "../../Helpers/Factory/recordFactory";

function LineCharts({ data, stack, single }) {
  const [chartData, setChartData] = useState([]);
  const [pieStack, setPieStack] = useState(1);
  const [dataOk, setDataOk] = useState(false);

  useEffect(() => {
    let counter = 0;
    if (single) setChartData(buildDataForChart(data));
    else setChartData(buildDataForLineChartMultiple(data));
    if (stack) setPieStack(stack);
    Object.values(data).map(function (value) {
      if (value !== 0) counter++;
      return true;
    });
    if (counter) setDataOk(true);
    else setDataOk(false);
  }, [data, stack]);
  return (
    <ResponsiveContainer width={"99%"} height={`${99 / pieStack}%`}>
      {single ? (
        dataOk ? (
          <LineChart
            data={chartData}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={false} label="Días" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke={info} />
          </LineChart>
        ) : (
          <h1 className="title is-5 is-flex is-justify-content-center py-5">
            No hay datos disponibles
          </h1>
        )
      ) : (
        <LineChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={false} label="Días" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="first" stroke={info} />
          <Line type="monotone" dataKey="second" stroke={danger} />
        </LineChart>
      )}
    </ResponsiveContainer>
  );
}

export default LineCharts;
