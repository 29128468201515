//ERRORS FOR INPUTS
export const projectNameError = "Nombre invalido, ejemplo: Z02F501";
export const assemblyNameError = "Nombre invalido, ejemplo: S100";
export const emptyField = "No puedes dejar vacío este campo.";
export const numberOnly = "Solo acepta cantidades numéricas";
export const revError = "La revisión debe de ser una letra del alfabeto";
export const quantityError =
  "No puedes enviar más piezas de las que estan dadas de alta";
export const emptyError = "No puedes colocar la cantidad 0";
export const emptyDate = "Debes introducir una fecha válida";
export const metallicFinishError =
  "Si no hay acabado, favor de indicar null o N/A";

//ERROR FOR INVALID FORM
export const invalidForm = "Corrige los campos para continuar.";
export const emptyForm = "Completa los campos correctamente para continuar";

//ERROR FOR API
export const comunicationError =
  "No se logró comunicación con los servidores, porfavor intentalo más tarde";
export const connectionError =
  "No tienes conexión a internet, porfavor intentalo más tarde.";
export const authorizationError =
  "No tienes autorización para realizar esta acción.";

//STATUS
export const OK = 200;
export const BAD_REQUEST = 400;
export const NO_AUTH = 401;
export const BAD_GATEWAY = 500;
export const NO_CONNECTION = 600;
