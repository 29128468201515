import Swal from "sweetalert2";
import { AlertObject, ToastObject } from "./Factory/swalFactory";

export const swalAlert = (modal = { title: "", text: "", icon: "" }) => {
  Swal.fire(modal);
};

export const confirmMessage = async (title, message, icon) => {
  const alertObject = AlertObject(
    {
      title,
      text: message,
    },
    icon
  );
  return (await Swal.fire(alertObject)).isConfirmed;
};

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const ToastError = (message) => {
  Toast.fire(ToastObject(message, "error"));
};
