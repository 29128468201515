import React from "react";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    background: "#4a89be",
    color: "white",
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SelectMultiple({ selectValue, data, onChange }) {
  const classes = useStyles();
  return (
    <div>
      <Select
        className="fullwidth-select"
        style={{height: "60px"}}
        id="demo-mutiple-chip"
        multiple
        value={selectValue}
        onChange={onChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
                // value
              <Chip key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {data.map((name) => (
          <MenuItem key={name.name} value={name.id}>
            {name.id}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default SelectMultiple;
