export const PieceSubmit = (piece, projectId, operationsIds) => {
  piece.projectId = projectId;
  if (piece.metallicFinish.toUpperCase() === "NULL")
    piece.metallicFinish = "N/A";
  piece.code = piece.code.toUpperCase();
  if (!Number(piece.thickness)) piece.thickness = 0;
  else piece.thickness = parseFloat(piece.thickness);
  if (!Number(piece.width)) piece.width = 0;
  else piece.width = parseFloat(piece.width);
  if (!Number(piece.height)) piece.height = 0;
  else piece.height = parseFloat(piece.height);
  return {
    piece,
    operationIds: operationsIds,
  };
};
