import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { buildOperationForSelect } from "../../../Helpers/Factory/operationFactory";
import { PieceObject } from "../../../Helpers/Factory/pieceFactory";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import { PieceSubmit } from "../../../Helpers/Validators/SubmitValidations";
import { CreateData, PostApi } from "../../../redux/middleware/httpMethodApi";
import { GetEditPiece } from "../../../redux/middleware/pieces.mid";
import { UpdatePieceApi } from "../../../Services/PiecesService";
import Operations from "../../1-blocks/b_operations";
import PieceForm from "../../5-forms/pieceForm";
import WithContextData from "../../HOC/WithContextData";

function EditPiece({
  apiData,
  dispatch,
  refresh_route,
  _routes,
  links,
  history,
  helpers,
}) {
  const { pieceId } = useParams();
  const [form, setForm] = useState(PieceObject);
  const projectId = useSelector((state) => state.projectId);
  const [operations, setOperations] = useState([]);

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    if (pieceId) {
      dispatch(GetEditPiece(pieceId));
      dispatch(
        refresh_route(
          _routes[links.editPiece.name],
          `${links.editPiece.to}/${pieceId}`
        )
      );
    }
  }, [pieceId]);

  useEffect(() => {
    if (apiData.data?.piece) {
      setOperations(
        CreateData(apiData.data.operations, buildOperationForSelect)
      );
      let operations = apiData.data.piece.operations.map((item) => item.id);
      setForm({ ...apiData.data.piece, operationsIds: operations });
    }
  }, [apiData.data]);
  /* #endregion */

  /* #region  CONFIRMACIÓN DE CREAR */
  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      helpers.Toast.fire(
        ToastObject("Se ha editado con éxito la pieza", "success")
      );
      history.goBack();
    }
  }, [apiData.data?.postConfirmation]);
  /* #endregion */

  /* #region  evento de checkBox */
  const onChangeCheckbox = (e) => {
    if (!form.operationsIds.includes(parseInt(e.target.name))) {
      setForm({
        ...form,
        operationsIds: [...form.operationsIds, parseInt(e.target.name)],
      });
    } else {
      setForm({
        ...form,
        operationsIds: form.operationsIds.filter(
          (item) => item !== parseInt(e.target.name)
        ),
      });
    }
  };
  /* #endregion */

  /* #region  SUBMIT */
  const onSubmit = async (piece) => {
    const piecePrimitive = PieceSubmit(piece, projectId.id, form.operationsIds);
    dispatch(PostApi([piecePrimitive], UpdatePieceApi));
  };
  /* #endregion */

  if (form?.pieceMaterial) {
    return (
      <PieceForm onSubmit={onSubmit} title="Editar pieza" deafultValues={form}>
        {form.operationsIds.length > 0 && (
          <div className="mb-5">
            <Operations
              operations={operations}
              checkedOperations={form.operationsIds}
              onChange={onChangeCheckbox}
            />
          </div>
        )}
      </PieceForm>
    );
  } else return null;
}

export default WithContextData(EditPiece);
