/* #region  IMPORTS */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { buildOperationForSelect } from "../../../Helpers/Factory/operationFactory";
import { PieceObject } from "../../../Helpers/Factory/pieceFactory";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import { PieceSubmit } from "../../../Helpers/Validators/SubmitValidations";
import {
  CreateData,
  GetApi,
  PostApi,
} from "../../../redux/middleware/httpMethodApi";
import { GetOperationsApi } from "../../../Services/OperationService";
import { CreatePieceApi } from "../../../Services/PiecesService";
import Operations from "../../1-blocks/b_operations";
import PieceForm from "../../5-forms/pieceForm";
import WithContextData from "../../HOC/WithContextData";
/* #endregion */

function CreatePieces({
  apiData,
  dispatch,
  refresh_route,
  _routes,
  links,
  history,
  helpers,
}) {
  /* #region  VARIABLES */
  const [operations, setOperations] = useState([]);
  const { pieceId } = useParams();
  const projectId = useSelector((state) => state.projectId);
  const [form, setForm] = useState(PieceObject);
  /* #endregion */

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    if (!pieceId) {
      dispatch(refresh_route(_routes[links.createPiece.name]));
      dispatch(GetApi([pieceId], GetOperationsApi));
    }
  }, []);

  useEffect(() => {
    if (apiData.data[0]?.title) {
      setOperations(CreateData(apiData.data, buildOperationForSelect));
    }
  }, [apiData.data]);

  /* #endregion */

  /* #region  CONFIRMACIÓN DE CREAR */
  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      helpers.Toast.fire(
        ToastObject("Se ha creado con éxito la pieza", "success")
      );
      history.goBack();
    }
  }, [apiData.data?.postConfirmation]);
  /* #endregion */

  /* #region  evento de checkBox */
  const onChangeCheckbox = (e) => {
    if (!form.operationsIds.includes(parseInt(e.target.name))) {
      setForm({
        ...form,
        operationsIds: [...form.operationsIds, parseInt(e.target.name)],
      });
    } else {
      setForm({
        ...form,
        operationsIds: form.operationsIds.filter(
          (item) => item !== parseInt(e.target.name)
        ),
      });
    }
  };
  /* #endregion */

  /* #region  SUBMIT */
  const onSubmit = async (piece) => {
    const piecePrimitive = PieceSubmit(piece, projectId.id, form.operationsIds);
    dispatch(PostApi([piecePrimitive], CreatePieceApi));
  };
  /* #endregion */

  return (
    <PieceForm
      onSubmit={onSubmit}
      title="Crear pieza"
      defaultValues={PieceObject}
    >
      <div className="mb-5">
        {operations.length > 0 && (
          <Operations
            operations={operations}
            checkedOperations={form.operationsIds}
            onChange={onChangeCheckbox}
          />
        )}
      </div>
    </PieceForm>
  );
}

export default WithContextData(CreatePieces);
