import React from "react";

function Empty(props) {
  return (
    <div
      className="is-flex is-justify-content-center"
      style={{ width: "100%" }}
    >
      <div
        className="box p-5 is-flex is-flex-direction-column is-align-items-center has-background-white"
        style={{ width: "100%" }}
      >
        <h4 className="title is-4">No hay datos que mostrar</h4>
        {props.children}
      </div>
    </div>
  );
}

export default Empty;
