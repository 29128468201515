import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./searchBar.module.scss";
import { onDownKey, onUpKey, removeIsSelectedClass } from "./helpers";
import useMounted from "../../../hooks/useMounted";
import Input from "../../0-atoms/a_input";

const SearchBar = forwardRef(
  (
    {
      name,
      onKeyUp,
      error,
      filterData,
      onSelect,
      placeholder = "Buscar",
      errorMessage = "No hay nada que mostrar",
      maxView = 3,
      className = "",
      minHeight = 100,
      onInputEnter = () => {},
      defaultSelection,
    },
    ref
  ) => {
    const [classNameIntern, setClassNameIntern] = useState("");
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(minHeight);
    const search = useRef(null);
    const div = useRef(null);
    const tr = useRef(null);
    const table = useRef(null);
    const [hidden, setHidden] = useState(true);
    const [rowCounter, setRowCounter] = useState(-1);
    const [key, setKey] = useState("");
    const mounted = useMounted();
    const [focused, setFocused] = useState(false);

    useEffect(() => {
      const data = filterData.find((f) => f.id === defaultSelection);
      if (defaultSelection !== search.current.value && data) {
        setTimeout(() => {
          search.current.value = data.name;
          search.current.classList.add("is-success");
        }, 100);
      }
    }, [defaultSelection]);

    /* #region  CONSTRUCTOR */
    useEffect(() => {
      window.addEventListener("keydown", KeyEvent);
      return () => {
        window.removeEventListener("keydown", KeyEvent);
      };
    });

    useEffect(() => {
      if (mounted && search.current) setWidth(search.current.offsetWidth);
    }, [mounted, hidden]);

    useEffect(() => {
      if (!hidden && tr.current) {
        const trHeight = tr.current.offsetHeight;
        const calHeight = maxView * trHeight + 25;
        if (calHeight > minHeight) setHeight(maxView * trHeight + 25);
        else setHeight(minHeight);
      }
    }, [hidden]);
    /* #endregion */

    useEffect(() => {
      if (error) {
        setClassNameIntern("is-danger");
      } else if (error === null) {
        setClassNameIntern("");
      }
    }, [error]);

    const onSelectItem = (item) => {
      if (item) {
        if (search.current) {
          search.current.value = item.name;
          search.current.classList.remove("is-danger");
          search.current.classList.add("is-success");
        }
        onSelect(item);
      }
      setHidden(true);
    };

    const onErase = () => {
      onSelect(null);
      search.current?.classList.remove("is-success");
    };

    /* #region  EVENTOS DE TECLADO */
    const KeyEvent = (e) => {
      let item;
      let itemCount = 0;
      if (table.current && table.current.rows.length > 0) {
        item = table.current.rows;
        itemCount = item.length;
      }
      if (e.key === "Escape") {
        setKey("esc");
        EscKey(search.current);
      } else if (e.key === "ArrowUp" && itemCount >= 0) {
        if (!focused) return;
        e.preventDefault();
        setKey("up");
        setRowCounter((count) => {
          if (count <= -1) {
            table.current.rows[0].className = "";
            return -1;
          }
          return onUpKey(table, count, key, styles.is_selected);
        });
      } else if (e.key === "ArrowDown" && itemCount >= 0) {
        if (!focused) return;
        e.preventDefault();
        setRowCounter((count) => {
          if (count >= itemCount) {
            return itemCount;
          }
          return onDownKey(table, count, key, styles.is_selected);
        });
        setKey("down");
      } else if (e.key === "Enter" && itemCount >= 0) {
        if (!focused) return;
        setKey("enter");
        search.current?.blur();
        EnterKey();
      } else setRowCounter(-1);
    };

    const EscKey = (input) => {
      input.value = "";
      if (input === document.activeElement) {
        input.blur();
        onSelect(null);
        setHidden(true);
      } else {
        input.focus();
      }

      if (table.current?.rows[rowCounter]) {
        table.current.rows[rowCounter].className = "";
      }
      setRowCounter(-1);
    };

    const EnterKey = () => {
      if (focused) {
        let counter = 0;
        if (key === "down") counter = rowCounter - 1;
        else if (key === "up") counter = rowCounter + 1;
        if (filterData[counter]) onSelectItem(filterData[counter]);
        else onInputEnter(search.current?.value ?? "");
        setRowCounter(-1);
      }
    };
    /* #endregion */

    const onFocus = () => {
      setFocused(true);
      setRowCounter(-1);
      removeIsSelectedClass(table, styles.is_selected);
      div.current?.scrollTo({ top: 0, behavior: "smooth" });
      setHidden(false);
    };

    const onBlur = () => {
      setFocused(false);
      setTimeout(() => setHidden(true), 150);
    };

    const onClear = () => {
      onErase();
      if (search.current) search.current.value = "";
    };

    useImperativeHandle(ref, () => {
      return {
        onClear,
      };
    });

    return (
      <>
        <Input
          ref={search}
          name={name}
          error=""
          onErase={onErase}
          icon="search"
          placeholder={placeholder}
          onKeyUp={onKeyUp}
          className={`${classNameIntern} ${className}`}
          style={{ marginBottom: "0px !important" }}
          onFocus={onFocus}
          autoComplete={false}
          onBlur={onBlur}
        />
        <div className={styles.searchBar_container}>
          {!hidden && (
            <div
              ref={div}
              tabIndex={0}
              className={`box ${styles.searchBar_box_container}`}
              style={{
                width,
                height,
              }}
            >
              {filterData.length > 0 ? (
                <table
                  className={"table is-fullwidth is-hoverable"}
                  ref={table}
                >
                  <tbody>
                    {filterData.map((data, index) => (
                      <tr key={index} ref={tr}>
                        <td
                          className={styles.searchBar_item}
                          key={index}
                          onClick={() => onSelectItem(data)}
                        >
                          {data.name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <span className={styles.searchBar_error_container}>
                  {errorMessage}
                </span>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
);

SearchBar.displayName = "SearchBar";

export default SearchBar;
