import { emptyField } from "../../resources/constants/messages";
import { inputIconFactory } from "../Factory/inputIconFactory";

//SELECT
//  Proyectos
export const OperationForm = {
  title: inputIconFactory("title", "Título", emptyField, "barcode"),
  description: inputIconFactory(
    "description",
    "Descripción de la operación",
    emptyField,
    "prescription",
    true
  ),
};

export const OperationButton = {
  name: "Enviar",
  icon: "puzzle-piece",
};
