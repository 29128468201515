import * as notification from "../../Helpers/notification";
import { apiFail, apiRequest, apiSuccess } from "../ducks/apiData";

export const NotificationSubscribeMid = (userId) => async (dispatch) => {
  dispatch(apiRequest());
  let available = notification.isPushNotificationSupported();
  if (available) {
    var result = await notification.askUserPermission();
    if (result === "granted") {
      await notification.createNotificationSubscription(userId);
      localStorage.setItem("notification-permissions", "granted");
    }
  } else {
    dispatch(apiFail("El navegador no permite notificaciones"));
  }

  dispatch(apiSuccess({ response: "Evento notificación" }));
};

export const NotificationUnsubscribeMid = (userId) => async (dispatch) => {
  dispatch(apiRequest());
  await notification.removeNotificationSubscription(userId);
  localStorage.setItem("notification-permissions", "denied");
  dispatch(apiSuccess());
};
