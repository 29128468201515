import { combineReducers } from "redux";
import routeReducer from "./ducks/route";
import apiDataReducer from "./ducks/apiData";
import projectIdReducer from "./ducks/projectId";
import dataTableSelectionReducer from "./ducks/dataTableSelection";

const rootReducer = combineReducers({
  route: routeReducer,
  apiData: apiDataReducer,
  projectId: projectIdReducer,
  dataTableSelection: dataTableSelectionReducer,
});

export default rootReducer;
