export const links = {
  home: { name: "Inicio", to: "/" },

  projects: { name: "Proyectos", to: "/projects" },
  project: { name: "Proyecto", to: "/project" },
  createProject: { name: "Crear Proyecto", to: "/createProject" },
  editProject: { name: "Editar Proyecto", to: "/editProject" },
  adminDesigners: { name: "Gestionar diseñadores", to: "/adminDesigners" },
  stats: { name: "Estadísticas", to: "/stats" },

  createWorkOrder: {
    name: "Crear órdenes de trabajo",
    to: "/createWorkOrders",
  },
  workOrders: { name: "Órdenes de trabajo", to: "/workOrders" },
  workOrder: { name: "Orden de trabajo", to: "/workOrder" },

  createPiece: { name: "Crear piezas", to: "/createPieces" },
  editPiece: { name: "Editar piezas", to: "/editPieces" },
  pieces: { name: "Piezas", to: "/pieces" },
  piece: { name: "Ver pieza", to: "/piece" },

  createOperation: { name: "Crear operaciones", to: "/createOperation" },
  editOperation: { name: "Editar Operaciones", to: "/editOperation" },
  operations: { name: "Operaciones", to: "/operations" },

  editUser: { name: "Editar rol de usuario", to: "/updateUserRole" },
  users: { name: "Usuarios", to: "/users" },

  sendPieces: { name: "Piezas a enviar", to: "/sendTraceables" },
  planningSendPieces: {
    name: "Planeación",
    to: "/planningSendPieces",
  },
  auxiliarSendPieces: {
    name: "Ordenes de trabajo en MaqZam",
    to: "/auxiliarSendPieces",
  },
  productionSendPieces: {
    name: "Producción",
    to: "/productionSendPieces",
  },

  receivePieces: { name: "Piezas a recibir", to: "/receiveTraceables" },
  receivePartPieces: {
    name: "Piezas a recibir",
    to: "/receivePartsTraceables",
  },
  getPieces: { name: "Almacén", to: "/warehouse" },

  login: { name: "Inicio de sesión", to: "/login" },
  forgotPassword: { name: "Olvidar la contraseña", to: "/forgotPassword" },
  resetPassword: { name: "Restablecer la contraseña", to: "/resetPassword" },
  createUser: { name: "Crear usuario", to: "/createUser" },
  changePassword: { name: "Cambiar contraseña", to: "/changePassword" },
};
