import * as urlConstants from "./helpers/urlConstants";
import { fetchMethod } from "./helpers/fetchMethod";
import { POST } from "./helpers/serviceConstants";
import setDataTableQuery from "./helpers/setDataTableQuery";

export const CreateProjectApi = async (data) => {
  return await fetchMethod(urlConstants.CreateProject, POST, data);
};

export const UpdateProjectApi = async (data) => {
  return await fetchMethod(urlConstants.UpdateProject, POST, data);
};

export const GetProjectApi = async (id) => {
  return await fetchMethod(urlConstants.GetProject + id);
};

export const GetProjectsApi = async () => {
  return await fetchMethod(urlConstants.GetProjects);
};

export const UpdateDesignersApi = async (data) => {
  return await fetchMethod(urlConstants.UpdateDesigners, POST, data);
};

export const GetProjectsWithSpecApi = async (query) => {
  let queryString = setDataTableQuery(query);
  return await fetchMethod(urlConstants.GetProjectsWithSpec + queryString);
};
