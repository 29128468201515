import React, { useReducer, useRef, useState } from "react";
import { isPassword } from "../../Helpers/Validators";
import PasswordValidator from "../0-atoms/a_passwordValidator";

const hasUpperCase = new RegExp("^(?=.*[A-Z])");

const hasOneNumber = new RegExp("^(?=.*[0-9])");

const hasSpecialCharacter = new RegExp("^(?=.*[.!@#;,=+?¡¿_$%^&*-])");

const initialData = {
  upperCase: false,
  oneNumber: false,
  specialCharacter: false,
  size: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CHECK_PASSWORD":
      const value = action.payload;
      if (!value) return initialData;
      return {
        upperCase: hasUpperCase.test(value),
        oneNumber: hasOneNumber.test(value),
        specialCharacter: hasSpecialCharacter.test(value),
        size: value.length >= 7,
      };
    default:
      return state;
  }
};

export default function PasswordInput({ name, onChange, onEnter }) {
  const input = useRef();
  const [inputOk, setInputOk] = useState(null);
  const [confirmPasswordOk, setConfirmPasswordOk] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialData);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onChangeChildren = ({ target }) => {
    setInputOk(isPassword(target.value));
    dispatch({ type: "CHECK_PASSWORD", payload: target.value });
    onChange(target.value, target.name);
    setPassword(target.value);
    setConfirmPasswordOk(target.value === confirmPassword);
  };

  const onConfirmPassword = ({ target }) => {
    const value = target.value;
    setConfirmPassword(value);
    onChange(value, target.name);
    setConfirmPasswordOk(value === password);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") onEnter();
  };

  return (
    <>
      <div className={`field`}>
        <label className="label">Contraseña</label>
        <div className="control has-icons-left has-icons-right">
          <input
            ref={input}
            className={`input ${
              inputOk !== null && `is-${inputOk ? "success" : "danger"}`
            } `}
            type={"password"}
            placeholder={"Contraseña"}
            name={name}
            onChange={onChangeChildren}
          />
          <span className="icon is-small is-left">
            <i className={`fas fa-lock`}></i>
          </span>
          <span className="icon is-small is-right">
            <i
              className={`fas fa-${
                inputOk === null ? "lock" : input ? "check" : "times"
              }`}
            ></i>
          </span>
        </div>
      </div>
      <PasswordValidator
        text={"Contiene una mayúscula"}
        isValid={state.upperCase}
      />
      <PasswordValidator
        text={"Contiene un numero"}
        isValid={state.oneNumber}
      />
      <PasswordValidator
        text={"Contiene un carácter alfanumérico"}
        isValid={state.specialCharacter}
      />
      <PasswordValidator
        text={"Contiene 7 letras o más"}
        isValid={state.size}
      />
      <div className={`field mt-3`}>
        <label className="label">Confirmar contraseña</label>
        <div className="control has-icons-left has-icons-right">
          <input
            className={`input ${
              confirmPasswordOk !== null &&
              `is-${confirmPasswordOk ? "success" : "danger"}`
            }`}
            type={"password"}
            placeholder={"Contraseña"}
            name={"confirmPassword"}
            onChange={onConfirmPassword}
            onKeyDown={onKeyDown}
          />
          <span className="icon is-small is-left">
            <i className={`fas fa-lock`}></i>
          </span>
          <span className="icon is-small is-right">
            <i
              className={`fas ${
                confirmPasswordOk !== null
                  ? `fa-${confirmPasswordOk ? "check" : "times"}`
                  : "fa-lock"
              } `}
            ></i>
          </span>
        </div>
      </div>
    </>
  );
}
