const setDataTableQuery = (query, traceable = false) => {
  let queryString = "";
  if (query?.pageSize) queryString = "?pageSize=" + query.pageSize;

  if (query?.pageIndex) queryString += "&pageIndex=" + query.pageIndex;

  if (query?.search) {
    if (traceable) queryString += "&workorderid=" + query.search;
    else queryString += "&search=" + query.search;
  }
  if (query?.sort) queryString += "&sort=" + query.sort;

  if (query?.routeId) {
    if (!Array.isArray(query.routeId))
      queryString += "&RouteId=" + query.routeId;
    else {
      const routeId = query.routeId.join("&RouteId=");
      queryString += "&RouteId=" + routeId;
    }
  }

  if (query?.projectId) queryString += "&ProjectId=" + query.projectId;

  if (query?.isRejected === true) queryString += "&IsRejected=true";
  else if (query?.isRejected === false) queryString += "&IsRejected=false";

  return queryString;
};

export default setDataTableQuery;
