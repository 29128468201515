import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import { InitAdminDesignersMid } from "../../../redux/middleware/adminDeisgners.mid";
import { PostApi } from "../../../redux/middleware/httpMethodApi";
import { UpdateDesignersApi } from "../../../Services/ProjectService";
import MultiSelect from "../../2-systems/s_MultiSelect";
import WithContextData from "../../HOC/WithContextData";

function AdminDesigners({
  apiData,
  history,
  dispatch,
  refresh_route,
  _routes,
  links,
  helpers,
}) {
  const [leftData, setLeftData] = useState([]);
  const [rightData, setRightData] = useState([]);

  const projectId = useSelector((state) => state.projectId);
  useEffect(() => {
    // falta ruta
    dispatch(refresh_route(_routes[links.adminDesigners.name]));
    dispatch(InitAdminDesignersMid(projectId.id));
  }, [projectId]);

  useEffect(() => {
    if (!apiData.loading && apiData.ok && apiData.data?.users) {
      setRightData(apiData.data.designers);
      setLeftData(
        apiData.data.users.filter(function (value) {
          for (var i = 0; i < apiData.data.designers.length; i++) {
            if (apiData.data.designers[i].id === value.id) return false;
          }
          return true;
        })
      );
    }
  }, [apiData.data]);

  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      helpers.Toast.fire(
        ToastObject("Se han guardado los cambios con éxito", "success")
      );
      history.goBack();
    }
  }, [apiData.data]);

  const onSubmit = (designers) => {
    let designersId = designers.map((item) => item.id);
    let obj = { projectId: projectId.id, designersId };
    if (designersId.length <= 0) {
      helpers.Toast.fire(
        ToastObject("Es necesario añadir al menos un diseñador", "warning")
      );
    } else dispatch(PostApi([obj], UpdateDesignersApi));
  };

  if (projectId) {
    return (
      <div className="box whiteBox">
        <div className="is-flex is-flex-direction-column is-align-items-center mb-4">
          <h4 className="title is-4 mb-0">Piezas {projectId.id}</h4>
          <label className="label has-text-grey-light">{projectId.name}</label>
        </div>
        <MultiSelect
          leftData={leftData}
          rightData={rightData}
          onSubmit={onSubmit}
        ></MultiSelect>
      </div>
    );
  } else {
    return (
      <div className="box p-5 has-background-white">
        <div className="is-flex is-flex-direction-column is-align-items-center mb-5">
          <h4 className="title is-4 mb-0">
            Sistema de trazabilidad de Zamtest
          </h4>
          <label className="label has-text-grey-light">
            Para acceder a esta página es necesario seleccionar un proyecto
          </label>
        </div>

        <div className="ml-5">
          <label className="label">Selecciona un proyecto</label>
          <p>
            Para seleccionar un proyecto, puedes seleccionarlo directamente en
            el menú de tu izquierda
          </p>
        </div>
      </div>
    );
  }
}

export default WithContextData(AdminDesigners);
