import React, { createRef, useEffect, useRef } from "react";
import { useState } from "react";
import { ToastError } from "../../Helpers/sweetAlertHandler";
import {
  cleanRefInputs,
  validateInputWithRef,
  validateRefInputs,
  validForm,
} from "../../Helpers/validationsForRefInputs";
import { ProjectValidator } from "../../Helpers/Validators/FormsValidation";
import Input from "../0-atoms/a_input";
import ButtonIcon from "../1-blocks/b_ButtonIcon";
import PropTypes from "prop-types";
import useMounted from "../../hooks/useMounted";
import Select from "../0-atoms/a_select";
import { buildNormalDate } from "../../Helpers";
import FormInput from "../0-atoms/a_formInput";
import moment from "moment";
import { isEmpty } from "../../Helpers/Validators";

export default function ProjectForm({
  defaultValues = {},
  readonly = false,
  onSubmit = () => {},
  title,
  managers = [],
  children,
}) {
  const refs = ["projectCode", "title", "description", "clientCode"];
  const inputs = useRef(refs.map(() => createRef()));
  const [form, setForm] = useState(defaultValues);
  const mounted = useMounted();

  const [select, setSelect] = useState(
    managers.find(
      (manager) => defaultValues?.manager?.userName === manager.name
    )?.id
  );

  useEffect(() => {
    if (mounted)
      validateRefInputs(ProjectValidator, inputs.current, "clientCode");
  }, [mounted, defaultValues]);

  /* #region  eventos */
  const onChange = (value) => {
    if (value === "notValue") {
      setSelect(value);
      value = -1;
    }
    value = parseInt(value);
    setSelect(value);
    const manager = managers.find((val, index) => index === value);
    if (manager) setForm({ ...form, managerId: manager.content });
  };

  const handleDateChange = (value, name) => {
    const date = buildNormalDate(new Date(value + "T00:00:00"));
    if (name === "startDate") {
      setForm({ ...form, startDate: date });
    } else {
      setForm({ ...form, plannedFinishDate: date });
    }
  };
  /* #endregion */

  const onKeyUp = ({ name, value }) => {
    setForm({ ...form, [name]: value.trim() });
    validateInputWithRef(ProjectValidator, inputs.current, name, "clientCode");
  };

  const onSend = () => {
    const result = validForm(
      validateRefInputs(ProjectValidator, inputs.current, "clientCode")
    );
    if (!form?.plannedFinishDate)
      form.plannedFinishDate = buildNormalDate(new Date());
    if (!form?.startDate) form.startDate = buildNormalDate(new Date());
    if (result && form.managerId) {
      onSubmit({
        ...form,
      });
      cleanRefInputs(inputs.current);
    } else ToastError("Completa los datos del fomulario correctamente");
  };

  if (mounted) {
    return (
      <div className="card form-container">
        <div className="card-content">
          <h1 className="title is-4 is-flex is-justify-content-center">
            {title}
          </h1>
          {children}
          <label className="label is-small">Datos generales</label>
          <div className="border-box p-3 mb-3">
            <div className="columns mb-0">
              <div className="column">
                <label className="label is-small">Código</label>
                <Input
                  className="is-small"
                  icon="barcode"
                  defaultValue={defaultValues?.projectCode}
                  name="projectCode"
                  onKeyUp={onKeyUp}
                  ref={inputs.current[0]}
                  placeholder="Código de proyecto"
                  error="Nombre invalido, ejemplo: Z##X###"
                />
              </div>
              <div className="column">
                <label className="label is-small">Título</label>
                <Input
                  className="is-small"
                  icon="at"
                  disabled={readonly}
                  defaultValue={defaultValues?.title}
                  name="title"
                  onKeyUp={onKeyUp}
                  ref={inputs.current[1]}
                  placeholder="Título"
                  error="No puedes dejar vacío este campo"
                />
              </div>
            </div>
            <label className="label is-small">Descripción</label>
            <Input
              className="is-small"
              icon="tasks"
              disabled={readonly}
              defaultValue={defaultValues?.description}
              name="description"
              onKeyUp={onKeyUp}
              ref={inputs.current[2]}
              placeholder="Descripción"
              textarea
              error="No puedes dejar vacío este campo"
            />
            <label className="label is-small">
              Código de cliente (opcional)
            </label>
            <Input
              className="is-small"
              icon="barcode"
              defaultValue={defaultValues?.clientCode}
              name="clientCode"
              onKeyUp={onKeyUp}
              ref={inputs.current[3]}
              placeholder="Código"
              error=""
            />
          </div>

          <div className="border-box p-3">
            <label className="label is-small">
              Asignar a gestor de proyecto
            </label>
            <Select
              className="is-fullwidth is-small mb-3"
              title="Selecciona un gestor de proyectos"
              name="managerId"
              icon="users"
              data={managers}
              onChange={onChange}
              select={select}
            />
            <div className="is-flex is-justify-content-space-between mt-2">
              <div>
                <label className="label is-small">Fecha de inicio</label>
                <FormInput
                  title=""
                  placeholder={"01/01/2024"}
                  type="date"
                  onChange={handleDateChange}
                  icon={"calendar-day"}
                  name="startDate"
                  onCheckError={isEmpty}
                  defaultValue={defaultValues?.startDate}
                />
              </div>
              <div>
                <label className="label is-small">
                  Fecha planeada para término
                </label>
                <FormInput
                  title=""
                  placeholder={"01/01/2024"}
                  type="date"
                  onChange={handleDateChange}
                  icon={"calendar-day"}
                  name="plannedFinishDate"
                  onCheckError={isEmpty}
                  min={moment(form.startDate).format("YYYY-MM-DD")}
                  defaultValue={defaultValues?.plannedFinishDate}
                />
              </div>
            </div>
          </div>

          {!readonly && (
            <ButtonIcon
              className="is-primary is-fullwidth"
              icon="paper-plane"
              name="Enviar formulario"
              onClick={onSend}
            />
          )}
        </div>
      </div>
    );
  } else return null;
}

ProjectForm.propTypes = {
  deafultValues: PropTypes.object,
  readonly: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  managers: PropTypes.array.isRequired,
};
