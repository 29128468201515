import { emptyField } from "../../resources/constants/messages";
import { inputIconFactory } from "../Factory/inputIconFactory";

export const ReceiveSendDataForm = {
  code: inputIconFactory(
    "code",
    "Código",
    emptyField,
    "barcode",
    false,
    false,
    false,
    true,
    true
  ),
  rev: inputIconFactory(
    "rev",
    "Revisión",
    emptyField,
    "file-signature",
    false,
    false,
    false,
    true,
    true
  ),
  projectId: inputIconFactory(
    "projectId",
    "Nombre de proyecto",
    emptyField,
    "file-signature",
    false,
    false,
    false,
    true,
    true
  ),
  quantity: inputIconFactory(
    "quantity",
    "Cantidad",
    emptyField,
    "file-signature",
    false,
    true,
    false,
    true,
    true
  ),
};
