import * as urlConstants from "./helpers/urlConstants";
import { fetchMethod } from "./helpers/fetchMethod";
import { POST } from "./helpers/serviceConstants";

export const GetPiecesQuantityApi = async (projectCode) => {
  return await fetchMethod(urlConstants.GetPiecesQuantity + projectCode);
};

export const GetPiecesQuantityMetrologApi = async (projectCode) => {
  return await fetchMethod(
    urlConstants.GetPiecesQuantityMetrology + projectCode
  );
};

export const GetProducedPiecesByDayApi = async (projectCode, data) => {
  return await fetchMethod(
    urlConstants.GetProducedPiecesByDay + projectCode,
    POST,
    data
  );
};
