import {
  GetPublicKeyApi,
  SubscribeApi,
  UnsubscribeApi,
} from "../Services/NotificationService";

export const isPushNotificationSupported = () => {
  return "serviceWorker" in navigator && "PushManager" in window;
};

export async function askUserPermission() {
  return await Notification.requestPermission(function (choice) {
    if (choice === "granted") {
      localStorage.setItem("notification-permissions", "granted");
    } else {
      localStorage.setItem("notification-permissions", "denied");
    }
  });
}

export function getNotificationPermissions() {
  if (Notification.permission === 'granted') {
      return localStorage.getItem('notification-permissions');
  } else {
      return Notification.permission;
  }
}

export async function createNotificationSubscription(userId) {
  const worker = await navigator.serviceWorker.getRegistration();
  const subscriptionExist = await worker.pushManager.getSubscription();

  if (!subscriptionExist) {
    const pushServerPublicKey = await GetPublicKeyApi();
    //wait for service worker installation to be ready
    const serviceWorker = await navigator.serviceWorker.ready;
    //   subscribe and return the subscription
    let subscription = await serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: pushServerPublicKey,
    });
    await SubscribeApi(buildSubscription(subscription, userId));
  }
}

export async function removeNotificationSubscription(userId) {
  const worker = await navigator.serviceWorker.getRegistration();
  const subscription = await worker.pushManager.getSubscription();

  if (subscription) {
    subscription.unsubscribe();
    await UnsubscribeApi(buildSubscription(subscription, userId));
  }
}

function buildSubscription(suscripcion, userId) {
  return {
    url: suscripcion.endpoint,
    p256dh: arrayBufferToBase64(suscripcion.getKey("p256dh")),
    auth: arrayBufferToBase64(suscripcion.getKey("auth")),
    userId: userId,
  };
}

function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
