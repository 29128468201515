import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types"; // ES6

function Select({
  onChange,
  select,
  data,
  className = "",
  title,
  icon = "",
  id = "",
  ...props
}) {
  const selected = useRef();
  const [color, setColor] = useState("");

  useEffect(() => {
    if (selected.current && select > -1) {
      selected.current.value = select;
      setColor("is-success");
    }
  }, [data, select]);

  useEffect(() => {
    if (selected.current?.value) {
      if (selected.current.value.toString() === "notValue") {
        setColor("is-danger");
      } else {
        setColor("is-success");
      }
    } else setColor("is-info");
  }, [selected.current?.value]);

  const onInnerChange = (e) => {
    const value = e.target.value;
    if (value.toString() === "notValue") setColor("is-danger");
    else setColor("is-success");
    onChange(value, id);
  };

  if (!data || data.length <= 0) {
    return <h1>No hay datos por seleccionar</h1>;
  } else {
    return (
      <div
        className={`control ${icon === "" ? "" : "has-icons-left"} is-right`}
        style={{ marginBottom: "5px" }}
        {...props}
      >
        <div
          className={`select ${className} is-normal ${color}`}
          onChange={onInnerChange}
        >
          <select ref={selected} select={select}>
            <option value={"notValue"}>{title}</option>
            {data.map((element, index) =>
              select === element.id ? (
                <option key={index} value={element.id} defaultValue>
                  {element.name}
                </option>
              ) : (
                <option key={index} value={element.id}>
                  {element.name}
                </option>
              )
            )}
          </select>
        </div>
        {icon === "" ? null : (
          <div className="icon is-big is-left">
            <i className={`fas fa-${icon}`}></i>
          </div>
        )}
      </div>
    );
  }
}

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  id: PropTypes.string,
  select: PropTypes.number,
};

export default Select;
