import styled from "@emotion/styled";

export const TabsContainer = styled("div")`
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
`;

export const Header = styled("h3")`
  width: auto;
  min-width: 100px;
  padding: 0px 10px 1px 10px;
  cursor: pointer;
  position: relative;
  text-align: center;
  color: #000;
  font-weight: bold;

  &:after {
    content: "";
    width: ${(props) => `${props.active ? 100 : 0}%`};
    height: 1.5px;
    background: ${(props) => `${props.activeColor}`};
    position: absolute;
    bottom: -2px;
    left: ${(props) => `${props.active ? 0 : 50}%`};
    transition: 300ms all;
  }

  ${({ active, activeColor }) => (active ? { color: activeColor } : null)};
`;

export const Headers = styled("div")`
  display: inline-flex;
  font-size: 16px;
  width: 100%;
  min-width: fit-content;
  margin: 10px 0px;
`;

export const TabsItems = styled("div")`
  margin: 0px;
  min-width: fit-content;
`;

export const Item = styled("div")`
  transition: 300ms all;
  ${({ active }) => (active ? { display: "block" } : { display: "none" })};
`;
