import * as urlConstants from "./helpers/urlConstants";
import { fetchMethod } from "./helpers/fetchMethod";
import { POST } from "./helpers/serviceConstants";

//{tag: "string", isRejected: true}
export const ChangeRejectedStatusApi = async (data) => {
  return await fetchMethod(urlConstants.ChangeRejectedStatus, POST, data);
};

//{
//  "traceableId": "string",
//  "title": "string",
//  "description": "string",
//  "status": 0,
//  "amount": 0,
//  "productionTime": {}
//}
export const CreateCustomRecordApi = async (data) => {
  return await fetchMethod(urlConstants.CreateCustomRecord, POST, data);
};
