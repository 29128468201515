import React from "react";

export default function SelectProjectMessage() {
  return (
    <div className="box p-5 has-background-white">
      <div className="is-flex is-flex-direction-column is-align-items-center mb-5">
        <h4 className="title is-4 mb-0">Sistema de trazabilidad de Zamtest</h4>
        <label className="label has-text-grey-light">
          Para acceder a esta página es necesario seleccionar un proyecto
        </label>
      </div>

      <div className="mx-5">
        <label className="label">Selecciona un proyecto</label>
        <p>
          Para seleccionar un proyecto, puedes seleccionarlo directamente en el
          menú de tu izquierda
        </p>
      </div>
    </div>
  );
}
