/* #region  IMPORTS */
import * as traceableFactory from "../../Helpers/Factory/traceableFactory";
import { OK } from "../../resources/constants/messages";
import {
  GetTraceablesOnPlaceWithSpecApi,
  GetTraceablesToReceiveByWorkOrderApi,
  GetTraceablesToReceiveWithSpecApi,
} from "../../Services/MoveTraceableService";
import { GetProjectApi } from "../../Services/ProjectService";
import { apiFail, apiRequest, apiSuccess } from "../ducks/apiData";
import { CreateData } from "./httpMethodApi";
import * as places from "../../resources/constants/places";
/* #endregion */

export const GetTraceableToSendMid = (query, place) => async (dispatch) => {
  dispatch(apiRequest());
  var result = await GetTraceablesOnPlaceWithSpecApi(query, place);
  if (result.status === OK) {
    let traceable = {};
    switch (place) {
      case places.Finished:
      case places.Planning:
        traceable = CreateData(
          result.data.data,
          traceableFactory.buildTraceableForTablePlanning
        );
        break;
      case places.Production:
      case places.ExternalMachining:
      case places.Auxiliar:
      case places.Design:
      case places.Quality:
        traceable = CreateData(
          result.data.data,
          traceableFactory.buildTraceableForTableQuality
        );
        break;
      default:
        traceable = CreateData(
          result.data.data,
          traceableFactory.buildTraceableForMetrology
        );
        break;
    }

    result.data.data = traceable;
    dispatch(apiSuccess(result.data));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const GetTraceablesToReceiveMid = (query, place) => async (dispatch) => {
  dispatch(apiRequest());
  var result = await GetTraceablesToReceiveWithSpecApi(query, place);
  if (result.status === OK) {
    let traceables = CreateData(
      result.data.data,
      traceableFactory.buildTracebleForTable
    );
    result.data.data = traceables;
    dispatch(apiSuccess({ traceables: result.data }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const GetTraceablesForDesign = (query, kind) => async (dispatch) => {
  dispatch(apiRequest());

  if (`${places.Design}-1` === kind) {
    let result = await GetTraceablesToReceiveWithSpecApi(
      query,
      places.Design,
      [1, 8]
    );
    if (result.status === OK) {
      let traceables = CreateData(
        result.data.data,
        traceableFactory.buildTracebleForTableForDesign
      );
      result.data.data = traceables;
      dispatch(apiSuccess({ traceables: result.data }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  } else if (`${places.Design}-2` === kind) {
    let result = await GetTraceablesToReceiveWithSpecApi(
      query,
      places.Design,
      [7, 9]
    );
    if (result.status === OK) {
      let traceables = CreateData(
        result.data.data,
        traceableFactory.buildTracebleForTableForDesign
      );
      result.data.data = traceables;
      dispatch(apiSuccess({ traceables: result.data }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  }
};

export const GetTraceableByWorkOrderIdMid =
  (place, workOrderId) => async (dispatch) => {
    dispatch(apiRequest());
    var result = await GetTraceablesToReceiveByWorkOrderApi(place, workOrderId);
    if (result.status === OK) {
      if (result.data.length === 0) dispatch(apiSuccess(false));
      else {
        let value = result.data[0];
        result = await GetProjectApi(value.workOrder.piece.projectId);
        if (result.status === OK) {
          dispatch(
            apiSuccess(
              traceableFactory.buildReceiveSendObject(value, result.data.title)
            )
          );
        } else
          dispatch(apiFail({ error: result.error, status: result.status }));
      }
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  };
