import authService from "../components/api-authorization/AuthorizeService";
import * as urlConstants from "./helpers/urlConstants";
import { fetchMethod } from "./helpers/fetchMethod";
import { FORM, GET } from "./helpers/serviceConstants";

const RequesOptions = (token) => {
  return {
    method: GET,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const GetFileApi = async (workOrderCode) => {
  const token = await authService.getAccessToken();
  let request = RequesOptions(token);
  const urlHost = urlConstants.GetUrl();
  const response = await fetch(
    urlHost + urlConstants.GetFile + workOrderCode,
    request
  );
  try {
    if (!response.ok) throw response;
    const data = await response.blob();
    var url = window.URL.createObjectURL(data);
    await window.open(url, "_blank");
    return true;
  } catch (err) {
    return false;
  }
};

export const DownloadFileApi = async (workOrderCode) => {
  const token = await authService.getAccessToken();
  let request = RequesOptions(token);
  const urlHost = urlConstants.GetUrl();
  const response = await fetch(
    urlHost + urlConstants.GetFile + workOrderCode,
    request
  );
  try {
    if (!response.ok) throw response;
    const data = await response.blob();
    var url = window.URL.createObjectURL(data);
    const responseUrl = decodeURI(response.url);
    let array = responseUrl.split("/");
    let fileName = array[array.length - 1] + ".pdf";
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.removeChild();
    return true;
  } catch (err) {
    return false;
  }
};

export const UpdateWorkOrderFileApi = async (data) => {
  return await fetchMethod(urlConstants.UpdateWorkOrderFile, FORM, data);
};
