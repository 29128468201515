import React from "react";
import { emptyField } from "../../resources/constants/messages";
import InputIcon from "./b_InputIcon";

function FormDashboard({ form }) {
  return (
    <div className="columns">
      <div className="column is-two-thirds">
        <InputIcon
          key="0"
          name="project"
          type={emptyField}
          placeholder={"Proyecto"}
          isValid={true}
          icon={"spell-check"}
          value={form.title}
          decimals={true}
          disabled={true}
          isTextArea={false}
        />
        <InputIcon
          key="1"
          name="manager"
          type={emptyField}
          placeholder={"Gestor de proyectos encargado"}
          isValid={true}
          icon={"user"}
          value={form.manager}
          decimals={true}
          disabled={true}
          isTextArea={false}
        />
        <InputIcon 
          key="2"
          name="designers"
          type="emptyField"
          placeholder={"Diseñadores"}
          isValid={true}
          value={form.designers ? (form.designers.map(item => (item.userName + '\n')).join('')) : ("")}
          decimals={true}
          disabled={true}
          isTextArea={true}
        />
      </div>
      <div className="column">
        <InputIcon
          key="3"
          name="createDate"
          type={emptyField}
          placeholder={"Fecha de creación"}
          isValid={true}
          icon={"qrcode"}
          value={form.createDate}
          decimals={true}
          disabled={true}
          isTextArea={false}
        />

        <InputIcon
          key="4"
          name="startDate"
          type={emptyField}
          placeholder={"Fecha de inicio"}
          isValid={true}
          icon={"calendar-day"}
          value={form.startDate}
          decimals={true}
          disabled={true}
          isTextArea={false}
        />

        <InputIcon
          key="5"
          name="plannedFinishDate"
          type={emptyField}
          placeholder={"Fecha estimada de conclusión"}
          isValid={true}
          icon={"hourglass-end"}
          value={form.plannedFinishDate}
          decimals={true}
          disabled={true}
          isTextArea={false}
        />

        <InputIcon
          key="6"
          name="closeDate"
          type={emptyField}
          placeholder={"Fecha de cierre"}
          isValid={true}
          icon={"skull-crossbones"}
          value={form.closeDate}
          decimals={true}
          disabled={true}
          isTextArea={false}
        />
      </div>
    </div>
  );
}

export default FormDashboard;
