import React from "react";
import TimeLineBlock from "../1-blocks/b_TimeLineBlock";

function TimeLine({ data }) {
  return (
    <div>
      {data.map((item, index) =>
        index + 1 < data.length ? (
          item.dateTime === data[index - 1]?.dateTime ? (
            <TimeLineBlock key={index} data={item} sameDate={true} />
          ) : (
            <TimeLineBlock key={index} data={item} />
          )
        ) : item.dateTime === data[index - 1]?.dateTime ? (
          <TimeLineBlock key={index} data={item} sameDate={true} isEnd={true} />
        ) : (
          <TimeLineBlock key={index} data={item} isEnd={true} />
        )
      )}
    </div>
  );
}

export default TimeLine;
