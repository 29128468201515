import Swal from "sweetalert2";
import { primary, danger } from "./recordFactory";

export const AlertObject = (
  message = {},
  type,
  confirmButtonColor = primary,
  cancelButtonColor = danger,
  confirmButtonText = "Sí",
  cancelButtonText = "Cancelar"
) => ({
  title: message.title,
  text: message.text,
  icon: type,
  showCancelButton: true,
  confirmButtonColor: confirmButtonColor,
  cancelButtonColor: cancelButtonColor,
  confirmButtonText: confirmButtonText,
  cancelButtonText: cancelButtonText,
});

export const InputObject = (
  title,
  input,
  inputLabel,
  inputValidator,
  inputOptions = {},
  inputPlaceholder = "",
  confirmButtonColor = primary,
  cancelButtonColor = danger,
  confirmButtonText = "Sí",
  cancelButtonText = "Cancelar"
) => {
  if (input === "number") {
    return {
      title: title,
      input: input,
      inputLabel: inputLabel,
      confirmButtonColor: confirmButtonColor,
      cancelButtonColor: cancelButtonColor,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      showCancelButton: true,
      preConfirm: (value) => {
        let val = parseInt(value);
        if (val <= 0)
          Swal.showValidationMessage(
            "No puedes colocar cantidades menores o iguales a 0"
          );
      },
      inputValidator: (value) => {
        if (!value) {
          return inputValidator;
        }
      },
    };
  } else {
    return {
      title: title,
      input: input,
      inputOptions: inputOptions,
      inputPlaceholder: inputPlaceholder,
      confirmButtonColor: confirmButtonColor,
      cancelButtonColor: cancelButtonColor,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      showCancelButton: true,
    };
  }
};

export const ToastObject = (title, type) => ({
  title: title,
  icon: type,
});
