import React, { forwardRef } from "react";
import logo from "../../resources/images/zamtest/blue.png";

const NavBarSimple = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className="navbar is-flex is-justify-content-center"
      {...props}
    >
      <img className="logo_navbar" src={logo} alt="" />
    </div>
  );
});

NavBarSimple.displayName = "NavBarSimple";

export default NavBarSimple;
