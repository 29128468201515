export const ChartObject = {
    concept: "",
    qty: 1,
    color: '#000000'
};

export const chartTypes = ["Producción", "Calidad", "Metrología"];

const buildPieChartObject = (name ,val, color) => ({
    name: name,
    value: val,
    color: color
});

export const buildDataForChart = (obj, listColors = []) => {
    let values = Object.keys(obj);
    return values.map((item, index) => buildPieChartObject(item, obj[item], listColors[index]));
};

export const buildDataForLineChartMultiple = (obj = [], listColors = []) => {
    let charts = [];
    let newList = [];
    if (Array.isArray(obj)){
        obj.forEach(item => {
            let values = Object.keys(item);
            let chart = values.map((i, index) => buildPieChartObject(i, item[i], listColors[index]));
            charts.push(chart);
        });
        charts[0].forEach((i) => {
            let newObj = {};
            charts[1].forEach((j) => {
                if(i.name === j.name){
                    newObj = {
                        name: i.name,
                        first: i.value,
                        second: j.value
                    }
                }
            });
            newList.push(newObj);
        });
    }
    return newList;
}


