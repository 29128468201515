import React, { useEffect, useRef, useState } from "react";
import DataTable from "../2-systems/s_DataTable";
import { GetTraceableToSendMid } from "../../redux/middleware/traceables.mid";
import * as changeRouteMid from "../../redux/middleware/changeRoute.mid";
import { PostApi } from "../../redux/middleware/httpMethodApi";
import { ToastObject } from "../../Helpers/Factory/swalFactory";
import { useParams } from "react-router";
import * as places from "../../resources/constants/places";
import * as traceableFactory from "../../Helpers/Factory/traceableFactory";
import { emptyForm, quantityError } from "../../resources/constants/messages";
import * as ChangeRouteService from "../../Services/ChangeRouteService";
import { GetFileMid } from "../../redux/middleware/file.mid";
import {
  GetInitDataForSendPiecesByPlace,
  swalConfirmation,
} from "../../Helpers/Factory/SendPiecesFactory";
import * as moveTraceableMid from "../../redux/middleware/moveTraceable.mid";
import { buildRecordForReject } from "../../Helpers/Factory/recordFactory";
import useMounted from "../../hooks/useMounted";
import { refresh_route } from "../../redux/ducks/route";
import routes from "../../resources/constants/routes";
import { links } from "../../resources/constants/links";
import WithTablesData from "../HOC/WithTableData";
import { Toast } from "../../Helpers";
import ProjectFilter from "../2-systems/s_projectFilter";

function SendPieces({
  tableContent,
  onSort,
  pageCount,
  onChangeQuery,
  dispatch,
  apiData,
  count,
  onCallApi,
  query,
}) {
  const dataTableRef = useRef(null);
  const { place } = useParams();
  const [buttons, setButtons] = useState([]);
  const [title, setTitle] = useState("");
  const mounted = useMounted();

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    if (mounted) {
      dispatch(
        refresh_route(routes[links.sendPieces.name], `/sendTraceables/${place}`)
      );

      let initData = GetInitDataForSendPiecesByPlace(place);
      setTitle(initData.title);
      setButtons(initData.buttons);
    }
  }, [place, mounted]);
  /* #endregion */

  /* #region  CONFIRMACIÓN DE CREAR */
  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      if (place === places.Quality) dataTableRef.current.UnselectAllTable();
      Toast.fire(
        ToastObject(
          "Se han enviado con éxito las ordenes de trabajo",
          "success"
        )
      );
      onCallApi();
    }
  }, [apiData.data?.postConfirmation]);
  /* #endregion */

  const modifyQuantity = async (quantity, sendObject) => {
    if (quantity > 1) {
      const value = await swalConfirmation("number");
      if (parseInt(value) > 0) {
        if (parseInt(value) > parseInt(quantity)) {
          Toast.fire(ToastObject(quantityError, "warning"));
          return null;
        }
        sendObject.QuantityToMove = parseInt(value);
      } else {
        Toast.fire(
          ToastObject(
            "Debes de colocar una cantidad numerica para continuar",
            "warning"
          )
        );
        return null;
      }
    }

    return sendObject;
  };

  const handleSubmit = async (rowData, item) => {
    if (place === places.Quality) {
      if (Array.isArray(rowData[0])) {
        onMultipleRow(rowData, item);
        return;
      } else
        Toast.fire(
          ToastObject(
            "Debes de seleccionar al menos una orden de trabajo",
            "warning"
          )
        );
      return;
    }

    const [id, workOrder, quantity] = rowData;
    var sendObject = {
      ZamtestTraceableTag: id,
      QuantityToMove: quantity,
    };
    let result = {};
    switch (item) {
      case "Recibir piezas":
        result = await swalConfirmation("confirmMessage", {
          workOrder,
          place: "Planeación",
        });
        if (!result) return;
        sendObject = await modifyQuantity(quantity, sendObject);
        if (!sendObject) return;
        dispatch(moveTraceableMid.DoubleMoveMid(sendObject));
        break;

      case "Aprobar":
        result = await swalConfirmation("confirmMessage", {
          workOrder,
          place: "Planeación",
        });
        if (!result) return;
        sendObject = await modifyQuantity(quantity, sendObject);
        if (!sendObject) return;
        dispatch(
          PostApi([sendObject, true], ChangeRouteService.ChangeRouteFinishedApi)
        );
        break;

      case "Rechazar":
        result = await swalConfirmation("reject", { workOrder });
        if (!result) return;
        let [quantityToMove, message] = await swalConfirmation("numberMessage");
        if (!quantity) return;
        quantityToMove = parseInt(quantityToMove);
        if (!message) {
          Toast.fire(ToastObject(emptyForm, "error"));
          return;
        }
        if (quantityToMove > parseInt(quantity)) {
          Toast.fire(ToastObject(quantityError, "warning"));
          return;
        }
        sendObject.QuantityToMove = quantityToMove;
        let record = buildRecordForReject(
          sendObject.ZamtestTraceableTag,
          place,
          message,
          sendObject.QuantityToMove
        );
        if (place === places.Metrology) {
          record.title = "Rechazo de metrología";
          dispatch(
            changeRouteMid.ChangeRouteMetrologyNotPassMid(sendObject, record)
          );
        }
        break;

      case "Paso sin inspección":
        result = await swalConfirmation("pass", { workOrder });
        if (result) {
          let quantity = await swalConfirmation("number");
          if (quantity) {
            if (parseInt(quantity) > parseInt(rowData[2])) {
              Toast.fire(ToastObject(quantityError, "warning"));
            } else {
              sendObject.QuantityToMove = parseInt(quantity);
              dispatch(
                PostApi(
                  [sendObject],
                  ChangeRouteService.ChangeRouteFinishedWithoutInpection
                )
              );
            }
          }
        }
        break;

      case "Enviar con fallo":
        result = await swalConfirmation("failure", { workOrder });
        if (!result) return;
        sendObject = await modifyQuantity(quantity, sendObject);
        if (!sendObject) return;
        dispatch(
          PostApi(
            [sendObject, false],
            ChangeRouteService.ChangeRouteFinishedApi
          )
        );
        break;

      case "Ver PDF":
        dispatch(GetFileMid(rowData[1]));
        break;
      default:
        break;
    }
  };

  const onMultipleRow = async (rows, button) => {
    var result = {};
    if (Array.isArray(rows) && rows.length > 0) {
      if (button === "Ver PDF") {
        dispatch(moveTraceableMid.VisualizeMultipleFilesMid(rows));
        dataTableRef.current.UnselectAllTable();
      } else if (button === "Rechazar") {
        result = await swalConfirmation("reject");
        if (result) {
          let values = await swalConfirmation("message");
          if (values) {
            let message = values[1];
            dispatch(
              moveTraceableMid.RejectMultipleTraceablesMid(rows, message, place)
            );
          }
        }
      } else if (button === "Orden incompleta") {
        result = await swalConfirmation("stop");
        if (result) {
          dispatch(moveTraceableMid.ChangeMultipleTraceablesMid(rows));
        }
      } else if (button === "Aprobar") {
        result = await swalConfirmation("yesno");
        if (result) {
          dispatch(moveTraceableMid.SendMultipleTraceablesMid(rows));
        }
      }
    } else {
      Toast.fire(
        ToastObject("Debes de seleccionar un renglón para continuar", "info")
      );
    }
  };

  const onSelect = (value) => {
    if (place === places.Quality) dataTableRef.current.UnselectAllTable();
    // filtrar por orden de trabajo
    if (value) onChangeQuery(query, { projectId: value.id });
    else onChangeQuery(query, { projectId: "" });
  };

  return (
    <div className="is-flex is-justify-content-center">
      <div style={{ width: "90%" }}>
        <DataTable
          ref={dataTableRef}
          data={tableContent}
          count={count}
          visibleColumn={false}
          onClick={handleSubmit}
          buttons={buttons}
          states={
            place !== places.Auxiliar
              ? traceableFactory.routeStates
              : traceableFactory.routeStateForAux
          }
          columnState={place === places.Finished ? 9 : 8}
          onSort={onSort}
          onChangeQuery={onChangeQuery}
          pageCount={pageCount}
          hasCheckbox={place === places.Quality ? true : false}
          FilterComponent={
            <ProjectFilter title="Filtrar por projecto" onSelect={onSelect} />
          }
        >
          <h1 className="title is-4 is-flex is-justify-content-center">
            {title}
          </h1>
        </DataTable>
      </div>
    </div>
  );
}

export default WithTablesData(
  SendPieces,
  GetTraceableToSendMid,
  null,
  traceableFactory.sortingTraceable,
  "ApiMethod"
);
