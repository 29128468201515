import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useMounted from "../../hooks/useMounted";
import * as traceableFactory from "../../Helpers/Factory/traceableFactory";
import WithContextData from "./WithContextData";
import { GetTraceableToSendMid } from "../../redux/middleware/traceables.mid";
import * as places from "../../resources/constants/places";

// eslint-disable-next-line no-unused-vars
const WithTabsDataTable = (Component, place, routeIdFilter = "1") => {
  const WrappedComponent = ({ dispatch, apiData }) => {
    const [tableContent, setTableContent] = useState([]);
    const [queryParams, setQueryParams] = useState({
      routeId: routeIdFilter,
    });
    const [pageCount, setPageCount] = useState(1);
    const [count, setCount] = useState(0);
    const mounted = useMounted();
    const [placeUrl, setPlaceUrl] = useState(place);
    const dataTableSelection = useSelector((state) => state.dataTableSelection);

    useEffect(() => {
      if (apiData.data?.pageIndex) {
        if (
          Array.isArray(apiData.data.data) &&
          apiData.data.data.length > 0 &&
          apiData.data.data[0]?.Id
        ) {
          setTableContent(apiData.data.data);
        } else setTableContent([]);
        setPageCount(apiData.data.pageCount);
        setCount(apiData.data.count);
      }
    }, [apiData.data]);

    const onCallApi = useCallback(() => {
      if (queryParams?.pageSize === dataTableSelection) {
        let query = queryParams;
        if (
          placeUrl === places.Production ||
          placeUrl === places.ExternalMachining
        )
          query = { ...query, routeId: "" };
        dispatch(GetTraceableToSendMid(query, placeUrl));
      }
    }, [queryParams, dispatch, dataTableSelection, placeUrl]);

    useEffect(() => {
      if (
        mounted &&
        queryParams?.pageSize === dataTableSelection &&
        queryParams?.routeId
      ) {
        onCallApi();
      }
    }, [queryParams, mounted, onCallApi, dataTableSelection]);

    const onSort = (column, upDown, name) => {
      let variables = Object.keys(traceableFactory.sortingTraceable);
      variables.forEach((item) => {
        if (item === name) {
          let position = traceableFactory.sortingTraceable[item];
          setQueryParams({
            ...queryParams,
            sort: !upDown ? position[0] : position[1],
          });
        }
      });
    };

    const onChangeQuery = (query) => {
      if (mounted) setQueryParams({ ...queryParams, ...query });
    };

    const onChangePlace = (place) => setPlaceUrl(place);

    return (
      <>
        <Component
          apiData={apiData}
          tableContent={tableContent}
          dispatch={dispatch}
          onSort={onSort}
          onChangeQuery={onChangeQuery}
          query={queryParams}
          pageCount={pageCount}
          onCallApi={onCallApi}
          count={count}
          onChangePlace={onChangePlace}
        />
      </>
    );
  };

  return WithContextData(WrappedComponent);
};

export default WithTabsDataTable;
