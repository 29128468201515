import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./input.module.scss";

function FormInput({
  name,
  icon,
  placeholder,
  onChange = () => {},
  onCheckError = () => {},
  errorMessage = "",
  onKeyDown = () => {},
  title,
  className = "",
  type = "text",
  ...props
}) {
  const input = useRef();
  const [inputOk, setInputOk] = useState(null);

  const onChangeChildren = ({ target }) => {
    setInputOk(onCheckError(target.value));
    onChange(target.value, target.name);
  };

  return (
    <div className={`field `}>
      <label className="label">{title}</label>
      <div className="control has-icons-left has-icons-right">
        <input
          ref={input}
          className={`input ${
            inputOk !== null ? `is-${inputOk ? "success" : "danger"}` : ""
          } ${className}`}
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChangeChildren}
          onKeyDown={onKeyDown}
          {...props}
        />
        <span className="icon is-small is-left">
          <i className={`fas fa-${icon}`}></i>
        </span>
        <span className="icon is-small is-right">
          <i className={`fas fa-${inputOk ? "check" : "times"}`}></i>
        </span>
      </div>
      {errorMessage !== "" && (
        <p
          className={`help is-danger ${
            inputOk === null
              ? styles.hide
              : !inputOk
              ? styles.show
              : styles.hide
          }`}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
}

FormInput.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onCheckError: PropTypes.func,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  onKeyDown: PropTypes.func,
};

FormInput.displayName = "Input";

export default FormInput;
