import React, { useEffect, useState } from "react";
import { Box, FormControl, MenuItem, Select } from "@material-ui/core";
import {
  danger,
  primary,
  warning,
} from "../../../Helpers/Factory/recordFactory";
import SelectMultiple from "../../2-systems/SelectMultiple";
import { chartTypes } from "../../../Helpers/Factory/chartFactory";
import LineCharts from "../../1-blocks/b_LineCharts";
import WithContextData from "../../HOC/WithContextData";
import {
  GetProjectsForLineChartMid,
  GetProjectsForPieChartMid,
  GetProjectsForSelectMid,
} from "../../../redux/middleware/projects.mid";
import ButtonIcon from "../../1-blocks/b_ButtonIcon";
import authService from "../../api-authorization/AuthorizeService";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import PieCharts from "../../1-blocks/b_PieCharts";
import { refresh_route } from "../../../redux/ducks/route";
import moment from "moment";
import FormInput from "../../0-atoms/a_formInput";
import { isEmpty } from "lodash";

function StatisticalGraphs({ apiData, dispatch, helpers, _routes, links }) {
  const [form, setForm] = useState({
    projects: [],
    chartType: "",
    startDate: new Date(),
    finishDate: new Date(),
  });
  const [productivity, setProductivity] = useState([]);
  const [quality, setQuality] = useState([]);
  const [metrology, setMetrology] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(refresh_route(_routes[links.stats.name]));
    const user = authService.getUser();
    dispatch(GetProjectsForSelectMid(user.name, user.role));
  }, []);

  useEffect(() => {
    if (!apiData.loading && apiData.ok) {
      if (apiData.data?.projectSelect) setProjects(apiData.data.projectSelect);
      else if (apiData.data?.productivity)
        setProductivity(apiData.data.productivity);
      else if (apiData.data?.info) {
        setQuality(apiData.data.info.quality);
        setMetrology(apiData.data.info.metrology);
      }
    }
  }, [apiData.data]);

  const handleDateChange = (value, name) => {
    setVisible(false);
    setForm({ ...form, [name]: value });
  };

  const onChange = (e) => {
    setVisible(false);
    let ids = [];
    let names = [];
    projects.forEach((project) => {
      e.target.value.forEach((name) => {
        if (project.id === name) ids.push(project);
      });
    });
    if (e.target.value.length > 2) {
      ids.pop();
      helpers.Toast.fire(
        ToastObject("No puedes seleccionar más de dos proyectos", "error")
      );
    }
    setForm({ ...form, projects: ids });
    ids.forEach((item) => names.push(item.id));
    setSelectedValues(names);
  };

  const onChangeGraphType = (e) => {
    setVisible(false);
    setForm({ ...form, chartType: e.target.value });
  };

  const submitHandler = () => {
    setVisible(true);
    if (selectedValues.length === 1) {
      if (form.chartType === "Producción")
        dispatch(
          GetProjectsForLineChartMid(
            form.projects[0].id,
            form.projects[0].id,
            form.startDate,
            form.finishDate
          )
        );
      else
        dispatch(
          GetProjectsForPieChartMid(form.projects[0].id, form.projects[0].id)
        );
    } else if (selectedValues.length === 2) {
      if (form.chartType === "Producción")
        dispatch(
          GetProjectsForLineChartMid(
            form.projects[0].id,
            form.projects[1].id,
            form.startDate,
            form.finishDate
          )
        );
      else
        dispatch(
          GetProjectsForPieChartMid(form.projects[0].id, form.projects[1].id)
        );
    } else {
      setVisible(false);
      helpers.Toast.fire(
        ToastObject("No has seleccionado algún proyecto", "error")
      );
    }
  };

  return (
    <div>
      <div
        className="box whiteBox mx-1"
        style={{ display: "flex", alignItems: "end" }}
      >
        <div className="mx-2 one-quarter">
          <label className="label is-small">Proyectos</label>
          <SelectMultiple
            selectValue={selectedValues}
            onChange={onChange}
            data={projects}
          />
        </div>
        <div className="mx-2 one-quarter">
          <label className="label is-small">Gráfica</label>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Select
                style={{ height: "60px" }}
                id="demo-simple-select"
                value={form.chartType}
                label="type"
                onChange={onChangeGraphType}
              >
                {chartTypes.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>

        {form.chartType === "Producción" && (
          <>
            <div>
              <label className="label is-small">Fecha inicial</label>
              <FormInput
                title=""
                placeholder={""}
                type="date"
                onChange={handleDateChange}
                icon={"calendar-day"}
                name="startDate"
                onCheckError={isEmpty}
                max={moment(new Date()).format("YYYY-MM-DD")}
              />
            </div>
            <div>
              <label className="label is-small">Fecha final</label>
              <FormInput
                title=""
                placeholder={""}
                type="date"
                onChange={handleDateChange}
                icon={"calendar-day"}
                name="finishDate"
                onCheckError={isEmpty}
                max={moment(new Date()).format("YYYY-MM-DD")}
                className="mx-2"
              />
            </div>
          </>
        )}
        <ButtonIcon
          className="is-info has-tooltip-info ml-3 mb-2"
          aria-label="search"
          data-tooltip="Buscar"
          onClick={submitHandler}
          icon="search"
        />
      </div>
      {form.chartType === "Producción" && productivity.length > 0 && visible ? (
        <div className="box whiteBox mx-1" style={{ height: "400px" }}>
          <LineCharts data={productivity} stack={1} single={false}></LineCharts>
          {selectedValues.length === 2 ? (
            <div className="right-aligned" style={{ margin: "-1%" }}>
              <span className="tag is-info">{form.projects[0].id}</span>
              <span className="tag is-danger">{form.projects[1].id}</span>
            </div>
          ) : null}
        </div>
      ) : (
        <div></div>
      )}
      {form.chartType === "Calidad" && quality.length > 0 && visible ? (
        <div className="columns box whiteBox m-1">
          <div className="column" style={{ height: "350px" }}>
            <h1 className="title is-6 is-small">{form.projects[0].id}</h1>
            <PieCharts data={quality[0]} colors={[primary, danger]}></PieCharts>
          </div>
          {selectedValues.length === 2 ? (
            <div className="column" style={{ height: "350px" }}>
              <h1 className="title is-6 is-small">{form.projects[1].id}</h1>
              <PieCharts
                data={quality[1]}
                colors={[primary, danger]}
              ></PieCharts>
            </div>
          ) : null}
        </div>
      ) : (
        <div></div>
      )}
      {form.chartType === "Metrología" && metrology.length > 0 && visible ? (
        <div className="columns box whiteBox m-1">
          <div className="column" style={{ height: "350px" }}>
            <h1 className="title is-6 is-small">{form.projects[0].id}</h1>
            <PieCharts
              data={metrology[0]}
              colors={[primary, warning, danger]}
            ></PieCharts>
          </div>
          {selectedValues.length === 2 ? (
            <div className="column" style={{ height: "350px" }}>
              <h1 className="title is-6 is-small">{form.projects[1].id}</h1>
              <PieCharts
                data={metrology[1]}
                colors={[primary, warning, danger]}
              ></PieCharts>
            </div>
          ) : null}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default WithContextData(StatisticalGraphs);
