export const password = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#;,=+?¡¿_$%^&*-])(?=.{7,})"
);

const alphanumericPathern = /[^a-zA-Z\d\u00C0-\u00FF]/;
export const alphanumeric = new RegExp(alphanumericPathern);

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const email = new RegExp(emailPattern);

export const phone = new RegExp(
  "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$"
);

const noNumbersPattern =
  /^(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
export const noNumbers = new RegExp(noNumbersPattern);

export const noSpaces = new RegExp("^[A-Za-z0-9][A-Za-z0-9]*$");

export const numbersOnly = new RegExp("^[0-9]*$");

export const decimalNumbers = new RegExp("[0-9]+(.[0-9][0-9]?)?");

export const ProjectName =
  /^([Z])([0-9]{2})([A-Z]{1})([0-9])+(([-])([A-Z]{1,2}))?$/;

export const AssemblyName = /^([sS])([0-9])/;

export const PieceName = /^([sS])([0-9])/;
