/* #region  IMPORTS */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as helpers from "../../Helpers";
import authService from "../api-authorization/AuthorizeService";
import { refresh_route } from "../../redux/ducks/route";
import { links } from "../../resources/constants/links";
import _routes from "../../resources/constants/routes";
import Modal from "../2-systems/s_modal";
import { loadingData } from "../../Helpers/Factory/modalFactory";
import { ToastObject } from "../../Helpers/Factory/swalFactory";
import { apiSuccess } from "../../redux/ducks/apiData";
import { NO_AUTH } from "../../resources/constants/messages";
/* #endregion */

const WithContextData = (Component) => {
  const WrappedComponent = () => {
    /* #region  VARIABLES */
    const history = useHistory();
    const dispatch = useDispatch();
    const apiData = useSelector((state) => state.apiData);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [form, setForm] = useState({});
    const [mounted, setMounted] = useState(false);
    /* #endregion */

    useEffect(() => {
      setMounted(true);

      return () => {
        setMounted(false);
      };
    }, []);

    /* #region  PREGUNTAMOS SI EL USUARIO ESTA AUTENTICADO */
    useEffect(() => {
      if (mounted) {
        const isAuthenticated = authService.isAuthenticated();
        setIsLoggedIn(isAuthenticated);
        if (!isAuthenticated) onGoToLogin();
      }
    }, [mounted]);
    /* #endregion */

    /* #region  PREGUNTAR SI LA PÁGINA ESTA CARGANDO Y VERIFICAR QUE NO HAYA MENSAJES DE ERROR DE API */
    useEffect(() => {
      if (apiData.loading) {
        setOpenModal(true);
      } else {
        setOpenModal(false);
        setTimeout(() => {
          if (!apiData.ok) {
            console.log(apiData);
            if (isLoggedIn) {
              if (apiData.errorMessage.status !== NO_AUTH) {
                helpers.Toast.fire(
                  ToastObject(apiData.errorMessage.error, "error")
                );
              } else onGoToLogin();
            }
            dispatch(apiSuccess());
          }
        }, 100);
      }
    }, [apiData.loading, apiData.errorMessage]);
    /* #endregion */

    const onGoToLogin = () => history.replace(links.login.to);

    if (!isLoggedIn) {
      return <div>Redireccionando...</div>;
    } else {
      return (
        <div className="is-fullheight">
          <Modal
            show={openModal}
            modalData={loadingData}
            onClose={() => setOpenModal(false)}
          ></Modal>
          <Component
            history={history}
            dispatch={dispatch}
            apiData={apiData}
            helpers={helpers}
            refresh_route={refresh_route}
            links={links}
            _routes={_routes}
            setOpenModal={setOpenModal}
            setForm={setForm}
            form={form}
          />
        </div>
      );
    }
  };

  return WrappedComponent;
};

export default WithContextData;
