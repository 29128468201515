import React, { useEffect, useState } from "react";
import styles from "./forgotPassword.module.scss";
import FormInput from "../../0-atoms/a_formInput";
import { isZamtestEmail } from "../../../Helpers/Validators";
import ButtonIcon from "../../1-blocks/b_ButtonIcon";
import { ToastError } from "../../../Helpers/sweetAlertHandler";
import { PostApi } from "../../../redux/middleware/httpMethodApi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Toast } from "../../../Helpers";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import { links } from "../../../resources/constants/links";
import { apiSuccess } from "../../../redux/ducks/apiData";
import { ForgotPasswordApi } from "../../../Services/authenticationService";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const apiData = useSelector((state) => state.apiData);
  const [formSended, setFormSended] = useState(false);

  useEffect(() => {
    dispatch(apiSuccess());
    setFormSended(false);
  }, []);

  useEffect(() => {
    setLoading(apiData.loading);
  }, [apiData.loading]);

  useEffect(() => {
    if (apiData.data.postConfirmation) {
      dispatch(apiSuccess());
      setFormSended(true);
      Toast.fire(
        ToastObject(
          "Se ha mandado la petición de contraseña olvidada",
          "success"
        )
      );
    } else if (!apiData.loading && !apiData.ok) setFormSended(false);
  }, [apiData.data]);

  const onChange = (value) => setEmail(value);

  const onSubmit = () => {
    if (!isZamtestEmail(email)) {
      ToastError("Coloca un correo valido");
      return;
    }
    dispatch(PostApi([email], ForgotPasswordApi));
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") onSubmit();
  };

  return (
    <div className="is-flex is-justify-content-center is-align-items-center is-fullheight">
      <div className={`${styles.container}`}>
        <div className={`box  has-background-white`}>
          <header className="is-flex is-justify-content-center mb-5">
            <h4 className="title is-4">¿Olvidaste la contraseña?</h4>
          </header>
          <span
            className="icon-text is-clickable mb-5"
            onClick={() => history.push(links.login.to)}
          >
            <span className="icon has-text-info">
              <i className="fas fa-arrow-left"></i>
            </span>
            <span className="has-text-info">Volver</span>
          </span>

          <FormInput
            title={"Coloca tu correo electrónico"}
            placeholder={"Coloca tu correo electrónico"}
            name="email"
            onChange={onChange}
            icon="user"
            onCheckError={isZamtestEmail}
            errorMessage="El correo debe de corresponder al dominio de Zamtest"
            onKeyDown={onKeyDown}
          />

          <br />
          <footer className="is-flex is-justify-content-center">
            <ButtonIcon
              icon={"paper-plane"}
              onClick={onSubmit}
              className={`is-success ${loading && "is-loading"}`}
              name="Enviar"
            />
          </footer>
        </div>

        <div
          className={`${styles.text} ${formSended ? styles.show : styles.hide}`}
        >
          <p>
            Se ha enviado la petición para restablecer la contraseña, favor de
            verificar su correo electrónico, siga las instrucciones que se
            encuentren ahi mismo, si no ha recibido nada puede volver a
            intentarlo o si lo desea contactar a soporte técnico{" "}
            <span
              className="has-text-info is-clickable"
              onClick={() => history.push(links.login.to)}
            >
              Volver a inicio de sesión
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
