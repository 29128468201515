import React from "react";

function TimeLineBlock({
  data,
  colorLine = "black",
  sameDate = false,
  isEnd = false,
}) {
  return (
    <div className="timeLineFlex" data-testid="timeline">
      <div className="mr-5 timeLineFlexChild">
        <div className="mr-5 datePosition" data-testid="timeline_dateTime">
          {!sameDate ? data.dateTime : null}
        </div>
        <div className="allHeight">
          <div
            className="iconContainer"
            data-testid="timeline_icon"
            style={{ background: data.color }}
          >
            <span
              className="icon is-small has-tooltip"
              data-tooltip={data.dateTime}
            >
              <i className={`fas fa-${data.icon}`}></i>
            </span>
          </div>
          {!isEnd ? (
            <div
              className="line"
              style={{
                borderLeftColor: colorLine === "black" ? "black" : colorLine,
              }}
            ></div>
          ) : null}
        </div>
      </div>
      <div className="card m-4 allWidth" data-testid="timeline_block">
        <div className="card-header pt-0 pb-0 is-flex is-justify-content-space-between">
          <p data-testid="timeline_title">
            <strong>{data.title}: </strong> {data.content}
          </p>
          <div
            className="card-header-icon has-tooltip-left pt-0 pb-0"
            data-tooltip={data.dateTime}
            data-testid="timeline_hour"
          >
            <div className="icon-text iconHour pt-0 pb-0">
              <span className="icon has-text-info mr-4 pt-0 pb-0">
                <i className="fas fa-clock" style={{ fontSize: "16px" }}></i>
              </span>
              <span style={{ marginTop: "3px", fontSize: "14px" }}>
                {data.hour}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimeLineBlock;
