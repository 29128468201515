import Swal from "sweetalert2";
import * as roles from "../resources/constants/roles";

/* #region  SWAL ALERT METHODS */
export const swalAlert = (modal = { title: "", text: "", icon: "" }) => {
  Swal.fire(modal);
};

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
/* #endregion */

/* #region  DATE METHODS */
export const convertUTCDateToLocalDate = (date) => {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};

export const buildNormalDate = (date) => {
  let array = date.toString().split(" ");
  let day = array[1];
  let month = array[2] + ",";
  let year = array[3] + ",";
  return new Date(day + month + year);
};

export function buildDate(newDate) {
  let date = new Date(newDate).toUTCString();
  let array = date.split(" ");
  let day = array[1];
  let month = array[2] + ",";
  let year = array[3] + ",";
  let dateComplete = convertUTCDateToLocalDate(new Date(newDate));
  // eslint-disable-next-line no-unused-vars
  let hour = 0;
  if (dateComplete.getMinutes() === 0) {
    hour = dateComplete.getHours() + ":00";
  } else {
    hour = dateComplete.getHours() + ":" + dateComplete.getMinutes();
  }
  if (dateComplete.getHours() < 10) {
    // eslint-disable-next-line no-unused-vars
    hour = "0" + hour;
  }
  let appointmentUTC = new Date(year + month + day);
  let monthUTC = appointmentUTC.getMonth() + 1;

  return (
    appointmentUTC.getDate() +
    "/" +
    monthUTC +
    "/" +
    appointmentUTC.getFullYear()
  );
}

export function buildHour(newDate) {
  let date = new Date(newDate);
  let hour = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hour >= 12 ? "pm" : "am";
  hour = hour % 12;
  hour = hour ? hour : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let time = hour + ":" + minutes + " " + ampm;
  return time;
}
/* #endregion */

/* #region  CSV METHOD */
export const buildCSVObject = (data, project) => {
  let headers = data.slice(0, data.indexOf("\n")).split(",");
  if (headers.length !== 16) return false;
  headers[0] = "id";
  headers[1] = "projectId";
  headers[2] = "workOrderName";
  headers[3] = "description";
  headers[4] = "quantity";
  headers[5] = "rev";
  headers[6] = "pieceMaterial";
  headers[7] = "pieceMaterialKind";
  headers[8] = "cnc";
  headers[9] = "corte";
  headers[10] = "soldadura";
  headers[11] = "dobles";
  headers[12] = "metallicFinish";
  headers[13] = "thickness";
  headers[14] = "width";
  headers[15] = "height";
  const rows = data.slice(data.indexOf("\n") + 1).split("\n");

  let result = rows.map((row) => {
    const values = row.split(",");
    const eachObject = headers.reduce((obj, header, i) => {
      obj[header] = values[i];
      return obj;
    }, {});
    return eachObject;
  });

  result = result.filter((piece) => {
    if (
      piece?.projectId &&
      piece.projectId !== "" &&
      parseInt(piece.quantity) > 0
    ) {
      if (
        piece?.workOrderName.includes(piece?.projectId) ||
        piece?.workOrderName.includes(project.clientCode)
      ) {
        if (parseFloat(piece.thickness))
          piece.thickness = parseFloat(piece.thickness);
        else piece.thickness = 0;
        if (parseFloat(piece.width)) piece.width = parseFloat(piece.width);
        else piece.width = 0;
        if (parseFloat(piece.height)) piece.height = parseFloat(piece.height);
        else piece.height = 0;
        piece.quantity = parseInt(piece.quantity);
        piece.projectId = project.id;
        return piece;
      }
    }

    return null;
  });

  return result.filter((r) => r !== null);
};
/* #endregion */

/* #region  FORM METHODS */
export const ValidSelect = (e) => {
  let value = e.target.value;
  if (value.toString() !== "0") {
    return true;
  } else {
    return false;
  }
};

const CheckForEmptyInput = (input, form) => {
  Object.keys(form).forEach((key) => {
    if (input.name === key.toString()) {
      input.isValid = false;
    }
  });
};

export const CheckErrorsForm = (inputs, form) => {
  var error = false;
  Object.values(inputs).forEach((item) => {
    if (item.isValid === false) {
      error = true;
    } else if (item.isValid === null) {
      error = true;
      CheckForEmptyInput(item, form);
    }
  });
  return error;
};

export const CleanInputs = (inputs) => {
  Object.values(inputs).forEach((item) => {
    item.isValid = null;
  });
};

export const ValidInputs = (inputs) => {
  Object.values(inputs).forEach((item) => {
    item.isValid = true;
  });
};

export const SetValueInputs = (inputs, errorInputs = [], value = false) => {
  Object.keys(inputs).forEach((input) => {
    errorInputs.forEach((errorInput) => {
      if (input.toString() === errorInput) inputs[input].isValid = value;
    });
  });
};
/* #endregion */

/* #region VISUAL CONDITIONS METHODS */
export const isProjectManager = (rol) => {
  return (
    rol === roles.Root ||
    rol === roles.ProjectManager ||
    rol === roles.AdminProjectManager ||
    rol === roles.Director
  );
};

export const isDesigner = (rol) => {
  return (
    rol === roles.Root || rol === roles.AdminDesign || rol === roles.Design
  );
};
/* #endregion */

export const GetItemOfLocalStorage = (item) => {
  const serializeData = localStorage.getItem("state");
  return JSON.parse(serializeData)[item];
};
