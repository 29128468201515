import { OK } from "../../resources/constants/messages";
import { apiRequest, apiSuccess, apiFail } from "../ducks/apiData";

export const CreateData = (data, filterFunction = () => {}) => {
  if (data.length > 0) {
    return data.map((workOrder) => filterFunction(workOrder));
  } else return [];
};

export const GetApi = (params = [], apiMethod = () => {}) => {
  return async (dispatch) => {
    dispatch(apiRequest());
    var result = await apiMethod(...params);
    if (result.status === OK) {
      return dispatch(apiSuccess(result.data));
    } else {
      return dispatch(apiFail({ error: result.error, status: result.status }));
    }
  };
};

export const PostApi =
  (params = [], apiMethod = () => {}) =>
  async (dispatch) => {
    dispatch(apiRequest());
    var result = await apiMethod(...params);
    if (result.status === OK) {
      return dispatch(
        apiSuccess({ postConfirmation: true, data: result.data })
      );
    } else {
      return dispatch(apiFail({ error: result.error, status: result.status }));
    }
  };
