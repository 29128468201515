import * as urlConstants from "./helpers/urlConstants";
import { fetchMethod } from "./helpers/fetchMethod";
import { FORM, GET, POST } from "./helpers/serviceConstants";
import setDataTableQuery from "./helpers/setDataTableQuery";
import authService from "../components/api-authorization/AuthorizeService";

export const CreateWorkOrderApi = async (data) => {
  return await fetchMethod(urlConstants.CreateWorkOrder, FORM, data);
};

export const UpdatateWorkOrderApi = async (data) => {
  return await fetchMethod(urlConstants.UpdateWorkOrder, POST, data);
};

export const GetWorkOrderApi = async (id) => {
  return await fetchMethod(urlConstants.GetWorkOrder + id);
};

export const GetWorkOrdersApi = async () => {
  return await fetchMethod(urlConstants.GetWorkOrders);
};

export const GetWorkOrdersByProjectApi = async (projectId) => {
  return await fetchMethod(urlConstants.GetWorkOrdersByProject + projectId);
};

export const ChangeWorkOrderStatusFromTraceableApi = async (status) => {
  return await fetchMethod(
    urlConstants.ChangeWorkOrderStatusFromTraceable,
    POST,
    status
  );
};

export const CancelWorkOrderApi = async (workOrderCode) => {
  return await fetchMethod(urlConstants.CancelWorkOrder + workOrderCode, POST);
};

export const ChangeWorkOrderProductionTimeFromTraceableApi = async (obj) => {
  return await fetchMethod(
    urlConstants.ChangeWorkOrderProductionTimeFromTraceable,
    POST,
    obj
  );
};

export const GetWorkOrderWithSpecApi = async (query) => {
  let queryString = setDataTableQuery(query);
  return await fetchMethod(urlConstants.GetWorkOrdersWithSpec + queryString);
};

export const GetWorkOrderByProjectWithSpecApi = async (query, id) => {
  let queryString = setDataTableQuery(query);
  return await fetchMethod(
    urlConstants.GetWorkOrdersByProjectWithSpec + id + queryString
  );
};

const RequesOptions = (token) => {
  return {
    method: GET,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const GetWorkOrdersFileByProjectApi = async (projectId) => {
  const token = await authService.getAccessToken();
  let request = RequesOptions(token);

  const urlHost = urlConstants.GetUrl();
  const response = await fetch(
    urlHost + urlConstants.GetWorkOrdersByProjectFile + projectId,
    request
  );
  try {
    if (!response.ok) throw response;
    const data = await response.blob();
    var url = window.URL.createObjectURL(data);
    let fileName = "Ordenes_de_trabajo_" + projectId + ".xlsx";
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.removeChild();
    return true;
  } catch (err) {
    return false;
  }
};
