import * as urlConstants from "./helpers/urlConstants";
import { fetchMethod } from "./helpers/fetchMethod";
import { POST } from "./helpers/serviceConstants";
import setDataTableQuery from "./helpers/setDataTableQuery";

// string ZamtestTraceableTag
// int QuantityToMove
export const MoveNextApi = async (data) =>
  await fetchMethod(urlConstants.MoveNext, POST, data);
export const DoubleMoveApi = async (data) =>
  await fetchMethod(urlConstants.DoubleMove, POST, data);
export const TripleMoveApi = async (data) =>
  await fetchMethod(urlConstants.TripleMove, POST, data);

export const GetTraceablesToReceiveApi = async (place) => {
  return await fetchMethod(urlConstants.GetTraceablesToReceive + place);
};

export const GetTraceablesToReceiveWithSpecApi = async (
  query,
  place,
  status = []
) => {
  let queryString = setDataTableQuery(query, true);
  if (status.length > 0) {
    status.forEach((item) => {
      queryString += "&Status=" + item;
    });
  }
  return await fetchMethod(
    urlConstants.GetTraceablesToReceiveWithSpec + place + queryString
  );
};

export const GetTraceablesOnPlaceApi = async (place) => {
  return await fetchMethod(urlConstants.GetTraceablesOnPlace + place);
};

export const GetTraceablesOnPlaceWithSpecApi = async (query, place) => {
  let queryString = setDataTableQuery(query, true);
  return await fetchMethod(
    urlConstants.GetTraceablesOnPlaceWithSpec + place + queryString
  );
};

export const GetTraceablesOnDesignForDesignerApi = async () => {
  return await fetchMethod(urlConstants.GetTraceablesOnDesignForDesigner);
};

export const GetTraceablesToReceiveForDesignerApi = async () => {
  return await fetchMethod(urlConstants.GetTraceablesOnDesignForDesigner);
};

export const GetTraceablesOnPlaceByWorkOrderApi = async (
  place,
  workOrderId
) => {
  return await fetchMethod(
    urlConstants.GetTraceablesOnPlaceByWorkOrder + place + "/" + workOrderId
  );
};

export const GetTraceablesToReceiveByWorkOrderApi = async (
  place,
  workOrderId
) => {
  return await fetchMethod(
    urlConstants.GetTraceablesToReceiveByWorkOrder + place + "/" + workOrderId
  );
};
