import React from "react";

export default function useTabs({ reducer = tabsReducer } = {}) {
  const [activeTabs, dispatcher] = React.useReducer(reducer, [0]);
  const toggleTab = (index) => {
    dispatcher({ type: actions.TOGGLE_ACTIVE_TABS, index });
  };
  return [activeTabs, toggleTab];
}

//Actions For reducer
const actions = {
  TOGGLE_ACTIVE_TABS: "tabs/TOGGLE_ACTIVE_TAB",
};

//Reducers
function tabsReducer(activeTabs, action) {
  switch (action.type) {
    case actions.TOGGLE_ACTIVE_TABS: {
      return [action.index];
    }
    default:
      return activeTabs;
  }
}
