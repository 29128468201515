import React, {
  createRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

const Tab = forwardRef(({ className, tabs, children, onClick }, ref) => {
  const refs = useRef(tabs.map(() => createRef()));

  const removeClass = () => {
    refs.current.forEach((singleRef) => {
      singleRef.current.classList.remove("is-active");
    });
  };

  const onClickChildren = (name) => {
    onClick(name);
    const currentRef = refs.current.find(
      (r) => r.current.getAttribute("name") === name
    );
    if (currentRef) {
      if (!currentRef.current.classList.contains("is-active")) {
        removeClass();
        currentRef.current.classList.add("is-active");
      }
    }
  };

  useImperativeHandle(ref, () => {});

  return (
    <div
      className="is-flex is-align-items-center is-flex-direction-column m-0"
      style={{ width: "100%" }}
    >
      <div
        className={`tabs is-boxed ${className} mb-0 is-toggle`}
        style={{ width: "100%" }}
      >
        <ul>
          {tabs.map((item, key) => (
            <li
              key={key}
              ref={refs.current[key]}
              className={`${key === 0 ? "is-active" : ""}`}
              name={item.name}
            >
              <a onClick={() => onClickChildren(item.name)}>
                <span className="icon is-small">
                  <i className={`fas fa-${item.icon}`} aria-hidden="true" />
                </span>
                <span>{item.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div
        className="box is-flex is-justify-content-center mt-0 has-background-white"
        style={{ width: "100%" }}
      >
        {children}
      </div>
    </div>
  );
});

Tab.displayName = "Tab";

export default Tab;
