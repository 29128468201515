/* #region  IMPORTS */
import React, { useEffect, useState } from "react";
import { GetOperationsMid } from "../../../redux/middleware/operations.mid";
import ButtonContainer from "../../1-blocks/b_ButtonContainer";
import Empty from "../../1-blocks/b_Empty";
import DataTable from "../../dataTable/DataTable";
import WithContextData from "../../HOC/WithContextData";
/* #endregion */

function Operations({
  apiData,
  history,
  dispatch,
  refresh_route,
  _routes,
  links,
}) {
  const [tableData, setTableData] = useState([]);

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    dispatch(GetOperationsMid());
    dispatch(refresh_route(_routes[links.operations.name]));
    return () => {
      setTableData([]);
    };
  }, []);

  useEffect(() => {
    if (!apiData.loading && apiData.ok && apiData.data?.operationsTable)
      setTableData(apiData.data.operationsTable);
  }, [apiData.data]);
  /* #endregion */

  const handleSubmit = (rowData, item) => {
    switch (item) {
      case "Editar operación":
        let id = rowData[0];
        history.push(links.editOperation.to + "/" + id);
        break;
      default:
        break;
    }
  };

  // const handleSubmit = (rowData, item) => {

  // }

  return (
    <div>
      {tableData.length > 0 ? (
        <DataTable
          data={tableData}
          handleSubmit={handleSubmit}
          columnName="Editar"
          buttons={[{ name: "Editar operación", class: "info", icon: "edit" }]}
        >
          <h1 className="title is-4"> Operaciones</h1>
          <ButtonContainer>
            <button
              className="button is-info"
              onClick={() => history.push(links.createOperation.to)}
            >
              Crear operación
            </button>
          </ButtonContainer>
        </DataTable>
      ) : (
        <Empty />
      )}
    </div>
  );
}

export default WithContextData(Operations);
