import {
  isEmpty,
  isOnlyNumbers,
  isOnlyNumbersDecimal,
  isProjectName,
  isRev,
} from ".";

export const WorkOrderValidator = {
  code: (data) => isEmpty(data.trim()),
  rev: (data) => isRev(data.trim()),
};

export const PieceValidator = {
  code: (data) => isEmpty(data.trim()),
  description: (data) => isEmpty(data.trim()),
  quantity: (data) => isOnlyNumbers(data),
  thickness: (data) => isOnlyNumbersDecimal(data),
  width: (data) => isOnlyNumbersDecimal(data),
  height: (data) => isOnlyNumbersDecimal(data),
  pieceMaterial: (data) => isEmpty(data.trim()),
  pieceMaterialKind: (data) => isEmpty(data.trim()),
  metallicFinish: (data) => isEmpty(data.trim()),
};

export const ProjectValidator = {
  projectCode: (data) => isProjectName(data.trim()),
  clientCode: (data) => isEmpty(data.trim()),
  title: (data) => isEmpty(data.trim()),
  description: (data) => isEmpty(data.trim()),
};

export const OperationValidator = {
  title: (data) => isEmpty(data.trim()),
  description: (data) => isEmpty(data.trim()),
};
