import * as urlConstants from "./helpers/urlConstants";
import { fetchMethod } from "./helpers/fetchMethod";
import { DELETE, POST } from "./helpers/serviceConstants";

export const CreateOperationApi = async (data) => {
  return await fetchMethod(urlConstants.CreateOperation, POST, data);
};

export const UpdateOperationApi = async (data) => {
  return await fetchMethod(urlConstants.UpdateOperation, POST, data);
};

export const DeleteOperationApi = async (id) => {
  return await fetchMethod(urlConstants.DeleteOperation + id, DELETE);
};

export const GetOperationApi = async (id) => {
  return await fetchMethod(urlConstants.GetOperation + id);
};

export const GetOperationsApi = async () => {
  return await fetchMethod(urlConstants.GetOperations);
};
