import React from "react";
import { isDesigner, isProjectManager } from "../../Helpers";
import * as roles from "../../resources/constants/roles";

function ButtonsDashboard({ role, onClick }) {
  return (
    <div className="is-flex is-flex-direction-row-reverse">
      {isProjectManager(role) || isDesigner(role) || role === roles.Planning ? (
        <>
          <button
            name="pieces"
            className="button is-info ml-1 is-small"
            onClick={onClick}
          >
            Ver piezas
          </button>
          <button
            name="workOrders"
            className="button has-background-primary-dark ml-1 is-small"
            onClick={onClick}
          >
            Ver órdenes de trabajo
          </button>
        </>
      ) : null}
    </div>
  );
}

export default ButtonsDashboard;
