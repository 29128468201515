import React from "react";

function ButtonContainer(props) {
  return (
    <div className="mb-2 buttonContainer">
      <div>{props.children}</div>
    </div>
  );
}

export default ButtonContainer;
