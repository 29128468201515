import * as routeNames from "../../resources/constants/routeNames";

export const TraceableObject = {
  tag: "",
  workOrderId: "",
  amount: 0,
};

export const buildTraceable = (traceable) => ({
  tag: traceable.tag,
  workOrderId: traceable.workOrderId,
  amount: traceable.amount,
});

export const buildTraceableForTableQuality = (traceable) => ({
  Id: traceable?.tag ?? "",
  "Orden de trabajo": traceable?.workOrderId ?? "",
  Cantidad: traceable?.amount ?? "",
  Espesor: traceable?.workOrder?.piece?.thickness ?? "",
  Ancho: traceable?.workOrder?.piece?.width ?? "",
  Largo: traceable?.workOrder?.piece?.height ?? "",
  Material: traceable?.workOrder?.piece?.pieceMaterial ?? "",
  "Tipo de material": traceable?.workOrder?.piece?.pieceMaterialKind ?? "",
  Ruta: traceable.isRejected ? 10 : routeValues(traceable?.route?.title ?? ""),
});

export const buildTraceableForTablePlanning = (traceable) => ({
  Id: traceable?.tag ?? "",
  "Orden de trabajo": traceable?.workOrderId ?? "",
  Cantidad: traceable?.amount ?? "",
  Espesor: traceable?.workOrder?.piece?.thickness ?? "",
  Ancho: traceable?.workOrder?.piece?.width ?? "",
  Largo: traceable?.workOrder?.piece?.height ?? "",
  Material: traceable?.workOrder?.piece?.pieceMaterial ?? "",
  "Tipo de material": traceable?.workOrder?.piece?.pieceMaterialKind ?? "",
  Acabado: traceable?.workOrder?.piece?.metallicFinish ?? "",
  Ruta: traceable.isRejected ? 10 : routeValues(traceable?.route?.title ?? ""),
});

export const buildTraceableForMetrology = (traceable) => ({
  Id: traceable?.tag ?? "",
  "Orden de trabajo": traceable?.workOrderId ?? "",
  Cantidad: traceable?.amount ?? "",
  Espesor: traceable?.workOrder?.piece?.thickness ?? "",
  Ancho: traceable?.workOrder?.piece?.width ?? "",
  Largo: traceable?.workOrder?.piece?.height ?? "",
  Material: traceable?.workOrder?.piece?.pieceMaterial ?? "",
  "Tipo de material": traceable?.workOrder?.piece?.pieceMaterialKind ?? "",
});

export const buildTracebleForTable = (traceable) => ({
  Id: traceable.tag,
  "Orden de trabajo": traceable.workOrderId,
  Cantidad: traceable.amount,
  Ruta: routeValues(traceable.route.title),
});

export const buildTracebleForTableForDesign = (traceable) => ({
  Id: traceable.tag,
  "Orden de trabajo": traceable.workOrderId,
  Cantidad: traceable.amount,
  Ruta: routeValues(
    traceable.route.title === routeNames.QualityNotPass
      ? traceable.route?.title
      : traceable.route?.title === routeNames.LackInformation
      ? traceable.route?.title
      : traceable.route?.title === routeNames.AuxiliarNotPass
      ? traceable.route?.title
      : traceable.route?.title === routeNames.OrderNoCompleted
      ? traceable.route?.title
      : "Orden cancelada"
  ),
});

export const sortingTraceable = {
  "Orden de trabajo": ["workOrderIdAsc", "workOrderIdDesc"],
  Cantidad: ["amountAsc", "amountDesc"],
  Ancho: ["widthAsc", "widthDesc"],
  Largo: ["heightAsc", "heightDesc"],
  Espesor: ["thicknessAsc", "thicknessDesc"],
  Material: ["materialAsc", "materialDesc"],
  "Tipo de material": ["materialKindAsc", "materialKindDesc"],
  Ruta: ["routeAsc", "routeDesc"],
};

const routeValues = (title) => {
  switch (title) {
    case routeNames.Complete:
      return 0;
    case routeNames.QualityNotPass:
      return 1;
    case routeNames.MetrologyNotPass:
      return 2;
    case routeNames.Finished:
      return 3;
    case routeNames.ExternalMachining:
      return 4;
    case routeNames.Production:
      return 5;
    case routeNames.Treatment:
      return 6;
    case "Orden cancelada":
      return 8;
    case routeNames.LackInformation:
      return 9;
    case routeNames.AuxiliarNotPass:
      return 10;
    case routeNames.OrderNoCompleted:
      return 11;
    default:
      return 7;
  }
};

export const routeStates = [
  { name: "Completa", class: "primary" },
  { name: "Fallo calidad", class: "danger" },
  { name: "Fallo metrología", class: "danger" },
  { name: "Pasó metrología", class: "link" },
  { name: "Producción externa", class: "warning" },
  { name: "Producción", class: "dark" },
  { name: "Tratamiento", class: "warning" },
  { name: "Error", class: "danger" },
  { name: "Orden cancelada", class: "dark" },
  { name: "Falta información", class: "warning" },
  { name: "Rechazo de auxiliar", class: "danger" },
  { name: "Orden incompleta", class: "warning" },
];

export const routeStateForAux = [
  { name: "A producción", class: "primary" },
  { name: "Fallo calidad", class: "danger" },
  { name: "Fallo metrología", class: "danger" },
  { name: "Pasó metrología", class: "link" },
  { name: "De externo", class: "warning" },
  { name: "De producción", class: "dark" },
  { name: "Tratamiento", class: "warning" },
  { name: "Error", class: "danger" },
  { name: "Orden cancelada", class: "dark" },
  { name: "Plano actualizado", class: "link" },
  { name: "Rechazo de auxiliar", class: "danger" },
  { name: "Orden incompleta", class: "warning" },
];

export const receiveSendObject = {
  tag: "",
  code: "",
  rev: "",
  projectId: "",
  quantity: 0,
};

export const buildReceiveSendObject = (traceable, projectName) => ({
  tag: traceable.tag,
  code: traceable.workOrderId,
  rev: traceable.workOrder.rev,
  projectId: projectName,
  quantity: traceable.amount,
});
