/* #region  IMPORTS */
import React, { useEffect } from "react";
import { GetUsersApi } from "../../../Services/SuperUserService";
import DataTable from "../../2-systems/s_DataTable";
import {
  buildUserForTable,
  sortingUser,
} from "../../../Helpers/Factory/userFactory";
import { useHistory } from "react-router-dom";
import { refresh_route } from "../../../redux/ducks/route";
import { links } from "../../../resources/constants/links";
import routes from "../../../resources/constants/routes";
import WithTablesData from "../../HOC/WithTableData";
import ButtonIcon from "../../1-blocks/b_ButtonIcon";
/* #endregion */

function Users({
  tableContent,
  onSort,
  pageCount,
  onChangeQuery,
  dispatch,
  count,
}) {
  const history = useHistory();

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    dispatch(refresh_route(routes[links.users.name]));
  }, []);
  /* #endregion */

  /* #region  HANDLE EDIT */
  const handleSubmit = (rowData, item) => {
    let id = rowData[0];
    switch (item) {
      case "Editar rol":
        history.push(links.editUser.to + "/" + id);
        break;
      default:
        break;
    }
  };
  /* #endregion */

  return (
    <div className="is-flex is-justify-content-center">
      <DataTable
        data={tableContent}
        count={count}
        onClick={handleSubmit}
        hasCheckbox={false}
        visibleColumn={false}
        onChangeQuery={onChangeQuery}
        onSort={onSort}
        pageCount={pageCount}
        buttons={[{ name: "Editar rol", class: "info", icon: "edit" }]}
      >
        <div>
          <div className="is-flex is-justify-content-center">
            <h1 className="title is-4"> Usuarios</h1>
          </div>
          <div className="is-flex is-justify-content-flex-end mb-4">
            <ButtonIcon
              icon="user"
              name="Crear usuario"
              onClick={() => history.push(links.createUser.to)}
              className="is-info"
            />
          </div>
        </div>
      </DataTable>
    </div>
  );
}

export default WithTablesData(
  Users,
  GetUsersApi,
  buildUserForTable,
  sortingUser
);
