import { emptyField } from "../../resources/constants/messages";
import { inputIconFactory } from "../Factory/inputIconFactory";

//SELECT
//  Proyectos
export const UpdateRoleForm = {
  userId: inputIconFactory(
    "userId",
    "Id",
    emptyField,
    "barcode",
    false,
    false,
    false,
    true
  ),
  email: inputIconFactory(
    "email",
    "Email",
    emptyField,
    "at",
    false,
    false,
    false,
    true
  ),
  actualRole: inputIconFactory(
    "actualRole",
    "Rol actual",
    emptyField,
    "user-cog",
    false,
    false,
    false,
    true
  ),
};

export const UpdateRoleButton = {
  name: "Enviar",
  icon: "paper-plane",
};
