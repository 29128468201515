import { workOrderStatus } from "../../Helpers/Factory/workOrderFactory";
import { OK } from "../../resources/constants/messages";
import { TripleMoveApi } from "../../Services/MoveTraceableService";
import {
  GetFileApi,
  UpdateWorkOrderFileApi,
} from "../../Services/WorkOrderFileService";
import { ChangeWorkOrderStatusFromTraceableApi } from "../../Services/WorkOrderService";
import { apiFail, apiRequest, apiSuccess } from "../ducks/apiData";
import { buildZamtestTraceable } from "./moveTraceable.mid";

export const GetFileMid = (workOrderCode) => async (dispatch) => {
  dispatch(apiRequest());
  await GetFileApi(workOrderCode);
  dispatch(apiSuccess());
};

export const UpdateFileMid = (formData, sendObject) => async (dispatch) => {
  dispatch(apiRequest());
  let result = await UpdateWorkOrderFileApi(formData);
  if (result.status === OK) {
    result = await TripleMoveApi(sendObject);
    if (result.status === OK) {
      const newStatus = buildZamtestTraceable(
        result.data,
        workOrderStatus.created
      );
      result = await ChangeWorkOrderStatusFromTraceableApi(newStatus);
      if (result.status === OK) {
        dispatch(apiSuccess({ postConfirmation: true }));
      } else dispatch(apiFail({ error: result.error, status: result.status }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};
