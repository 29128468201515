export const inputIconFactory = (
  name,
  placeholder,
  errorMessage,
  icon,
  isTextArea = false,
  number = false,
  decimals = true,
  disabled = false,
  isValid = null
) => ({
  name: name,
  placeholder: placeholder,
  errorMessage: errorMessage,
  icon: icon,
  type: number ? "number" : "text",
  decimals: decimals,
  isValid: isValid,
  disabled: disabled,
  isTextArea: isTextArea,
});
