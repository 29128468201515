const MODIFY_ROUTE = "MODIFY_ROUTE";

export const refresh_route = (data = "", link = "") => {
  if (link !== "") {
    let lastElement = data.pop();
    lastElement[1] = link;
    data.push(lastElement);
  }
  return {
    type: MODIFY_ROUTE,
    payload: data,
  };
};

const routeReducer = (state = [["", "/"]], action) => {
  switch (action.type) {
    case MODIFY_ROUTE:
      if (action.payload.length === 1) {
        return action.payload;
      } else {
        let st = state[0];
        let act = action.payload[0];
        if (st !== undefined && act !== undefined) {
          if (st[0] === act[0]) {
            state.forEach((item) => {
              action.payload.forEach((i) => {
                if (i[0] === item[0]) i[1] = item[1];
              });
            });
          }
          return action.payload;
        }
      }
      return state;

    default:
      return state;
  }
};

export default routeReducer;
