import React from "react";
import { workOrderStates } from "../../Helpers/Factory/workOrderFactory";
function TitleList({ data }) {
  return (
    <div className="m-0">
      {workOrderStates.map((item, index) => (
        <div className="columns my-1 py-0" key={index}>
          <div className="column my-0 py-0 is-2">
            <label className={`label mr-3 has-text-${item.class}`}>
              {Object.values(data)[index]}
            </label>
          </div>
          <div className="column my-0 py-0">
            <label className={`label has-text-${item.class}`}>
              {item.name}
            </label>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TitleList;
