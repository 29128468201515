import React, { useEffect, useRef, useState } from "react";
import WithContextData from "../HOC/WithContextData";
import { ToastObject } from "../../Helpers/Factory/swalFactory";
import { useParams } from "react-router";
import ReceiveSendData from "../2-systems/s_ReceiveSendData";
import { apiSuccess } from "../../redux/ducks/apiData";
import { receiveSendObject } from "../../Helpers/Factory/traceableFactory";
import { GetTraceableByWorkOrderIdMid } from "../../redux/middleware/traceables.mid";
import { GetInitDataForReceivePiecesByPlace } from "../../Helpers/Factory/ReceivePiecesFactory";
import { quantityError } from "../../resources/constants/messages";
import { ReceivePartPiecesMid } from "../../redux/middleware/moveTraceable.mid";

function ReceivePartPieces({
  apiData,
  dispatch,
  refresh_route,
  _routes,
  links,
  helpers,
}) {
  const modal = useRef();
  const { place } = useParams();
  const [searchFlag, setSearchFlag] = useState(false);
  const [data, setData] = useState(receiveSendObject);
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState();

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    dispatch(apiSuccess());
    dispatch(
      refresh_route(
        _routes[links.receivePartPieces.name],
        `/receivePartsTraceables/${place}`
      )
    );
    let result = GetInitDataForReceivePiecesByPlace(place);
    setTitle(result.title);
    setStatus(result.workOrderStatus);
  }, [place]);
  /* #endregion */

  /* #region  CONFIRMACIÓN DE CREAR */
  useEffect(() => {
    if (apiData.data?.received) {
      helpers.Toast.fire(
        ToastObject("Se han recibido con éxito las piezas", "success")
      );
    }
  }, [apiData.data]);
  /* #endregion */

  useEffect(() => {
    if (!apiData.loading && apiData.ok) {
      if (apiData.data) {
        setData(apiData.data);
      } else {
        if (searchFlag) {
          helpers.Toast.fire(
            ToastObject(
              "No se logró encontrar información sobre la pieza",
              "info"
            )
          );
          setSearchFlag(false);
        }
      }
    }
  }, [apiData.data]);

  const onSend = async (value) => {
    if (value) {
      if (parseInt(value) > data.quantity) {
        helpers.Toast.fire(ToastObject(quantityError, "warning"));
      } else {
        let sendObject = {
          ZamtestTraceableTag: data.tag,
          QuantityToMove: parseInt(value),
        };
        dispatch(ReceivePartPiecesMid(sendObject, status));
        modal.current.onClean();
      }
    }
  };

  const onSearch = async (value) => {
    if (value) {
      setSearchFlag(true);
      dispatch(GetTraceableByWorkOrderIdMid(place, value));
    }
  };

  return (
    <div>
      <h1 className="title is-4 is-flex is-justify-content-center">{title}</h1>
      <ReceiveSendData
        ref={modal}
        onSend={onSend}
        onSearch={onSearch}
        data={data}
        setData={setData}
      />
    </div>
  );
}

export default WithContextData(ReceivePartPieces);
