import * as urlConstants from "./helpers/urlConstants";
import { fetchMethod } from "./helpers/fetchMethod";
import { POST } from "./helpers/serviceConstants";

export const LoginApi = async (data) =>
  await fetchMethod(urlConstants.Login, POST, data, false);

// This is the method to register users with roles
export const RegisterAdminApi = async (data) =>
  await fetchMethod(urlConstants.RegisterAdmin, POST, data);

export const GetAllUsersApi = async () =>
  await fetchMethod(urlConstants.GetAllUsers);

export const ChangePasswordApi = async (data) =>
  await fetchMethod(urlConstants.ChangePassword, POST, data);

export const ForgotPasswordApi = async (email) =>
  await fetchMethod(urlConstants.ForgotPassword + email, POST);

/*
  {
    code: "",
    userId: "",
    newPassword: ""
  }
  */
export const ResetPasswordApi = async (data) =>
  await fetchMethod(urlConstants.ResetPassword, POST, data);
