/* #region  IMPORTS */
import { buildPieceForTable } from "../../Helpers/Factory/pieceFactory";
import { BAD_REQUEST, OK } from "../../resources/constants/messages";
import { GetOperationsApi } from "../../Services/OperationService";
import {
  CreatePieceApi,
  GetPiecesByProjectApi,
  GetPiecesFileByProjectApi,
  GetPieceApi,
} from "../../Services/PiecesService";
import { apiFail, apiRequest, apiSuccess } from "../ducks/apiData";
import { CreateData } from "./httpMethodApi";
/* #endregion */

export const GetPiecesMid = (projectId) => async (dispatch) => {
  dispatch(apiRequest());
  var result = await GetPiecesByProjectApi(projectId);
  if (result.status === OK) {
    dispatch(
      apiSuccess({ piecesTable: CreateData(result.data, buildPieceForTable) })
    );
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const CreatePiecesMid = (pieces) => async (dispatch) => {
  dispatch(apiRequest());
  let result = await GetOperationsApi();
  if (result.status === OK) {
    const operations = result.data;
    let errors = [];
    let total,
      ok = 0;
    await Promise.all(
      pieces.map(async (piece) => {
        let oper = [];
        if (piece.cnc === "SI") oper.push(operations[0].id);
        if (piece.corte === "SI") oper.push(operations[1].id);
        if (piece.soldadura === "SI") oper.push(operations[2].id);
        if (piece.dobles === "SI") oper.push(operations[3].id);
        piece.code = piece.workOrderName.split(" ")[1];
        delete piece.workOrderName;
        delete piece.id;
        delete piece.cnc;
        delete piece.corte;
        delete piece.soldadura;
        delete piece.dobles;
        let piecePrimitive = {
          piece: piece,
          operationIds: oper,
        };

        result = await CreatePieceApi(piecePrimitive);
        if (result.status !== OK) errors.push(piece.code);
        else {
          ok += 1;
        }
      })
    );
    total = ok + errors.length;
    if (errors.length === 0)
      dispatch(
        apiSuccess({
          piecesCreated: "Se crearon con éxito todas las piezas",
        })
      );
    else if (errors.length > 0 && ok > 1)
      dispatch(
        apiSuccess({
          piecesCreated: `No se crearon las piezas [${errors}] de ${total} piezas totales`,
        })
      );
    else dispatch(apiFail({ error: result.error, status: result.status }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const GetFilePiece = (projectId) => async (dispatch) => {
  dispatch(apiRequest());
  const result = await GetPiecesFileByProjectApi(projectId);
  if (result.data === BAD_REQUEST) {
    dispatch(apiFail({ error: result.error, status: result.status }));
    return;
  }

  dispatch(apiSuccess());
};

export const GetEditPiece = (pieceId) => async (dispatch) => {
  dispatch(apiRequest());
  let piece = {};
  let result = await GetPieceApi(pieceId);
  if (result.status === OK) {
    piece = result.data;
    result = await GetOperationsApi(pieceId);
    if (result.status === OK) {
      dispatch(apiSuccess({ piece, operations: result.data }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};
