import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

function Pagination({ pageCount, paginationMethod }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesForPagination, setPagesForPagination] = useState([]);
  const [auxList, setAuxList] = useState([]);
  const [middleList, setMiddleList] = useState([]);
  const [bigPagination, setBigPagination] = useState(false);
  const [firstLastPage, setFirstLastPage] = useState({
    first: 0,
    last: 0,
  });
  useEffect(() => {
    let pages = [];
    for (let i = 1; i <= pageCount; i++) {
      pages.push(i);
    }
    setPagesForPagination(pages);
  }, [pageCount]);

  useEffect(() => {
    if (pagesForPagination.length > 8) {
      setBigPagination(true);
      let firstPage = pagesForPagination[0];
      let lastPage = pagesForPagination[pagesForPagination.length - 1];
      let aux = pagesForPagination.filter(
        (i) => i !== firstPage && i !== lastPage
      );
      let value = aux[1];
      let secondAux = getMiddleList(value, aux);
      setMiddleList(secondAux);
      setAuxList(aux);
      setFirstLastPage({
        first: firstPage,
        last: lastPage,
      });
    } else {
      setBigPagination(false);
    }
  }, [pagesForPagination]);

  const getMiddleList = (value, list) => {
    let index = list.indexOf(value);
    let middleItem = list[index];
    let lastItem = list[index + 1];
    let firstItem = list[index - 1];
    if (!firstItem) {
      firstItem = middleItem;
      middleItem = lastItem;
      lastItem = list[index + 2];
    } else if (!lastItem) {
      lastItem = middleItem;
      middleItem = firstItem;
      firstItem = list[index - 2];
    }
    return [firstItem, middleItem, lastItem];
  };

  const changeClasses = (name) => {
    let prevButton = document.getElementsByClassName("is-current")[0];
    // eslint-disable-next-line no-unused-expressions
    prevButton?.classList.remove("is-current");
    let currentButton = document.getElementById(name);
    // eslint-disable-next-line no-unused-expressions
    currentButton?.classList.add("is-current");
  };

  useEffect(() => {
    changeClasses(currentPage);
  }, [currentPage]);

  const onBigPaginationClick = async (e) => {
    setMiddleList(getMiddleList(parseInt(e.target.name), auxList));
    setCurrentPage(parseInt(e.target.name));
    paginationMethod(e.target.name);
  };

  const numberButtonClickParent = (e) => {
    setCurrentPage(parseInt(e.target.name));
    paginationMethod(e.target.name);
  };

  const paginationMethodParent = (e) => {
    let button = e.target.name;
    switch (button) {
      case "prev":
        paginationMethod(currentPage - 1);
        if (bigPagination && currentPage - 1 > 1)
          setMiddleList(getMiddleList(currentPage - 1, auxList));
        setCurrentPage(currentPage - 1);
        break;
      case "next":
        paginationMethod(currentPage + 1);
        if (bigPagination && currentPage + 1 < pageCount)
          setMiddleList(getMiddleList(currentPage + 1, auxList));
        setCurrentPage(currentPage + 1);
        break;
      default:
        break;
    }
  };

  const pagination = () => {
    if (bigPagination) {
      return (
        <ul className="pagination-list">
          <li>
            <button
              className="pagination-link is-current"
              onClick={numberButtonClickParent}
              name={firstLastPage.first}
              id={firstLastPage.first}
            >
              {firstLastPage.first}
            </button>
          </li>
          <li>
            <span className="pagination-ellipsis">&hellip;</span>
          </li>

          {middleList.map((item, index) => (
            <li key={index * 78.45}>
              <button
                className={`pagination-link`}
                onClick={onBigPaginationClick}
                name={item}
                id={item}
              >
                {item}
              </button>
            </li>
          ))}

          <li>
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
          <li>
            <button
              className="pagination-link"
              onClick={numberButtonClickParent}
              name={firstLastPage.last}
              id={firstLastPage.last}
            >
              {firstLastPage.last}
            </button>
          </li>
        </ul>
      );
    } else {
      return (
        <>
          <ul className="pagination-list">
            {pagesForPagination.map((item, index) => (
              <li key={index}>
                <button
                  id={item}
                  name={item}
                  className={`pagination-link ${
                    currentPage === item ? "is-current" : ""
                  }`}
                  onClick={numberButtonClickParent}
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        </>
      );
    }
  };

  return (
    <nav
      className="pagination is-centered"
      role="navigation"
      aria-label="pagination"
    >
      <button
        name="prev"
        className="pagination-previous"
        disabled={currentPage === 1 ? true : false}
        onClick={paginationMethodParent}
      >
        Anterior
      </button>
      <button
        name="next"
        className="pagination-next"
        disabled={currentPage === pageCount ? true : false}
        onClick={paginationMethodParent}
      >
        Siguiente
      </button>
      {pagination()}
    </nav>
  );
}

// eslint-disable-next-line react/no-typos
Pagination.proptypes = {
  pageCount: PropTypes.number.isRequired,
  paginationMethod: PropTypes.func.isRequired,
};

export default Pagination;
