import * as places from "../../resources/constants/places";
import Swal from "sweetalert2";
import { AlertObject } from "./swalFactory";
import { danger, primary } from "./recordFactory";
import { workOrderStatus } from "./workOrderFactory";

const mainButton = [{ name: "Recibir", class: "link", icon: "box-open" }];

export const GetInitDataForReceivePiecesByPlace = (place) => {
  switch (place) {
    case "Design-1":
      return {
        title: "Órdenes rechazadas",
        buttons: [
          { name: "Actualizar PDF", class: "info", icon: "redo" },
          { name: "Descargar PDF", class: "dark", icon: "file-pdf" },
        ],
      };
    case "Design-2":
      return {
        title: "Actualizar planos para producción",
        buttons: [
          { name: "Subir PDF", class: "info", icon: "redo" },
          { name: "Descargar PDF", class: "dark", icon: "file-pdf" },
        ],
      };
    case places.Auxiliar:
      return {
        title: "Recibir piezas rechazadas",
        workOrderStatus: workOrderStatus.inProduction,
        buttons: { mainButton },
      };
    case places.Quality:
      return {
        title: "Enviar piezas a calidad",
        workOrderStatus: workOrderStatus.inProduction,
        buttons: [
          { name: "Enviar", class: "primary", icon: "share-square" },
          { name: "Detener orden", class: "warning", icon: "stop" },
          { name: "Rechazar", class: "danger", icon: "times" },
          { name: "Descargar PDF", class: "dark", icon: "file-pdf" },
        ],
      };
    case places.Finished:
      return {
        title: "Recibir piezas de acabados",
        workOrderStatus: workOrderStatus.treatment,
        buttons: { mainButton },
      };

    case places.Metrology:
      return {
        title: "Recibir piezas de producción",
        workOrderStatus: workOrderStatus.metrology,
        buttons: { mainButton },
      };

    case places.Planning:
      return {
        title: "Recibir piezas de metrología",
        workOrderStatus: workOrderStatus.Manufactured,
        buttons: { mainButton },
      };

    case places.Completed:
      return {
        title: "Recibir piezas a almacén",
        workOrderStatus: workOrderStatus.finishOk,
        buttons: { mainButton },
      };
    default:
      return { title: "Recibir piezas" };
  }
};

export const swalReceive = async (code) => {
  let result = await Swal.fire(
    AlertObject(
      {
        title: `Se recibirá el producto ${code}`,
        text: "No serás capaz de revertir esta accón",
      },
      "info",
      primary,
      danger,
      "Recibir"
    )
  );
  return result.isConfirmed;
};
