import { buildNormalDate } from "..";

export const WorkOrderObject = {
  code: "",
  rev: "",
  datePost: buildNormalDate(new Date()),
  creatorId: "",
  projectId: "",
  pieceId: 0,
  status: 0,
};

export const buildWorkOrderForTable = (workOrder) => ({
  Código: workOrder.code,
  Cantidad: workOrder.piece?.quantity,
  Espesor: workOrder.piece?.thickness,
  Ancho: workOrder.piece?.width,
  Altura: workOrder.piece?.height,
  Estado: workOrder.status,
});

export const sortingWorkOrder = {
  Código: ["idAsc", "idDesc"],
  Cantidad: ["quantityAsc", "quantityDesc"],
  Espesor: ["bnameAsc", "bnameDesc"],
  Ancho: ["addressAsc", "addressDesc"],
  Altura: ["enableAsc", "enableDesc"],
  Estado: ["statusAsc", "statusDesc"],
};

export const workOrderStates = [
  { name: "Creado", class: "info" },
  { name: "Cancelado", class: "danger" },
  { name: "En producción", class: "dark" },
  { name: "Metrología", class: "warning" },
  { name: "Liberado", class: "link" },
  { name: "Terminado", class: "primary" },
  { name: "Tratamiento", class: "warning" },
  { name: "Pendiente", class: "dark" },
  { name: "Obsoleto", class: "danger" },
  { name: "Incompleto", class: "warning" },
  { name: "Planeación", class: "link" },
  { name: "MaqZam", class: "success" },
];

export const workOrderStatus = {
  created: 0,
  cancelled: 1,
  inProduction: 2,
  metrology: 3,
  Manufactured: 4,
  finishOk: 5,
  treatment: 6,
  pendant: 7,
  obsolete: 8,
  incomplete: 9,
  planning: 10,
  maqZam: 11,
};

export const buildWorkOrder = (workOrder) => ({
  code: workOrder.code,
  rev: workOrder.rev,
  quantity: workOrder.piece.quantity,
  thickness: workOrder.piece.thickness,
  width: workOrder.piece.width,
  height: workOrder.piece.height,
  pieceMaterial: workOrder.piece.pieceMaterial,
  pieceMaterialKind: workOrder.piece.pieceMaterialKind,
  metallicFinish: workOrder.piece.metallicFinish,
  operations: [],
  piece: workOrder.piece.description,
  status: workOrder.status,
  productionTime: workOrder.productionTime,
});
