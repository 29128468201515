import React, { useEffect, useState } from "react";
import WithContextData from "../../HOC/WithContextData";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import { buildUserSelect } from "../../../Helpers/Factory/selectFactory";
import ProjectForm from "../../5-forms/projectForm";
import { GetProjectForEdit } from "../../../redux/middleware/projects.mid";
import { useParams } from "react-router-dom";
import { buildNormalDate, Toast } from "../../../Helpers";
import CheckBox from "../../0-atoms/a_checkBox";
import { PostApi } from "../../../redux/middleware/httpMethodApi";
import { UpdateProjectApi } from "../../../Services/ProjectService";

function EditProject({
  apiData,
  dispatch,
  refresh_route,
  _routes,
  links,
  history,
}) {
  const [managers, setManagers] = useState([]);
  const [form, setForm] = useState();
  const { projectId } = useParams();
  const [checked, setChecked] = useState();

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    if (projectId) {
      dispatch(refresh_route(_routes[links.createProject.name]));
      dispatch(GetProjectForEdit(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    if (apiData.data?.managers) {
      let users = apiData.data.managers.map((item, index) =>
        buildUserSelect(item, index)
      );
      setManagers(users);
      setForm(apiData.data.project);
      setChecked(apiData.data.project.closeDate ? true : false);
    }
  }, [apiData.data]);
  /* #endregion */

  /* #region  CONFIRMACIÓN DE CREAR */
  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      Toast.fire(ToastObject("Se ha editado con éxito tu proyecto", "success"));
      history.goBack();
    }
  }, [apiData.data]);
  /* #endregion */

  const onChange = (value) => setChecked(value);

  const onSubmit = async (project) => {
    delete project.manager;
    delete project.designers;
    delete project.pieces;
    if (checked) {
      project.closeDate = buildNormalDate(new Date());
      project.status = 2;
    } else {
      project.closeDate = null;
      project.status = 0;
    }
    dispatch(PostApi([project], UpdateProjectApi));
  };

  if (form?.managerId) {
    return (
      <ProjectForm
        defaultValues={form}
        onSubmit={onSubmit}
        title="Editar proyecto"
        managers={managers}
      >
        <div className="is-flex is-flex-direction-row-reverse">
          <CheckBox
            content="Finalizar proyecto"
            onChange={onChange}
            defaultChecked={checked}
          />
        </div>
      </ProjectForm>
    );
  } else return null;
}

export default WithContextData(EditProject);
