import React from "react";

function ButtonIcon({ icon, name = "", className, onClick, ...props }) {
  return (
    <button className={`button ${className}`} onClick={onClick} {...props}>
      <span className="icon is-small">
        <i className={`fas fa-${icon}`}></i>
      </span>
      {name.length > 0 ? <span>{name}</span> : null}
    </button>
  );
}

export default ButtonIcon;
