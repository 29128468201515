/* eslint-disable no-undef */
import "./resources/main.scss";
import React, { useEffect } from "react";
import { Route } from "react-router";
import LoggedIn from "./components/3-layouts/l_LoggedIn";
import { Switch } from "react-router-dom";
import LoggedOut from "./components/3-layouts/l_LoggedOut";
import { links } from "./resources/constants/links";
import Home from "./components/4-pages/home";
import CreatePieces from "./components/4-pages/4-Design/CreatePieces";
import WorkOrders from "./components/4-pages/4-Design/WorkOrders";
import Pieces from "./components/4-pages/4-Design/Pieces";
import CreateProject from "./components/4-pages/2-ProjectManager/CreateProject";
import Projects from "./components/4-pages/2-ProjectManager/Projects";
import CreateOperations from "./components/4-pages/1-Root/CreateOperations";
import Operations from "./components/4-pages/1-Root/Operations";
import WorkOrder from "./components/4-pages/4-Design/WorkOrder";
import ReceivePieces from "./components/4-pages/ReceivePieces";
import SendPieces from "./components/4-pages/SendPieces";
import ReceivePartPieces from "./components/4-pages/ReceivePartPieces";
import GetTraceablesOnPlace from "./components/4-pages/GetTraceablesOnPlace";
import PendantWorkOrders from "./components/4-pages/4-Design/PendantWorkOrders";
import Piece from "./components/4-pages/4-Design/Piece";
import AdminDesigners from "./components/4-pages/3-DesignManager/AdminDesigners";
import StatisticalGraphs from "./components/4-pages/5-AdminProjectManager/StatisticalGraphs";
import UpdateUserRole from "./components/4-pages/1-Root/UpdateUserRole";
import Users from "./components/4-pages/1-Root/Users";
import EditPiece from "./components/4-pages/4-Design/EditPiece";
import EditProject from "./components/4-pages/2-ProjectManager/EditProject";
import Project from "./components/4-pages/2-ProjectManager/Project";
import LoginPage from "./components/4-pages/Login";
import ResetPassword from "./components/4-pages/ResetPassword";
import CreateUser from "./components/4-pages/1-Root/CreateUser/createUser";
import ChangePassword from "./components/4-pages/ChangePassword";
import ForgotPassword from "./components/4-pages/ForgotPassword";
import PlanningSendPieces from "./components/4-pages/6-Planning/PlanningSendPieces";
import AuxiliarSendPieces from "./components/4-pages/7-MaqZam/AuxiliarSendPieces";
import ProductionSendPieces from "./components/4-pages/7-MaqZam/ProductionSendPieces";

export default function App() {
  useEffect(() => {}, []);

  const verifyLocalStorageVersion = () => {
    const version = localStorage.getItem("V");
    if (!version) {
      localStorage.setItem("V", process.env.REACT_APP_LOCAL_STORAGE_VERSION);
      return;
    }

    if (version !== process.env.REACT_APP_LOCAL_STORAGE_VERSION) {
      console.info("Se ha cambiado la versión del local storage");
      localStorage.clear();
      localStorage.setItem("V", process.env.REACT_APP_LOCAL_STORAGE_VERSION);
      setTimeout(() => window.location.reload(), 1000);
    }
  };

  const getApiUrlFromEnv = () => {
    if (!process.env.REACT_APP_API_URL) {
      console.error("NO SE ENCUENTRA LA VARIABLE DE ENTORNO REACT_APP_API_URL");
      return;
    }

    const localStorageConstant = "zamtest-url";
    const url = localStorage.getItem(localStorageConstant);
    if (!url) {
      localStorage.setItem("zamtest-url", process.env.REACT_APP_API_URL);
      return;
    }

    if (url !== process.env.REACT_APP_API_URL)
      localStorage.setItem("zamtest-url", process.env.REACT_APP_API_URL);
  };

  useEffect(() => {
    window.process = {
      ...window.process,
    };

    verifyLocalStorageVersion();
    getApiUrlFromEnv();
  }, []);

  return (
    <Switch>
      <Route exact path="/">
        <LoggedIn>
          <Home />
        </LoggedIn>
      </Route>

      <Route exact path="/project">
        <LoggedIn>
          <Project />
        </LoggedIn>
      </Route>
      {/* PÁGINAS DE ROOT */}
      <Route exact path={`${links.createOperation.to}`}>
        <LoggedIn>
          <CreateOperations />
        </LoggedIn>
      </Route>

      <Route exact path={`${links.editOperation.to}/:operationId`}>
        <LoggedIn>
          <CreateOperations />
        </LoggedIn>
      </Route>

      <Route exact path={links.operations.to}>
        <LoggedIn>
          <Operations />
        </LoggedIn>
      </Route>

      <Route exact path={`${links.editUser.to}/:userId`}>
        <LoggedIn>
          <UpdateUserRole />
        </LoggedIn>
      </Route>

      <Route exact path={links.users.to}>
        <LoggedIn>
          <Users />
        </LoggedIn>
      </Route>

      {/* PÁGINAS DE ADMIN PROJECT MANAGER*/}
      <Route exact path={links.stats.to}>
        <LoggedIn>
          <StatisticalGraphs />
        </LoggedIn>
      </Route>

      {/* PÁGINAS DE PROJECT MANAGER*/}
      <Route exact path={links.createProject.to}>
        <LoggedIn>
          <CreateProject />
        </LoggedIn>
      </Route>

      <Route exact path={`${links.editProject.to}/:projectId`}>
        <LoggedIn>
          <EditProject />
        </LoggedIn>
      </Route>

      <Route exact path={links.projects.to}>
        <LoggedIn>
          <Projects />
        </LoggedIn>
      </Route>

      {/* PÁGINAS DE JEFE DE DISEÑADOR*/}
      <Route exact path={links.adminDesigners.to}>
        <LoggedIn>
          <AdminDesigners />
        </LoggedIn>
      </Route>

      {/* PÁGINAS DE DISEÑADOR */}
      <Route exact path={`${links.workOrder.to}/:woId`}>
        <LoggedIn>
          <WorkOrder />
        </LoggedIn>
      </Route>

      <Route exact path={`${links.workOrders.to}`}>
        <LoggedIn>
          <WorkOrders />
        </LoggedIn>
      </Route>

      <Route exact path={links.createWorkOrder.to}>
        <LoggedIn>
          <PendantWorkOrders />
        </LoggedIn>
      </Route>

      <Route exact path={`${links.piece.to}/:pieceId`}>
        <LoggedIn>
          <Piece />
        </LoggedIn>
      </Route>

      <Route exact path={links.pieces.to}>
        <LoggedIn>
          <Pieces />
        </LoggedIn>
      </Route>

      <Route exact path={links.createPiece.to}>
        <LoggedIn>
          <CreatePieces />
        </LoggedIn>
      </Route>

      <Route exact path={`${links.editPiece.to}/:pieceId`}>
        <LoggedIn>
          <EditPiece />
        </LoggedIn>
      </Route>

      <Route exact path={`${links.receivePieces.to}/:place`}>
        <LoggedIn>
          <ReceivePieces />
        </LoggedIn>
      </Route>

      <Route exact path={`${links.sendPieces.to}/:place`}>
        <LoggedIn>
          <SendPieces />
        </LoggedIn>
      </Route>

      <Route exact path={`${links.planningSendPieces.to}`}>
        <LoggedIn>
          <PlanningSendPieces />
        </LoggedIn>
      </Route>

      <Route exact path={`${links.auxiliarSendPieces.to}`}>
        <LoggedIn>
          <AuxiliarSendPieces />
        </LoggedIn>
      </Route>

      <Route exact path={`${links.productionSendPieces.to}`}>
        <LoggedIn>
          <ProductionSendPieces />
        </LoggedIn>
      </Route>

      <Route exact path={`${links.receivePartPieces.to}/:place`}>
        <LoggedIn>
          <ReceivePartPieces />
        </LoggedIn>
      </Route>

      <Route exact path={`${links.getPieces.to}/:place`}>
        <LoggedIn>
          <GetTraceablesOnPlace />
        </LoggedIn>
      </Route>

      <Route exact path={links.createUser.to}>
        <LoggedIn>
          <CreateUser />
        </LoggedIn>
      </Route>

      <Route exact path={links.changePassword.to}>
        <LoggedIn>
          <ChangePassword />
        </LoggedIn>
      </Route>

      <Route exact path={links.login.to}>
        <LoggedOut>
          <LoginPage />
        </LoggedOut>
      </Route>

      <Route exact path={links.forgotPassword.to}>
        <LoggedOut>
          <ForgotPassword />
        </LoggedOut>
      </Route>

      <Route exact path={links.resetPassword.to}>
        <LoggedOut>
          <ResetPassword />
        </LoggedOut>
      </Route>
    </Switch>
  );
}
