import * as urlConstants from "./helpers/urlConstants";
import { fetchMethod } from "./helpers/fetchMethod";
import { POST } from "./helpers/serviceConstants";
import setDataTableQuery from "./helpers/setDataTableQuery";

export const GetUsersByRoleApi = async (role) => {
  return await fetchMethod(urlConstants.GetUsersByRole + role);
};

export const GetRolesApi = async () => {
  return await fetchMethod(urlConstants.GetRoles);
};

export const GetUserApi = async (userId) => {
  return await fetchMethod(urlConstants.GetUser + userId);
};

export const GetUsersApi = async (query) => {
  let queryString = setDataTableQuery(query);
  return await fetchMethod(urlConstants.GetUsers + queryString);
};

export const UpdateUserRoleApi = async (data) => {
  return await fetchMethod(urlConstants.UpdateUserRole, POST, data);
};
