import { GetKey, Subscribe, UnSubscribe } from "./helpers/urlConstants";
import { fetchMethod } from "./helpers/fetchMethod";
import { GET, POST } from "./helpers/serviceConstants";

export const SubscribeApi = async (data) => {
  return await fetchMethod(Subscribe, POST, data);
};

export const GetPublicKeyApi = async () => {
  const result = await fetch(GetKey, {
    method: GET,
  });
  return await result.text();
};

export const UnsubscribeApi = async (data) => {
  return await fetchMethod(UnSubscribe, POST, data);
};
