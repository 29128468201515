import { links } from "./links";
import * as roles from "./roles";
import * as places from "./places";

export const menu = {
  [roles.Root]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: links.projects.name,
      link: links.projects.to,
      icon: "project-diagram",
    },
    {
      parent: {
        name: "Super usuario",
        childs: [
          {
            text: links.operations.name,
            link: links.operations.to,
            icon: "hdd",
          },
          {
            text: links.users.name,
            link: links.users.to,
            icon: "users",
          },
        ],
      },
    },
    {
      parent: {
        name: "Jefe gestor de proyectos",
        childs: [
          {
            text: links.stats.name,
            link: links.stats.to,
            icon: "chart-bar",
          },
        ],
      },
    },
    {
      parent: {
        name: "Jefe diseñador",
        childs: [
          {
            text: links.adminDesigners.name,
            link: links.adminDesigners.to,
            icon: "pen",
          },
          {
            text: links.sendPieces.name,
            link: `${links.sendPieces.to}/${places.Quality}`,
            icon: "share-square",
          },
        ],
      },
    },
    {
      parent: {
        name: "Diseñador",
        childs: [
          {
            text: links.pieces.name,
            link: links.pieces.to,
            icon: "puzzle-piece",
          },
          {
            text: links.workOrders.name,
            link: links.workOrders.to,
            icon: "network-wired",
          },
          {
            text: links.createWorkOrder.name,
            link: links.createWorkOrder.to,
            icon: "briefcase",
          },
          {
            text: "Órdenes rechazadas",
            link: `${links.receivePieces.to}/${places.Design}-1`,
            icon: "times",
          },
          {
            text: "Actualizar planos para producción",
            link: `${links.receivePieces.to}/${places.Design}-2`,
            icon: "exclamation-triangle",
          },
        ],
      },
    },
    {
      parent: {
        name: "Planeación",
        childs: [
          {
            text: "Recibir acabados",
            link: `${links.sendPieces.to}/${places.Finished}`,
            icon: "hands-helping",
          },
          {
            text: "Recibir piezas de metrología",
            link: `${links.receivePartPieces.to}/${places.Planning}`,
            icon: "ruler",
          },
          {
            text: links.sendPieces.name,
            link: `${links.planningSendPieces.to}`,
            icon: "share-square",
          },
        ],
      },
    },
    {
      parent: {
        name: "Auxiliar",
        childs: [
          {
            text: "Recibir piezas rechazadas",
            link: `${links.receivePartPieces.to}/${places.Auxiliar}`,
            icon: "times",
          },
          {
            text: links.auxiliarSendPieces.name,
            link: `${links.auxiliarSendPieces.to}`,
            icon: "toolbox",
          },
          {
            text: links.productionSendPieces.name,
            link: `${links.productionSendPieces.to}`,
            icon: "hands-helping",
          },
        ],
      },
    },
    {
      parent: {
        name: "Metrología",
        childs: [
          {
            text: links.receivePartPieces.name,
            link: `${links.receivePartPieces.to}/${places.Metrology}`,
            icon: "ruler",
          },
          {
            text: links.sendPieces.name,
            link: `${links.sendPieces.to}/${places.Metrology}`,
            icon: "share-square",
          },
        ],
      },
    },
    {
      parent: {
        name: "Almacén",
        childs: [
          {
            text: links.receivePartPieces.name,
            link: `${links.receivePartPieces.to}/${places.Completed}`,
            icon: "hands-helping",
          },
          {
            text: links.getPieces.name,
            link: `${links.getPieces.to}/${places.Completed}`,
            icon: "puzzle-piece",
          },
        ],
      },
    },
  ],
  [roles.Director]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: links.projects.name,
      link: links.projects.to,
      icon: "folder-open",
    },
    {
      parent: {
        name: "Control de proyecto",
        childs: [
          {
            text: links.pieces.name,
            link: links.pieces.to,
            icon: "puzzle-piece",
          },
          {
            text: links.stats.name,
            link: links.stats.to,
            icon: "chart-bar",
          },
          {
            parent: {
              name: "Órdenes de trabajo",
              childs: [
                {
                  text: links.workOrders.name,
                  link: links.workOrders.to,
                  icon: "network-wired",
                },
                {
                  text: "Órdenes rechazadas",
                  link: `${links.receivePieces.to}/${places.Design}-1`,
                  icon: "times",
                },
                {
                  text: "Actualizar planos para producción",
                  link: `${links.receivePieces.to}/${places.Design}-2`,
                  icon: "exclamation-triangle",
                },
              ],
            },
          },
        ],
      },
    },
  ],
  [roles.AdminProjectManager]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: links.projects.name,
      link: links.projects.to,
      icon: "folder-open",
    },
    {
      parent: {
        name: "Control de proyecto",
        childs: [
          {
            text: links.pieces.name,
            link: links.pieces.to,
            icon: "puzzle-piece",
          },
          {
            text: links.stats.name,
            link: links.stats.to,
            icon: "chart-bar",
          },
          {
            parent: {
              name: "Órdenes de trabajo",
              childs: [
                {
                  text: links.workOrders.name,
                  link: links.workOrders.to,
                  icon: "network-wired",
                },
                {
                  text: "Órdenes rechazadas",
                  link: `${links.receivePieces.to}/${places.Design}-1`,
                  icon: "times",
                },
                {
                  text: "Actualizar planos para producción",
                  link: `${links.receivePieces.to}/${places.Design}-2`,
                  icon: "exclamation-triangle",
                },
              ],
            },
          },
        ],
      },
    },
  ],
  [roles.ProjectManager]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: links.projects.name,
      link: links.projects.to,
      icon: "folder-open",
    },
    {
      parent: {
        name: "Control de proyecto",
        childs: [
          {
            text: links.pieces.name,
            link: links.pieces.to,
            icon: "puzzle-piece",
          },
          {
            parent: {
              name: "Órdenes de trabajo",
              childs: [
                {
                  text: links.workOrders.name,
                  link: links.workOrders.to,
                  icon: "network-wired",
                },
                {
                  text: "Órdenes rechazadas",
                  link: `${links.receivePieces.to}/${places.Design}-1`,
                  icon: "times",
                },
                {
                  text: "Actualizar planos para producción",
                  link: `${links.receivePieces.to}/${places.Design}-2`,
                  icon: "exclamation-triangle",
                },
              ],
            },
          },
        ],
      },
    },
  ],
  [roles.AdminDesign]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: links.adminDesigners.name,
      link: links.adminDesigners.to,
      icon: "pen",
    },
    {
      parent: {
        name: "Piezas",
        childs: [
          {
            text: links.pieces.name,
            link: links.pieces.to,
            icon: "puzzle-piece",
          },
          {
            text: "Nueva pieza",
            link: links.createPiece.to,
            icon: "puzzle-piece",
          },
        ],
      },
    },
    {
      parent: {
        name: "Órdenes de trabajo",
        childs: [
          {
            text: "Ver órdenes",
            link: links.workOrders.to,
            icon: "network-wired",
          },
          {
            text: "Crear orden de trabajo",
            link: links.createWorkOrder.to,
            icon: "briefcase",
          },
        ],
      },
    },
    {
      parent: {
        name: "Control",
        childs: [
          {
            text: "Validar planos",
            link: `${links.sendPieces.to}/${places.Quality}`,
            icon: "share-square",
          },
          {
            text: "Órdenes rechazadas",
            link: `${links.receivePieces.to}/${places.Design}-1`,
            icon: "times",
          },
          {
            text: "Actualizar planos",
            link: `${links.receivePieces.to}/${places.Design}-2`,
            icon: "exclamation-triangle",
          },
        ],
      },
    },
  ],
  [roles.Design]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      parent: {
        name: "Piezas",
        childs: [
          {
            text: links.pieces.name,
            link: links.pieces.to,
            icon: "puzzle-piece",
          },
          {
            text: "Nueva pieza",
            link: links.createPiece.to,
            icon: "puzzle-piece",
          },
        ],
      },
    },
    {
      parent: {
        name: "Órdenes de trabajo",
        childs: [
          {
            text: "Ver órdenes",
            link: links.workOrders.to,
            icon: "network-wired",
          },
          {
            text: "Crear orden de trabajo",
            link: links.createWorkOrder.to,
            icon: "briefcase",
          },
          {
            text: "Órdenes rechazadas",
            link: `${links.receivePieces.to}/${places.Design}-1`,
            icon: "times",
          },
          {
            text: "Actualizar planos",
            link: `${links.receivePieces.to}/${places.Design}-2`,
            icon: "exclamation-triangle",
          },
        ],
      },
    },
  ],
  [roles.Quality]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: "Validar planos",
      link: `${links.sendPieces.to}/${places.Quality}`,
      icon: "share-square",
    },
  ],
  [roles.AdminPlanning]: [
    {
      parent: {
        name: "Control de proyecto",
        childs: [
          {
            text: links.pieces.name,
            link: links.pieces.to,
            icon: "puzzle-piece",
          },
          {
            text: "Ver órdenes de trabajo",
            link: links.workOrders.to,
            icon: "network-wired",
          },
        ],
      },
    },
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: "Recibir acabados",
      link: `${links.sendPieces.to}/${places.Finished}`,
      icon: "hands-helping",
    },
    {
      text: "Recibir piezas de metrología",
      link: `${links.receivePartPieces.to}/${places.Planning}`,
      icon: "ruler",
    },
    {
      text: links.sendPieces.name,
      link: `${links.planningSendPieces.to}`,
      icon: "share-square",
    },
  ],
  [roles.Planning]: [
    {
      parent: {
        name: "Control de proyecto",
        childs: [
          {
            text: links.pieces.name,
            link: links.pieces.to,
            icon: "puzzle-piece",
          },
          {
            text: "Ver órdenes de trabajo",
            link: links.workOrders.to,
            icon: "network-wired",
          },
        ],
      },
    },
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: "Recibir acabados",
      link: `${links.sendPieces.to}/${places.Finished}`,
      icon: "hands-helping",
    },
    {
      text: "Recibir piezas de metrología",
      link: `${links.receivePartPieces.to}/${places.Planning}`,
      icon: "ruler",
    },
    {
      text: links.sendPieces.name,
      link: `${links.planningSendPieces.to}`,
      icon: "share-square",
    },
  ],
  [roles.Operations]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      parent: {
        name: "Operaciones",
        childs: [
          {
            text: "Recibir piezas rechazadas",
            link: `${links.receivePartPieces.to}/${places.Auxiliar}`,
            icon: "times",
          },
          {
            text: links.auxiliarSendPieces.name,
            link: `${links.auxiliarSendPieces.to}`,
            icon: "toolbox",
          },
          {
            text: links.productionSendPieces.name,
            link: `${links.productionSendPieces.to}`,
            icon: "hands-helping",
          },
        ],
      },
    },
  ],
  [roles.Metrology]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: links.receivePartPieces.name,
      link: `${links.receivePartPieces.to}/${places.Metrology}`,
      icon: "ruler",
    },
    {
      text: links.sendPieces.name,
      link: `${links.sendPieces.to}/${places.Metrology}`,
      icon: "share-square",
    },
  ],
  [roles.Warehouse]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: "Recibir piezas",
      link: `${links.receivePartPieces.to}/${places.Completed}`,
      icon: "hands-helping",
    },
    {
      text: "Almacén",
      link: `${links.getPieces.to}/${places.Completed}`,
      icon: "puzzle-piece",
    },
  ],
  default: [{ text: "Inicio", link: links.home.to, icon: "home" }],
};

export const menuWithoutProject = {
  [roles.Root]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: links.projects.name,
      link: links.projects.to,
      icon: "project-diagram",
    },
    {
      parent: {
        name: "Super user",
        childs: [
          {
            text: links.operations.name,
            link: links.operations.to,
            icon: "hdd",
          },
          {
            text: links.users.name,
            link: links.users.to,
            icon: "users",
          },
        ],
      },
    },
    {
      parent: {
        name: "Design manager",
        childs: [
          {
            text: links.sendPieces.name,
            link: `${links.sendPieces.to}/${places.Quality}`,
            icon: "share-square",
          },
        ],
      },
    },
    {
      parent: {
        name: "Planeación",
        childs: [
          {
            text: "Recibir acabados",
            link: `${links.sendPieces.to}/${places.Finished}`,
            icon: "hands-helping",
          },
          {
            text: "Recibir piezas de metrología",
            link: `${links.receivePartPieces.to}/${places.Planning}`,
            icon: "ruler",
          },
          {
            text: links.sendPieces.name,
            link: `${links.planningSendPieces.to}`,
            icon: "share-square",
          },
        ],
      },
    },
    {
      parent: {
        name: "Auxiliar",
        childs: [
          {
            text: "Recibir piezas rechazadas",
            link: `${links.receivePartPieces.to}/${places.Auxiliar}`,
            icon: "times",
          },
          {
            text: links.auxiliarSendPieces.name,
            link: `${links.auxiliarSendPieces.to}`,
            icon: "toolbox",
          },
          {
            text: links.productionSendPieces.name,
            link: `${links.productionSendPieces.to}`,
            icon: "hands-helping",
          },
        ],
      },
    },
    {
      parent: {
        name: "Metrología",
        childs: [
          {
            text: links.receivePartPieces.name,
            link: `${links.receivePartPieces.to}/${places.Metrology}`,
            icon: "ruler",
          },
          {
            text: links.sendPieces.name,
            link: `${links.sendPieces.to}/${places.Metrology}`,
            icon: "share-square",
          },
        ],
      },
    },
    {
      parent: {
        name: "Almacén",
        childs: [
          {
            text: links.receivePartPieces.name,
            link: `${links.receivePartPieces.to}/${places.Completed}`,
            icon: "hands-helping",
          },
          {
            text: links.getPieces.name,
            link: `${links.getPieces.to}/${places.Completed}`,
            icon: "puzzle-piece",
          },
        ],
      },
    },
  ],
  [roles.Director]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: links.stats.name,
      link: links.stats.to,
      icon: "chart-bar",
    },
    {
      parent: {
        name: "Órdenes de trabajo",
        childs: [
          {
            text: "Órdenes rechazadas",
            link: `${links.receivePieces.to}/${places.Design}-1`,
            icon: "times",
          },
          {
            text: "Actualizar planos para producción",
            link: `${links.receivePieces.to}/${places.Design}-2`,
            icon: "exclamation-triangle",
          },
        ],
      },
    },
  ],
  [roles.AdminProjectManager]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: links.stats.name,
      link: links.stats.to,
      icon: "chart-bar",
    },
    {
      parent: {
        name: "Órdenes de trabajo",
        childs: [
          {
            text: "Órdenes rechazadas",
            link: `${links.receivePieces.to}/${places.Design}-1`,
            icon: "times",
          },
          {
            text: "Actualizar planos para producción",
            link: `${links.receivePieces.to}/${places.Design}-2`,
            icon: "exclamation-triangle",
          },
        ],
      },
    },
  ],
  [roles.ProjectManager]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      parent: {
        name: "Órdenes de trabajo",
        childs: [
          {
            text: "Órdenes rechazadas",
            link: `${links.receivePieces.to}/${places.Design}-1`,
            icon: "times",
          },
          {
            text: "Actualizar planos para producción",
            link: `${links.receivePieces.to}/${places.Design}-2`,
            icon: "exclamation-triangle",
          },
        ],
      },
    },
  ],
  [roles.AdminDesign]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      parent: {
        name: "Control",
        childs: [
          {
            text: "Validar planos",
            link: `${links.sendPieces.to}/${places.Quality}`,
            icon: "share-square",
          },
          {
            text: "Órdenes rechazadas",
            link: `${links.receivePieces.to}/${places.Design}-1`,
            icon: "times",
          },
          {
            text: "Actualizar planos",
            link: `${links.receivePieces.to}/${places.Design}-2`,
            icon: "exclamation-triangle",
          },
        ],
      },
    },
  ],
  [roles.Design]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      parent: {
        name: "Órdenes de trabajo",
        childs: [
          {
            text: "Órdenes rechazadas",
            link: `${links.receivePieces.to}/${places.Design}-1`,
            icon: "times",
          },
          {
            text: "Actualizar planos",
            link: `${links.receivePieces.to}/${places.Design}-2`,
            icon: "exclamation-triangle",
          },
        ],
      },
    },
  ],
  [roles.Quality]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: "Validar planos",
      link: `${links.sendPieces.to}/${places.Quality}`,
      icon: "share-square",
    },
  ],
  [roles.AdminPlanning]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: "Recibir acabados",
      link: `${links.sendPieces.to}/${places.Finished}`,
      icon: "hands-helping",
    },
    {
      text: "Recibir piezas de metrología",
      link: `${links.receivePartPieces.to}/${places.Planning}`,
      icon: "ruler",
    },
    {
      text: links.sendPieces.name,
      link: `${links.planningSendPieces.to}`,
      icon: "share-square",
    },
  ],
  [roles.Planning]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: "Recibir acabados",
      link: `${links.sendPieces.to}/${places.Finished}`,
      icon: "hands-helping",
    },
    {
      text: "Recibir piezas de metrología",
      link: `${links.receivePartPieces.to}/${places.Planning}`,
      icon: "ruler",
    },
    {
      text: links.sendPieces.name,
      link: `${links.planningSendPieces.to}`,
      icon: "share-square",
    },
  ],
  [roles.Operations]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: "Recibir piezas rechazadas",
      link: `${links.receivePartPieces.to}/${places.Auxiliar}`,
      icon: "times",
    },
    {
      text: links.auxiliarSendPieces.name,
      link: `${links.auxiliarSendPieces}`,
      icon: "toolbox",
    },
    {
      text: links.productionSendPieces.name,
      link: `${links.productionSendPieces.to}`,
      icon: "hands-helping",
    },
  ],
  [roles.Metrology]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: links.receivePartPieces.name,
      link: `${links.receivePartPieces.to}/${places.Metrology}`,
      icon: "ruler",
    },
    {
      text: links.sendPieces.name,
      link: `${links.sendPieces.to}/${places.Metrology}`,
      icon: "share-square",
    },
  ],
  [roles.Warehouse]: [
    { text: links.home.name, link: links.home.to, icon: "home" },
    {
      text: "Recibir piezas",
      link: `${links.receivePartPieces.to}/${places.Completed}`,
      icon: "hands-helping",
    },
    {
      text: "Almacén",
      link: `${links.getPieces.to}/${places.Completed}`,
      icon: "puzzle-piece",
    },
  ],
  default: [{ text: "Inicio", link: links.home.to, icon: "home" }],
};
