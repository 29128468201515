import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import logo from "../../resources/images/zamtest/blue.png";
import SetMenu from "../1-blocks/b_SetMenu";
import { menu, menuWithoutProject } from "../../resources/constants/menu";
import { useDispatch } from "react-redux";
import { setProjectId } from "../../redux/ducks/projectId";
import ProjectFilter from "./s_projectFilter";

function Sidebar({ role = "default", projectSelected = false }) {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onStateChange = (state) => setOpen(state.isOpen);
  const dispatch = useDispatch();

  const onSelect = (project) => {
    if (project) {
      dispatch(
        setProjectId({
          id: project.id,
          code: project.code,
          name: project.name,
          clientCode: project.clientCode,
        })
      );
    } else dispatch(setProjectId(""));
  };

  return (
    <Menu isOpen={open} onStateChange={(state) => onStateChange(state)}>
      <img src={logo} alt="" className="bm-logo" />
      <br />
      <div className="select-item">
        <ProjectFilter onSelect={onSelect} useDefault={true} />
      </div>
      {projectSelected ? (
        <SetMenu items={menu[role]} onClose={onClose} />
      ) : (
        <SetMenu items={menuWithoutProject[role]} onClose={onClose} />
      )}
    </Menu>
  );
}

export default Sidebar;
