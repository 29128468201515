/* #region  IMPORTS */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ValidInputs } from "../../../Helpers";
import { OperationObject } from "../../../Helpers/Factory/operationFactory";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import {
  OperationButton,
  OperationForm,
} from "../../../Helpers/Forms/OperationForm";
import { OperationValidator } from "../../../Helpers/Validators/FormsValidation";
import { apiSuccess } from "../../../redux/ducks/apiData";
import { GetApi, PostApi } from "../../../redux/middleware/httpMethodApi";
import { invalidForm } from "../../../resources/constants/messages";
import {
  CreateOperationApi,
  GetOperationApi,
  UpdateOperationApi,
} from "../../../Services/OperationService";
import Form from "../../2-systems/s_Form";
import WithContextData from "../../HOC/WithContextData";
/* #endregion */

function CreateOperations({
  apiData,
  history,
  dispatch,
  refresh_route,
  _routes,
  links,
  helpers,
}) {
  const { operationId } = useParams();
  const [form, setForm] = useState(OperationObject);

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    if (!operationId) {
      dispatch(apiSuccess());
      dispatch(refresh_route(_routes[links.createOperation.name]));
    } else {
      dispatch(GetApi([operationId], GetOperationApi));
      dispatch(
        refresh_route(
          _routes[links.editOperation.name],
          `${links.editOperation.to}/${operationId}`
        )
      );
      ValidInputs(OperationForm);
    }
    return () => {
      helpers.CleanInputs(OperationForm);
    };
  }, []);
  useEffect(() => {
    if (!apiData.loading && apiData.ok && apiData.data?.title) {
      setForm(apiData.data);
    }
  }, [apiData.data]);
  /* #endregion */

  /* #region  CONFIRMACIÓN DE CREAR */
  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      helpers.Toast.fire(
        ToastObject("Se ha creado con éxito tu nueva operación", "success")
      );
      history.goBack();
    }
  }, [apiData.data]);
  /* #endregion */

  const handleSubmit = async (valid) => {
    if (valid) {
      form.title = form.title.trim();
      if (!operationId) dispatch(PostApi([form], CreateOperationApi));
      else dispatch(PostApi([form], UpdateOperationApi));
    } else {
      helpers.Toast.fire(ToastObject(invalidForm, "error"));
    }
  };

  return (
    <div className="form-container">
      <h1 className="title is-3">Crear operación</h1>

      <Form
        inputs={OperationForm}
        inputsValidator={OperationValidator}
        state={[form, setForm]}
        button={OperationButton}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

export default WithContextData(CreateOperations);
