import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { PieceObject } from "../../../Helpers/Factory/pieceFactory";
import { GetApi } from "../../../redux/middleware/httpMethodApi";
import { GetPieceApi } from "../../../Services/PiecesService";
import PieceForm from "../../5-forms/pieceForm";
import WithContextData from "../../HOC/WithContextData";

function Piece({ apiData, dispatch, refresh_route, _routes, links }) {
  const { pieceId } = useParams();
  const [form, setForm] = useState(PieceObject);
  useEffect(() => {
    dispatch(refresh_route(_routes[links.piece.name], "/piece/1"));
    dispatch(GetApi([pieceId], GetPieceApi));
  }, []);

  useEffect(() => {
    if (!apiData.loading && apiData.ok && apiData.data?.id) {
      setForm(apiData.data);
    }
  }, [apiData.data]);
  if (form?.projectId) {
    return (
      <PieceForm deafultValues={form} readonly={true} title="Ver pieza">
        <>
          <label className="label">Operaciones</label>
          <div className="border-box p-5">
            {form?.operations &&
              form.operations.map((item, index) => (
                <span className="tag is-dark mr-5" key={index}>
                  {item.title}
                </span>
              ))}
          </div>
        </>
      </PieceForm>
    );
  } else return null;
}

export default WithContextData(Piece);
