/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-deprecated */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as dataTableFactory from "../../Helpers/Factory/dataTableFactory";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import * as jzip from "jszip";

const $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-buttons/js/dataTables.buttons.min");
require("datatables.net-buttons/js/buttons.html5.min");

window.JSZip = jzip;

/* #region  ACTUALIZAR TABLA */
function updateTable(data, t, props) {
  const table = t.DataTable();
  let dataChanged = false;
  // eslint-disable-next-line no-unused-vars
  const [targets, dataTable, columns] = GetDataToTable(data, props);
  table.rows().every(function () {
    const oldNameData = this.data();
    if (oldNameData !== dataTable) {
      dataChanged = true;
      this.data(dataTable);
    }
    return true;
  });

  if (dataChanged) {
    table.clear();
    table.rows.add(dataTable);
    table.draw();
  }
}
/* #endregion */

/* #region  GENERAR COLUMNAS Y RENGLONES DE DATOS */
function GetDataToTable(data = [], props) {
  //OBTENER LAS PROPIEDADES DEL OBJETO
  let properties = [];
  properties = Object.keys(data[0]);
  //AÑADIENDO LAS COLUMNAS
  let columns = [];
  properties.forEach((item) => {
    columns.push({ title: item, sTitle: item });
  });
  if (props.buttons) {
    columns.push({ title: props.columnName, sTitle: props.columnName }); //SE AGREGA OTRA COLUMNA PARA COLOCAR EL BOTÓN
  }

  //AÑADIENDO EL ARRAY DE DATOS
  let dataTable = [];
  data.forEach((item) => {
    let properties = Object.keys(item);
    let newData = [];
    properties.forEach((property) => {
      newData.push(item[property]);
    });

    if (props.buttons) {
      newData.push("");
    }

    dataTable.push(newData);
  });
  //SE OBTIENE EL TOTAL DE COLUMNAS
  let targets = properties.length;

  return [targets, dataTable, columns];
}
/* #endregion */

export class Tables extends Component {
  //CREAR TABLA
  componentDidMount() {
    const [targets, dataTable, columns] = GetDataToTable(
      this.props.data,
      this.props
    );
    $.fn.dataTable.ext.errMode = "none";
    //SE LLENA LOS DATOS DE LA DATATABLE
    this.$el = $(this.el);
    if (this.props.states && !this.props.buttons) {
      this.$el.DataTable({
        aLengthMenu: dataTableFactory.aLengthMenu,
        iDisplayLength: dataTableFactory.iDisplayLength,
        language: dataTableFactory.language,
        dom: dataTableFactory.dom,
        buttons: dataTableFactory.buttons,
        data: dataTable,
        columns: columns,
        columnDefs: [
          {
            targets: targets - 1,
            createdCell: (td, cellData, rowData, row, col) => {
              ReactDOM.render(
                <div>
                  <span
                    className={`tag is-${
                      this.props.states[rowData[targets - 1]][1]
                    }`}
                  >
                    {this.props.states[rowData[targets - 1]][0]}
                  </span>
                </div>,
                td
              );
            },
          },
        ],
      });
    } else if (!this.props.states && this.props.buttons) {
      this.$el.DataTable({
        aLengthMenu: dataTableFactory.aLengthMenu,
        iDisplayLength: dataTableFactory.iDisplayLength,
        language: dataTableFactory.language,
        dom: dataTableFactory.dom,
        buttons: dataTableFactory.buttons,
        data: dataTable,
        columns: columns,
        columnDefs: [
          {
            targets: targets,
            createdCell: (td, cellData, rowData, row, col) => {
              ReactDOM.render(
                <div className="columns">
                  {this.props.buttons.map((item, index) => (
                    <div className="column" key={index}>
                      <button
                        className={`button is-outlined is-small is-${item.class} has-tooltip-${item.class}`}
                        data-tooltip={item.name}
                        style={{ height: "18px" }}
                        onClick={() =>
                          this.props.handleSubmit(rowData, item.name)
                        }
                      >
                        <span className="icon is-big">
                          <i className={`fas fa-${item.icon}`}></i>
                        </span>
                      </button>
                    </div>
                  ))}
                </div>,
                td
              );
            },
          },
        ],
      });
    } else if (this.props.states && this.props.buttons) {
      this.$el.DataTable({
        aLengthMenu: dataTableFactory.aLengthMenu,
        iDisplayLength: dataTableFactory.iDisplayLength,
        language: dataTableFactory.language,
        data: dataTable,
        columns: columns,
        dom: dataTableFactory.dom,
        buttons: dataTableFactory.buttons,
        columnDefs: [
          {
            targets: targets,
            createdCell: (td, cellData, rowData, row, col) => {
              ReactDOM.render(
                <div className="columns">
                  {this.props.buttons.map((item, index) => (
                    <div className="column" key={index}>
                      <button
                        className={`button is-outlined is-small is-${item.class} has-tooltip-${item.class}`}
                        data-tooltip={item.name}
                        style={{ height: "18px" }}
                        onClick={() =>
                          this.props.handleSubmit(rowData, item.name)
                        }
                      >
                        <span className="icon is-big">
                          <i className={`fas fa-${item.icon}`}></i>
                        </span>
                      </button>
                    </div>
                  ))}
                </div>,
                td
              );
            },
          },
          {
            targets: targets - 1,
            createdCell: (td, cellData, rowData, row, col) => {
              let array = this.props.states[rowData[targets - 1]];
              let content = "Cargando...";
              let color = "dark";
              if (array) {
                content = array[0];
                color = array[1];
              }
              ReactDOM.render(
                <div>
                  <span className={`tag is-${color}`}>{content}</span>
                </div>,
                td
              );
            },
          },
        ],
      });
    } else {
      this.$el.DataTable({
        aLengthMenu: dataTableFactory.aLengthMenu,
        iDisplayLength: dataTableFactory.iDisplayLength,
        language: dataTableFactory.language,
        drawCallback: function () {
          $(".dataTables_paginate > .pagination").addClass(
            "pagination-rounded"
          );
        },
        dom: dataTableFactory.dom,
        buttons: dataTableFactory.buttons,
        data: dataTable,
        columns: columns,
      });
    }

    if (!this.props.firstColumnVisible) {
      let table = this.$el.DataTable();
      table.column(0).visible(false);
    }
  }

  componentWillUnmount() {
    this.$el.find("table").DataTable().destroy(true);
  }

  //MANDAR A ACTUALIZAR TABLA
  shouldComponentUpdate(nextProps) {
    updateTable(nextProps.data, this.$el, this.props);

    return false;
  }

  render() {
    return (
      <div className="dataTableContainer">
        <div className="box dataTableBox">
          <div>{this.props.children}</div>
          <table
            className="display"
            width="100%"
            ref={(el) => (this.el = el)}
          ></table>
        </div>
      </div>
    );
  }
}

export default Tables;
