/* #region  IMPORTS */
import { buildUserForSelect } from "../../Helpers/Factory/userFactory";
import { OK } from "../../resources/constants/messages";
import * as roles from "../../resources/constants/roles";
import { GetProjectApi } from "../../Services/ProjectService";
import { GetUsersByRoleApi } from "../../Services/SuperUserService";
import { apiRequest, apiSuccess, apiFail } from "../ducks/apiData";
import { CreateData } from "./httpMethodApi";
/* #endregion */

export const InitAdminDesignersMid = (projectId) => async (dispatch) => {
  dispatch(apiRequest());
  var result = await GetUsersByRoleApi(roles.Design);
  if (result.status === OK) {
    var userResult = result.data;
    result = await GetProjectApi(projectId);
    if (result.status === OK) {
      dispatch(
        apiSuccess({
          users: CreateData(userResult, buildUserForSelect),
          designers: CreateData(result.data.designers, buildUserForSelect),
        })
      );
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};
