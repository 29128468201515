import React from "react";
import PropTypes from "prop-types";

export default function CheckBox({
  content = "",
  onChange = () => {},
  ...props
}) {
  const onChangeParent = (e) => {
    const checked = e.target.checked;
    onChange(checked);
  };
  return (
    <label className="checkbox">
      <input
        type="checkbox"
        className="mr-1"
        onChange={onChangeParent}
        {...props}
      />
      {content}
    </label>
  );
}

CheckBox.propTypes = {
  content: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
