import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import images from "../../resources/constants/Images";
import logo from "../../resources/images/sciodesk-cmyk.png";

function Modal({ show, onClose, modalData, ...props }) {
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27 && modalData?.OutsideClick) onClose();
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return () => {
      document.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return (
    <div
      data-testid="modal"
      className={`modal ${show ? "show" : ""}`}
      onClick={() => {
        if (modalData?.OutsideClick) onClose();
      }}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header is-flex is-justify-content-center is-align-items-center">
          <h4 className="modal-title">{modalData.title}</h4>
        </div>
        <div className="modal-body">
          {!modalData?.loader ? (
            <div>
              {/* {modalData.icon !== null || modalData.icon !== undefined ? ( */}
              <img className="icon_modal" src={logo} alt="logo" />
              {/* ) : null} */}
              <div>{modalData.body}</div>
              {modalData?.link ? (
                <Link
                  to={modalData.to}
                  style={{ color: "#0c64d1" }}
                  onClick={props.onGoToLink}
                >
                  {modalData.link}
                </Link>
              ) : null}
            </div>
          ) : (
            <div className="loader"></div>
          )}
        </div>
        {!modalData?.loader ? (
          <div className="modal-footer">
            <button
              className={`button scheme ${
                modalData.icon !== undefined && modalData.icon !== null
                  ? modalData.icon
                  : ""
              }`}
              onClick={onClose}
            >
              {modalData?.button ? modalData.button : "OK"}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Modal;
