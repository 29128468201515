import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PostApi } from "../../../redux/middleware/httpMethodApi";
import { LoginApi } from "../../../Services/authenticationService";
import styles from "./login.module.scss";
import { isPassword, isZamtestEmail } from "../../../Helpers/Validators";
import { ToastError } from "../../../Helpers/sweetAlertHandler";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { links } from "../../../resources/constants/links";
import Input from "../../0-atoms/a_formInput";
import { AuthorizeService } from "../../api-authorization/AuthorizeService";
import { apiSuccess } from "../../../redux/ducks/apiData";

export default function LoginPage() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [userNameOk, setUserNameOk] = useState(false);
  const [passwordOk, setPasswordOk] = useState(false);
  const apiData = useSelector((state) => state.apiData);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onChange = (value, name) => {
    if (name === "password") {
      // verificar con regex
      const result = isPassword(value);
      setPasswordOk(result);
      setFormData({ ...formData, password: value });
    } else {
      // verificar con regex
      const result = isZamtestEmail(value);
      setUserNameOk(result);
      setFormData({ ...formData, email: value });
    }
  };

  useEffect(() => {
    dispatch(apiSuccess());
  }, []);

  useEffect(() => {
    if (!apiData.loading && !apiData.ok) {
      ToastError(apiData.errorMessage.error);
      setLoading(false);
    } else if (apiData.data?.postConfirmation) {
      const auth = new AuthorizeService();
      auth.saveCookie(apiData.data.data.token, apiData.data.data.expiration);
      history.replace(links.home.to);
    }
  }, [apiData.data]);

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const onSubmit = () => {
    if (!userNameOk || !passwordOk) {
      ToastError("Debes de completar el formulario para poder enviarlo");
      //error
      return;
    }
    setLoading(true);
    dispatch(PostApi([formData], LoginApi));
  };

  return (
    <div className="is-flex is-fullheight is-justify-content-center is-align-items-center">
      <div className={`box p-5 has-background-white ${styles.container}`}>
        <header className="is-flex is-justify-content-center">
          <h2 className="title is-2">Inicio de sesión</h2>
        </header>
        <br />
        <Input
          title="Correo"
          name="email"
          icon="user"
          placeholder="Correo@zamtest.com"
          onCheckError={isZamtestEmail}
          errorMessage={
            "Debes de colocar un correo valido ejemplo: correo@zamtest.com"
          }
          onChange={onChange}
        />
        <Input
          title="Contraseña"
          name="password"
          icon="lock"
          type="password"
          placeholder="Constraseña"
          onCheckError={isPassword}
          errorMessage={
            "La contraseña debe de contener 7 caracteres, 1 mayúscula, 1 carácter alfanumérico y 1 número"
          }
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <div className="is-flex is-flex-direction-row-reverse">
          <p
            className="has-text-info is-clickable"
            onClick={() => history.push(links.forgotPassword.to)}
          >
            <u>{"¿Olvidaste la contraseña?"}</u>
          </p>
        </div>
        <br />
        <footer className="is-flex is-justify-content-center">
          <button
            className={`button is-info ${loading && "is-loading"}`}
            onClick={onSubmit}
            disabled={!userNameOk || !passwordOk}
          >
            <span className="icon">
              <i className="fas fa-paper-plane"></i>
            </span>
            <span>Enviar</span>
          </button>
        </footer>
      </div>
    </div>
  );
}
