import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import arrow from "../../resources/images/icons/arrow.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { refresh_route } from "../../redux/ducks/route";
import routes from "../../resources/constants/routes";

const AccordionTogglerImage = styled("img")`
  height: 0.8em;
  transform: rotate(270deg);
  transition: 300ms all;
`;

function SetMenu({ items = [], onClose = () => {} }) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setExpanded(false);
    return () => {
      setExpanded(false);
    };
  }, []);

  return (
    <div>
      {items.length > 0
        ? items.map((item, index) => (
            <div key={index}>
              {item.parent !== undefined ? (
                <div>
                  <Accordion
                    style={{
                      background: "#4a89be",
                      boxShadow: "none",
                    }}
                    expanded={expanded === item.parent.name}
                    onChange={handleChange(item.parent.name)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <AccordionTogglerImage
                          src={arrow}
                          alt=""
                          color="white"
                        />
                      }
                      aria-controls={`${item.name}bh-content`}
                      id={`${item.parent.name}bh-header`}
                      style={{ paddingLeft: "10px" }}
                    >
                      <Typography style={{ color: "white" }}>
                        {item.parent.name}
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails
                      style={{ display: "block", padding: "0px" }}
                    >
                      {item.parent.childs.map((child, i) => (
                        <Link
                          className="bm-item"
                          key={child.text + i}
                          style={{
                            display: "flex",
                            padding: "8px 0px 8px 35px",
                          }}
                          to={`${child.link}`}
                          onClick={() => {
                            onClose();
                            dispatch(refresh_route(routes[child.text]));
                          }}
                        >
                          <div style={{ marginRight: "8px" }}>
                            <span className="icon">
                              <i className={`fas fa-${child.icon}`}></i>
                            </span>
                          </div>
                          <div>
                            <span>{child.text}</span>
                          </div>
                        </Link>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ) : (
                <Link
                  className="bm-item"
                  key={item.text + index}
                  style={{
                    display: "flex",
                  }}
                  to={`${item.link}`}
                  onClick={() => {
                    onClose();
                    dispatch(refresh_route(routes[item.text]));
                  }}
                >
                  <div style={{ marginRight: "8px" }}>
                    <span className="icon">
                      <i className={`fas fa-${item.icon}`}></i>
                    </span>
                  </div>
                  <div>
                    <span>{item.text}</span>
                  </div>
                </Link>
              )}
            </div>
          ))
        : null}
    </div>
  );
}

export default SetMenu;
