import { links } from "./links";

const routes = {
  Inicio: [[links.home.name, links.home.to]],

  [links.workOrder.name]: [
    [links.home.name, links.home.to],
    [links.workOrders.name, links.workOrders.to],
    [links.workOrder.name, "*"],
  ],
  [links.workOrders.name]: [
    [links.home.name, links.home.to],
    [links.workOrders.name, links.workOrders.to],
  ],
  [links.createWorkOrder.name]: [
    [links.home.name, links.home.to],
    [links.workOrders.name, links.workOrders.to],
    [links.createWorkOrder.name, links.createWorkOrder.to],
  ],
  [links.pieces.name]: [
    [links.home.name, links.home.to],
    [links.pieces.name, links.pieces.to],
  ],
  [links.piece.name]: [
    [links.home.name, links.home.to],
    [links.pieces.name, links.pieces.to],
    [links.piece.name, links.piece.to],
  ],
  [links.createPiece.name]: [
    [links.home.name, links.home.to],
    [links.pieces.name, links.pieces.to],
    [links.createPiece.name, links.createPiece.to],
  ],
  [links.editPiece.name]: [
    [links.home.name, links.home.to],
    [links.pieces.name, links.pieces.to],
    [links.editPiece.name, links.editPiece.to],
  ],

  [links.projects.name]: [
    [links.home.name, links.home.to],
    [links.projects.name, links.projects.to],
  ],
  [links.createProject.name]: [
    [links.home.name, links.home.to],
    [links.projects.name, links.projects.to],
    [links.createProject.name, links.createProject.to],
  ],
  [links.adminDesigners.name]: [
    [links.home.name, links.home.to],
    [links.adminDesigners.name, links.adminDesigners.to],
  ],
  [links.stats.name]: [
    [links.home.name, links.home.to],
    [links.stats.name, links.stats.to],
  ],

  [links.users.name]: [
    [links.home.name, links.home.to],
    [links.users.name, links.users.to],
  ],
  [links.editUser.name]: [
    [links.home.name, links.home.to],
    [links.users.name, links.users.to],
    [links.editUser.name, links.editUser.to],
  ],

  [links.operations.name]: [
    [links.home.name, links.home.to],
    [links.operations.name, links.operations.to],
  ],
  [links.createOperation.name]: [
    [links.home.name, links.home.to],
    [links.operations.name, links.operations.to],
    [links.createOperation.name, links.createOperation.to],
  ],
  [links.editOperation.name]: [
    [links.home.name, links.home.to],
    [links.operations.name, links.operations.to],
    [links.editOperation.name, links.editOperation.to],
  ],

  [links.sendPieces.name]: [
    [links.home.name, links.home.to],
    [links.sendPieces.name, links.sendPieces.to],
  ],
  [links.planningSendPieces.name]: [
    [links.home.name, links.home.to],
    [links.planningSendPieces.name, links.planningSendPieces.to],
  ],
  [links.auxiliarSendPieces.name]: [
    [links.home.name, links.home.to],
    [links.auxiliarSendPieces.name, links.auxiliarSendPieces.to],
  ],
  [links.productionSendPieces.name]: [
    [links.home.name, links.home.to],
    [links.productionSendPieces.name, links.productionSendPieces.to],
  ],
  [links.receivePieces.name]: [
    [links.home.name, links.home.to],
    [links.receivePieces.name, links.receivePieces.to],
  ],

  [links.createUser.name]: [
    [links.home.name, links.home.to],
    [links.users.name, links.users.to],
    [links.createUser.name, links.createUser.to],
  ],

  [links.changePassword.name]: [
    [links.home.name, links.home.to],
    [links.changePassword.name, links.changePassword.to],
  ],
};

export default routes;
