import { buildDate, buildHour } from "..";
import * as places from "../../resources/constants/places";
import { recordStatus } from "../../resources/constants/recordStatus";

export const recordObject = {
  title: "El zamora es chido",
  content: "Cantidad: 1, ",
  dateTime: "15/09/2021",
  icon: "info-circle",
  color: "#3e8ed0",
  hour: "11:56 am",
};

// Undefined = 0, warning
// OnDelivery = 1, info,
// Deliverated = 2, primary
// Terminated = 3, primary
// Canceled = 4, danger
// Rejected = 5, danger
// Created = 6, info
// Lost = 7, warning
// ChangeRoute = 8, purple
// Updated = 9, purple
export const purple = "#3E40D0";
export const primary = "#00d1b2";
export const danger = "#f14668";
export const warning = "#f1c40f";
export const info = "#3e8ed0";
export const dark = "#2F3030";

const RecordStatus = [
  {
    icon: "folder-open",
    color: warning,
  },
  {
    icon: "truck",
    color: info,
  },
  {
    icon: "box-open",
    color: primary,
  },
  {
    icon: "check-circle",
    color: primary,
  },
  {
    icon: "times",
    color: danger,
  },
  {
    icon: "bug",
    color: danger,
  },
  {
    icon: "plus-circle",
    color: info,
  },
  {
    icon: "exclamation-triangle",
    color: warning,
  },
  {
    icon: "route",
    color: purple,
  },
  {
    icon: "edit",
    color: purple,
  },
  {
    icon: "check",
    color: primary,
  },
  {
    icon: "check",
    color: warning,
  },
  {
    icon: "search-minus",
    color: danger,
  },
  {
    icon: "bug",
    color: danger,
  },
];

export const buildRecord = (record) => ({
  title: record.title !== null ? record.title : "Titulo default",
  content:
    record.description !== ""
      ? record.description
      : "Se ha creado la orden de trabajo",
  dateTime: buildDate(record.date),
  icon: RecordStatus[record.status]?.icon,
  color: RecordStatus[record.status]?.color,
  hour: buildHour(record.date),
});

export const buildRecordForReject = (
  traceableId,
  place,
  description,
  amount
) => ({
  traceableId: traceableId,
  title: "Rechazo de " + place,
  description: description,
  status:
    place === places.Auxiliar
      ? recordStatus.RejectedByAuxiliar
      : recordStatus.Rejected,
  amount: amount,
  productionTime: "",
});

export const buildRecordForProduction = (
  traceableId,
  amount,
  productionTime
) => ({
  traceableId: traceableId,
  title: "Aprobado en auxiliar",
  description: `Se aceptaron ${amount} piezas de producción interna.\nTiempo: ${productionTime}`,
  status: recordStatus.OnDelivery,
  amount: amount,
  productionTime: productionTime,
});
