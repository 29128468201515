export const UrlHost = window.location.protocol + "//" + window.location.host;

export const GetUrl = () => localStorage.getItem("zamtest-url");

//SuperUser
export const GetUsersByRole = "/api/SuperUser/GetUsersByRole/"; //role
export const GetRoles = "/api/SuperUser/GetRoles";
export const GetUser = "/api/SuperUser/GetUser/"; //id
export const GetUsers = "/api/SuperUser/GetUsers"; //query
export const UpdateUserRole = "/api/SuperUser/UpdateUserRole";

// Authentication
export const Login = "/api/Authentication/login";
export const RegisterAdmin = "/api/Authentication/RegisterAdmin";
export const Register = "/api/Authentication/Register";
export const ChangePassword = "/api/Authentication/ChangePassword";
export const GetAllUsers = "/api/Authentication/GetUsers";
export const ForgotPassword = "/api/Authentication/ForgotPassword/"; // + email
export const ResetPassword = "/api/Authentication/ResetPassword";

//WorkOrder
export const CreateWorkOrder = "/api/WorkOrder/CreateWorkOrder";
export const UpdateWorkOrder = "/api/WorkOrder/UpdateWorkOrder";
export const GetWorkOrder = "/api/WorkOrder/GetWorkOrder/"; //id
export const GetWorkOrdersByProject = "/api/WorkOrder/GetWorkOrdersByProject/"; //code
export const GetWorkOrders = "/api/WorkOrder/GetWorkOrders";
export const GetWorkOrdersWithSpec = "/api/WorkOrder/GetWorkOrdersWithSpec";
export const GetWorkOrdersByProjectWithSpec =
  "/api/WorkOrder/GetWorkOrdersByProjectWithSpec/"; // id
export const ChangeWorkOrderStatusFromTraceable =
  "/api/WorkOrder/ChangeWorkOrderStatusFromTraceable";
export const CancelWorkOrder = "/api/WorkOrder/CancelWorkOrder/"; //code
export const ChangeWorkOrderProductionTimeFromTraceable =
  "/api/WorkOrder/ChangeWorkOrderProductionTimeFromTraceable";
export const GetWorkOrdersByProjectFile =
  "/api/WorkOrder/GetWorkOrdersByProjectFile/"; //projectCode

//Records
export const GetRecords = "/api/Records/GetRecords/"; //code

//Pieces
export const CreatePiece = "/api/Piece/CreatePiece";
export const UpdatePiece = "/api/Piece/UpdatePiece";
export const GetPiece = "/api/Piece/GetPiece/"; //id
export const GetPieces = "/api/Piece/GetPieces";
export const GetPiecesByProject = "/api/Piece/GetPiecesByProject/"; //code
export const GetPiecesWithSpec = "/api/Piece/GetPiecesWithSpec";
export const GetPiecesFileByProject = "/api/Piece/GetPiecesFileByProject/"; //projectCode
export const DeletePiece = "/api/Piece/DeletePiece/";

//Project
export const CreateProject = "/api/Project/CreateProject";
export const UpdateProject = "/api/Project/UpdateProject";
export const GetProject = "/api/Project/GetProject/"; //id
export const GetProjects = "/api/Project/GetProjects";
export const GetProjectsWithSpec = "/api/Project/GetProjectsWithSpec";
export const UpdateDesigners = "/api/Project/UpdateDesigners";

//Operations
export const CreateOperation = "/api/Operations/CreateOperation";
export const UpdateOperation = "/api/Operations/UpdateOperation";
export const DeleteOperation = "/api/Operations/DeleteOperation/"; //id
export const GetOperation = "/api/Operations/GetOperation/"; //id
export const GetOperations = "/api/Operations/GetOperations";

//Assembly
export const CreateAssembly = "/api/Assembly/CreateAssembly";
export const UpdateAssembly = "/api/Assembly/UpdateAssembly";
export const GetAssembly = "/api/Assembly/GetAssembly/"; //id
export const GetAssemblies = "/api/Assembly/GetAssemblies";
export const GetAssembliesByProject = "/api/Assembly/GetAssembliesByProject/"; //code

//MetallicFinish
export const CreateMetallicFinish = "/api/MetallicFinish/CreateMetallicFinish";
export const UpdateMetallicFinish = "/api/MetallicFinish/UpdateMetallicFinish";
export const GetMetallicFinish = "/api/MetallicFinish/GetMetallicFinish/"; //id
export const GetMetallicFinishes = "/api/MetallicFinish/GetMetallicFinishes";

//PieceMaterial
export const CreatePieceMaterial = "/api/PieceMaterial/CreatePieceMaterial";
export const UpdatePieceMaterial = "/api/PieceMaterial/UpdatePieceMaterial";
export const GetPieceMaterial = "/api/PieceMaterial/GetPieceMaterial/"; //id
export const GetPieceMaterials = "/api/PieceMaterial/GetPieceMaterials";

//PieceMaterialKind
export const CreatePieceMaterialKind =
  "/api/PieceMaterialKind/CreatePieceMaterialKind";
export const UpdatePieceMaterialKind =
  "/api/PieceMaterialKind/UpdatePieceMaterialKind";
export const GetPieceMaterialKind =
  "/api/PieceMaterialKind/GetPieceMaterialKind/"; //id
export const GetPieceMaterialKinds =
  "/api/PieceMaterialKind/GetPieceMaterialKinds";

//MoveTraceable
export const MoveNext = "/api/MoveTraceable/MoveNext";
export const DoubleMove = "/api/MoveTraceable/DoubleMove";
export const TripleMove = "/api/MoveTraceable/TripleMove";
export const GetTraceablesToReceive =
  "/api/MoveTraceable/GetTraceablesToReceive/"; //place;
export const GetTraceablesToReceiveForDesigner =
  "/api/MoveTraceable/GetTraceablesToReceiveForDesigner";
export const GetTraceablesOnDesignForDesigner =
  "/api/MoveTraceable/GetTraceablesOnDesignForDesigner";
export const GetTraceablesOnPlace = "/api/MoveTraceable/GetTraceablesOnPlace/"; //place;
export const GetTraceablesOnPlaceWithSpec =
  "/api/MoveTraceable/GetTraceablesOnPlaceWithSpec/"; //place;
export const GetTraceablesToReceiveWithSpec =
  "/api/MoveTraceable/GetTraceablesToReceiveWithSpec/"; //place
export const GetTraceablesOnPlaceByWorkOrder =
  "/api/MoveTraceable/GetTraceablesOnPlace/"; //place/workOrderCode
export const GetTraceablesToReceiveByWorkOrder =
  "/api/MoveTraceable/GetTraceablesToReceive/"; //place/workOrderId

//ChangeRoute
export const ChangeRouteComplete = "/api/ChangeRoute/Complete";
export const ChangeRouteFinished = "/api/ChangeRoute/Finished"; //true || false
export const ChangeRouteProduction = "/api/ChangeRoute/Production";
export const ChangeRouteQualityNotPass = "/api/ChangeRoute/QualityNotPass";
export const ChangeRouteMetrologyNotPass = "/api/ChangeRoute/MetrologyNotPass";
export const ChangeRouteExternalMachining =
  "/api/ChangeRoute/ExternalMachining";
export const ChangeRouteTreatment = "/api/ChangeRoute/Treatment";
export const ChangeRouteLackInformation = "/api/ChangeRoute/LackInformation";
export const ChangeRouteAuxiliarNotPass = "/api/ChangeRoute/AuxiliarNotPass";
export const ChangeRouteFinishedWithoutInspection =
  "/api/ChangeRoute/Finished/WithoutInspection/false";
export const ChangeRouteOrderNoCompleted = "/api/ChangeRoute/OrderNoCompleted";

//WorkOrderFile
export const GetFile = "/api/WorkOrderFile/GetFile/"; //workOrderCode
export const UpdateWorkOrderFile = "/api/WorkOrderFile/UpdateWorkOrderFile";

//ProductivityData
export const GetPiecesQuantity = "/api/ProductivityData/GetPiecesQuantity/"; //projectCode
export const GetPiecesQuantityMetrology =
  "/api/ProductivityData/GetPiecesQuantityMetrology/";
export const GetProducedPiecesByDay =
  "/api/ProductivityData/GetProducedPiecesByDay/"; //projectCode

//Notification
export const Subscribe = "/api/Notification/Suscribe";
export const UnSubscribe = "/api/Notification/Unsuscribe";
export const GetKey = "/api/Notification/PublicKey";

//ZamtestTraceable
export const ChangeRejectedStatus =
  "/api/ZamtestTraceable/ChangeRejectedStatus";
export const CreateCustomRecord = "/api/ZamtestTraceable/CreateCustomRecord";
