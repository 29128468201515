import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import useMounted from "../../hooks/useMounted";

const Input = forwardRef(
  (
    {
      className,
      icon,
      placeholder,
      onKeyUp = () => {},
      style,
      name,
      error,
      textarea = false,
      onErase = () => {},
      onEnter = () => {},
      autoComplete = true,
      ...props
    },
    ref
  ) => {
    const mounted = useMounted();
    const input = useRef();
    const [deleteButton, setDeleteButton] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
      let mutationObserver = {};
      if (mounted) {
        mutationObserver = new MutationObserver(onMutation);
        mutationObserver.observe(input.current, { attributes: true });
      }

      return () => {
        if (mounted) mutationObserver.disconnect();
      };
    }, [mounted]);

    // eslint-disable-next-line no-unused-vars
    const onMutation = (mutationList, _) => {
      mutationList.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          const valueOfClass = input.current?.classList;
          if (
            valueOfClass &&
            valueOfClass.value.includes("is-danger") &&
            error !== ""
          ) {
            setShowError(true);
          } else if (error !== "") setShowError(false);
        }

        if (mutation.target.value.length > 0) setDeleteButton(true);
        else setDeleteButton(false);
      });
    };

    const onClick = () => {
      input.current.value = "";
      onKeyUp({ value: "", name: input.current.name });
      onErase({ value: "", name: input.current.name });
      setDeleteButton(false);
      input.current.focus();
    };

    const onKeyUpParent = (e) => {
      if (e.key === "Enter") onEnter();
    };

    const onChange = (e) => {
      let value = "";
      if (!e) value = input.current.value;
      else value = e.target.value;

      if (value.length > 0) {
        setDeleteButton(true);
      } else setDeleteButton(false);

      onKeyUp(e.target);
    };

    useImperativeHandle(ref, () => input.current);

    return (
      <div className="field" style={style}>
        <div
          className={`control ${!textarea && "has-icons-left"} has-icons-right`}
          style={{ width: "100%" }}
        >
          {!textarea ? (
            <input
              {...props}
              ref={input}
              id={placeholder}
              name={name}
              className={`input ${className}`}
              placeholder={placeholder}
              onKeyUp={onKeyUpParent}
              autoComplete={autoComplete ? "on" : "off"}
              onChange={onChange}
            />
          ) : (
            <textarea
              {...props}
              ref={input}
              id={placeholder}
              name={name}
              className={`textarea ${className}`}
              placeholder={placeholder}
              onKeyUp={onKeyUpParent}
              autoComplete={autoComplete ? "on" : "off"}
              onChange={onChange}
            />
          )}
          {!textarea && (
            <span className="icon is-small is-left">
              <i className={`fas fa-${icon}`}></i>
            </span>
          )}
          {deleteButton ? (
            <span
              className="icon is-small is-right is-clickable"
              onClick={onClick}
            >
              <i className="fas fa-times" />
            </span>
          ) : null}
        </div>
        {showError && <p className="help is-danger">{error}</p>}
      </div>
    );
  }
);

Input.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onKeyUp: PropTypes.func,
  textarea: PropTypes.bool,
  style: PropTypes.object,
  name: PropTypes.string,
  error: PropTypes.string,
  onErase: PropTypes.func,
  onEnter: PropTypes.func,
};

Input.displayName = "Input";

export default Input;
