import React, { useEffect, useState } from "react";
import { GetProjectMid } from "../../../redux/middleware/projects.mid";
import { links } from "../../../resources/constants/links";
import PieCharts from "../../1-blocks/b_PieCharts";
import LineCharts from "../../1-blocks/b_LineCharts";
import TitleList from "../../1-blocks/b_TitleList";
import FormDashboard from "../../1-blocks/b_formDashboard";
import ButtonsDashboard from "../../1-blocks/b_buttonsDashboard";
import authService from "../../api-authorization/AuthorizeService";
import {
  info as blue,
  danger,
  primary,
  warning,
  dark,
} from "../../../Helpers/Factory/recordFactory";
import * as roles from "../../../resources/constants/roles";
import useMounted from "../../../hooks/useMounted";
import WithContextData from "../../HOC/WithContextData";
import { useSelector } from "react-redux";
import { apiSuccess } from "../../../redux/ducks/apiData";
import SelectProjectMessage from "../../1-blocks/b_selectProjectMessage";

function Project({ apiData, dispatch, history }) {
  const projectId = useSelector((state) => state.projectId);
  /* #region  VARIABLES */
  const [form, setForm] = useState({});
  const [role, setRole] = useState("");
  const [info, setInfo] = useState({
    table: {},
    progress: {},
    quality: {},
    productivity: {},
    metrology: {},
    auxiliar: {},
  });
  const mounted = useMounted();

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    if (mounted) {
      const user = authService.getUser();
      setRole(user.role);
    }
    if (mounted && projectId.id) dispatch(GetProjectMid(projectId.id));
    else dispatch(apiSuccess());
  }, [mounted, projectId]);

  useEffect(() => {
    if (!apiData.loading && apiData.ok && apiData.data?.project) {
      setForm(apiData.data.project);
      setInfo({
        table: apiData.data.table,
        progress: apiData.data.progress,
        quality: apiData.data.quality,
        productivity: apiData.data.productivity,
        metrology: apiData.data.metrology,
        auxiliar: apiData.data.auxiliar,
      });
    }
  }, [apiData.data]);
  /* #endregion */

  const onClick = (e) => {
    switch (e.target.name) {
      case "pieces":
        history.push(`${links.pieces.to}`);
        break;

      case "workOrders":
        history.push(`${links.workOrders.to}`);
        break;

      default:
        break;
    }
  };

  if (projectId.id) {
    return (
      <>
        <div className="box whiteBox">
          <div className="is-flex is-flex-direction-column is-align-items-center mb-2">
            <h4 className="title is-4 mb-0">{projectId.code}</h4>
            <label className="label has-text-grey-light">
              {projectId.name}
            </label>
          </div>
          <ButtonsDashboard role={role} onClick={onClick} />
          <FormDashboard form={form} />
        </div>
        <div className="columns">
          <div
            className="column
         box whiteBox m-3"
            style={{ height: "370px" }}
          >
            <h1 className="title is-6 pt-0 is-flex is-justify-content-center">
              Progreso
            </h1>
            <PieCharts data={info.progress} colors={[primary, "#9B9B9B"]} />
          </div>
          {role !== roles.Operations ? (
            <div className="column box whiteBox m-3 heightBox p-3">
              <TitleList data={info.table} />
            </div>
          ) : (
            <div className="column box whiteBox m-3 heightBox">
              <h1 className="title is-6 pt-0 is-flex is-justify-content-center">
                Piezas en producción
              </h1>
              <PieCharts data={info.auxiliar} colors={[blue, dark, warning]} />
            </div>
          )}
          {/* {console.log(info)} */}
          <div className="column box whiteBox m-3 heightBox middlePositionBox">
            <h1 className="title is-6 is-small pt-0 is-flex is-justify-content-center">
              Piezas/día
            </h1>
            <LineCharts data={info.productivity} stack={1} single />
          </div>
          <div className="column box whiteBox m-3 heightBox">
            <h1 className="is-5 is-small pt-0 is-flex is-justify-content-center">
              Efectividad de diseño
            </h1>
            <PieCharts
              data={info.quality}
              colors={[primary, danger]}
              stack={2}
            />
            <h1 className="is-5 is-small pt-0">Efectividad de producción</h1>
            <PieCharts
              data={info.metrology}
              colors={[primary, warning, danger]}
              stack={2}
            />
          </div>
        </div>
      </>
    );
  } else return <SelectProjectMessage />;
}

export default WithContextData(Project);
