import React, { useEffect, useState } from "react";
import {
  CreatePiecesMid,
  GetFilePiece,
} from "../../../redux/middleware/pieces.mid";
import DataTable from "../../2-systems/s_DataTable";
import { buildCSVObject } from "../../../Helpers";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import UploadFile from "../../1-blocks/b_uploadFile";
import { primary } from "../../../Helpers/Factory/recordFactory";
import authService from "../../api-authorization/AuthorizeService";
import WithTablesData from "../../HOC/WithTableData";
import * as helpers from "../../../Helpers";
import { refresh_route } from "../../../redux/ducks/route";
import { links } from "../../../resources/constants/links";
import routes from "../../../resources/constants/routes";
import {
  DeletePieceApi,
  GetPiecesWithSpecApi,
} from "../../../Services/PiecesService";
import {
  buildPieceForTable,
  sortingPiece,
} from "../../../Helpers/Factory/pieceFactory";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ButtonIcon from "../../1-blocks/b_ButtonIcon";
import { PostApi } from "../../../redux/middleware/httpMethodApi";
import { confirmMessage, ToastError } from "../../../Helpers/sweetAlertHandler";
import SelectProjectMessage from "../../1-blocks/b_selectProjectMessage";

function Pieces({
  tableContent,
  onSort,
  pageCount,
  onChangeQuery,
  dispatch,
  apiData,
  onCallApi,
  count,
}) {
  const history = useHistory();
  const [role, setRole] = useState();
  const projectId = useSelector((state) => state.projectId);
  const [fileLoading, setFileLoading] = useState(false);

  /* #region  CONSTRUCTOR */
  useEffect(() => {
    dispatch(refresh_route(routes[links.pieces.name]));

    const user = authService.getUser();
    setRole(user.role);
  }, []);
  /* #endregion */

  /* #region  SUBIR ARCHIVO CSV */
  useEffect(() => {
    if (apiData.data?.piecesCreated) {
      helpers.swalAlert({
        title: apiData.data?.piecesCreated,
        text: apiData.data.postConfirmation,
        icon: "success",
        confirmButtonColor: primary,
      });
      onCallApi();
    }
  }, [apiData.data?.piecesCreated]);

  const handleFileUpload = (e) => {
    setFileLoading(true);
    const file = e.target.files[0];
    let type = file.name.split(".")[1];
    if (type && type === "csv") {
      const reader = new FileReader();
      reader.onload = (evt) => {
        const text = evt.target.result;
        let pieces = buildCSVObject(text, projectId);
        if (!pieces) ToastError("El archivo no cuenta con 16 columnas");
        else {
          if (Array.isArray(pieces) && pieces.length > 0)
            dispatch(CreatePiecesMid(pieces));
          else
            ToastError(
              "No se ha podido crear ninguna pieza desde el archivo, verifique que el nombre del proyecto coincida en la tercera columna"
            );
        }
      };

      reader.readAsText(file, "ISO-8859-1");
    } else ToastError("El archivo debe ser un csv");

    setFileLoading(false);
  };
  /* #endregion */

  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      helpers.Toast.fire(
        ToastObject("Su acción se hizo correctamente", "success")
      );
      onCallApi();
    }
  }, [apiData.data, onCallApi]);

  /* #region  HANDLE BUTTONS */
  const handleSubmit = async (rowData, item) => {
    const [id, code] = rowData;
    switch (item) {
      case "Editar pieza":
        history.push(links.editPiece.to + "/" + id);
        break;
      case "Ver pieza":
        history.push(links.piece.to + "/" + id);
        break;
      case "Eliminar":
        const result = await confirmMessage(
          `¿Seguro de eliminar la pieza ${code}?`,
          "No podrás revertir esta acción",
          "warning"
        );
        if (result) dispatch(PostApi([id], DeletePieceApi));
        break;
      default:
        break;
    }
  };
  /* #endregion */

  const onDownloadFile = () => {
    dispatch(GetFilePiece(projectId.id));
  };

  if (projectId.id) {
    return (
      <div className="is-flex is-justify-content-center">
        <DataTable
          data={tableContent}
          count={count}
          onClick={handleSubmit}
          hasCheckbox={false}
          visibleColumn={false}
          onChangeQuery={onChangeQuery}
          onSort={onSort}
          pageCount={pageCount}
          buttons={
            helpers.isDesigner(role)
              ? [
                  { name: "Editar pieza", class: "info", icon: "edit" },
                  { name: "Ver pieza", class: "dark", icon: "eye" },
                  { name: "Eliminar", class: "danger", icon: "times" },
                ]
              : [{ name: "Ver pieza", class: "info", icon: "eye" }]
          }
        >
          <div className="is-flex is-flex-direction-column is-align-items-center mb-5">
            <h4 className="title is-4 mb-0">{projectId.code}</h4>
            <label className="label has-text-grey-light">
              {projectId.name}
            </label>
          </div>
          <div className="is-flex is-flex-direction-row-reverse mb-2">
            {helpers.isDesigner(role) ? (
              <div className="mb-2 is-flex is-justify-content-end">
                <ButtonIcon
                  icon={"file-excel"}
                  name="Descargar"
                  className="is-dark mr-2 is-small"
                  onClick={onDownloadFile}
                />
                <UploadFile
                  className="mr-2 is-small"
                  handleFileUpload={handleFileUpload}
                  loading={fileLoading}
                />
                <button
                  className="button is-info is-small"
                  onClick={() => history.push(links.createPiece.to)}
                >
                  Crear pieza
                </button>
              </div>
            ) : null}
          </div>
        </DataTable>
      </div>
    );
  } else return <SelectProjectMessage />;
}

export default WithTablesData(
  Pieces,
  GetPiecesWithSpecApi,
  buildPieceForTable,
  sortingPiece,
  "projectId"
);
