/* #region  IMPORTS */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import authService from "../api-authorization/AuthorizeService";
import { NotificationSubscribeMid } from "../../redux/middleware/notification";
import { links } from "../../resources/constants/links";
import { refresh_route } from "../../redux/ducks/route";
import routes from "../../resources/constants/routes";
/* #endregion */

function Home() {
  const dispatch = useDispatch();
  // FIXME Reparar pedir permiso para notificaciones
  useEffect(() => {
    dispatch(refresh_route(routes[links.home.name]));
    (async () => {
      const user = await authService.getUser();
      let userId = user.sub;
      dispatch(NotificationSubscribeMid(userId));
    })();
  }, []);

  return <Redirect to="/project" />;
}

export default Home;
