import React, { useEffect, useRef, useState } from "react";
import DataTable from "../../2-systems/s_DataTable";
import { ToastObject } from "../../../Helpers/Factory/swalFactory";
import * as places from "../../../resources/constants/places";
import * as traceableFactory from "../../../Helpers/Factory/traceableFactory";
import {
  GetInitDataForSendPiecesByPlace,
  swalConfirmation,
} from "../../../Helpers/Factory/SendPiecesFactory";
import * as moveTraceableMid from "../../../redux/middleware/moveTraceable.mid";
import useMounted from "../../../hooks/useMounted";
import { refresh_route } from "../../../redux/ducks/route";
import routes from "../../../resources/constants/routes";
import { links } from "../../../resources/constants/links";
import { Toast } from "../../../Helpers";
import Tab from "../../0-atoms/a_tab";
import { PostApi } from "../../../redux/middleware/httpMethodApi";
import * as ChangeRouteService from "../../../Services/ChangeRouteService";
import { MoveNextApi } from "../../../Services/MoveTraceableService";
import { ChangeRouteTreatmentMid } from "../../../redux/middleware/changeRoute.mid";
import WithTabsDataTable from "../../HOC/WithTabsDataTable";
import ProjectFilter from "../../2-systems/s_projectFilter";
import { GetFileMid } from "../../../redux/middleware/file.mid";

function PlanningSendPieces({
  apiData,
  tableContent,
  dispatch,
  onCallApi,
  onChangeQuery,
  onSort,
  count,
  pageCount,
}) {
  const dataTableRef = useRef(null);
  const mounted = useMounted();
  const [buttons, setButtons] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (mounted) {
      dispatch(
        refresh_route(routes[links.planningSendPieces.name], places.Planning)
      );

      let initData = GetInitDataForSendPiecesByPlace(places.Planning);
      setTitle(initData.title);
      setButtons(initData.buttons);
    }
  }, [mounted]);

  useEffect(() => {
    if (apiData.data?.postConfirmation) {
      if (title === "Enviar piezas para producción")
        dataTableRef.current.UnselectAllTable();
      Toast.fire(ToastObject("Se han enviado con éxito las piezas", "success"));
      onCallApi();
    }
  }, [apiData.data?.postConfirmation]);

  const onTabSelected = (name) => {
    if (name === "Enviar piezas para producción") {
      // cambiar nombre
      setTitle("Enviar piezas para producción");
      onChangeQuery({ routeId: "1" });
      const index = buttons.findIndex(
        (b) => b.name === "Enviar a" || b.name === "Enviar"
      );
      if (index > -1) {
        const button = buttons[index];
        button.name = "Aprobar";
        button.class = "success";
        button.icon = "check";
        setButtons([...buttons]);
      }
    } else if (name === "Piezas de metrología") {
      setTitle("Piezas de metrología");
      onChangeQuery({ routeId: "4" });
      const index = buttons.findIndex(
        (b) => b.name === "Enviar" || b.name === "Aprobar"
      );
      if (index > -1) {
        const button = buttons[index];
        button.name = "Enviar a";
        button.class = "primary";
        button.icon = "share-square";
        setButtons([...buttons]);
      }
    } else {
      setTitle("Piezas de tratamiento");
      onChangeQuery({ routeId: "7" });
      const index = buttons.findIndex(
        (b) => b.name === "Enviar a" || b.name === "Aprobar"
      );
      if (index > -1) {
        const button = buttons[index];
        button.name = "Enviar";
        button.class = "primary";
        button.icon = "share-square";
        setButtons([...buttons]);
      }
    }
  };

  const handleSubmit = async (rowData, item) => {
    if (title === "Enviar piezas para producción") {
      if (Array.isArray(rowData[0])) {
        onMultipleRow(rowData, item);
      } else
        Toast.fire(
          ToastObject(
            "Debes de seleccionar al menos una orden de trabajo",
            "warning"
          )
        );
    } else onSendSingleRow(rowData, item);
  };

  const sendToWareHouse = async (sendObject, workOrder, treatment = false) => {
    if (sendObject.QuantityToMove > 1) {
      let value = await swalConfirmation("number");
      if (!value) return;
      if (parseInt(value) > sendObject.QuantityToMove) {
        Toast.fire(
          ToastObject(
            `La cantidad máxima que puedes enviar de esta orden es ${sendObject.QuantityToMove}`,
            "error"
          )
        );
        return;
      }

      sendObject.QuantityToMove = parseInt(value);
    }

    const result = await swalConfirmation("confirmMessage", {
      workOrder,
      place: "Almacén",
    });
    if (!result) return;

    if (treatment) {
      dispatch(
        PostApi([sendObject], ChangeRouteService.ChangeRouteWarehouseApi)
      );
    } else dispatch(PostApi([sendObject], MoveNextApi));
  };

  const onSendSingleRow = async (row, button) => {
    const [id, workOrder, quantity] = row;
    const sendObject = {
      ZamtestTraceableTag: id,
      QuantityToMove: parseInt(quantity),
    };

    if (button === "Enviar a") {
      let option = await swalConfirmation("select", {
        planningFinishes: "Tratamiento",
        warehouse: "Almacén",
      });
      if (option === "warehouse") sendToWareHouse(sendObject, workOrder);
      else {
        const result = await swalConfirmation("confirmMessage", {
          workOrder,
          place: "Tratamiento",
        });
        if (!result) return;
        dispatch(ChangeRouteTreatmentMid(sendObject));
      }
    } else if (button === "Enviar")
      sendToWareHouse(sendObject, workOrder, true);
    else dispatch(GetFileMid(workOrder));
  };

  const onMultipleRow = async (rows, button) => {
    var result = {};
    if (Array.isArray(rows) && rows.length === 0) {
      Toast.fire(
        ToastObject("Debes de seleccionar un renglón para continuar", "info")
      );
      return;
    }

    if (button === "Ver PDF") {
      dispatch(moveTraceableMid.VisualizeMultipleFilesMid(rows));
      dataTableRef.current.UnselectAllTable();
    } else if (button === "Aprobar") {
      result = await swalConfirmation("yesno");
      if (result)
        dispatch(moveTraceableMid.SendMultipleTraceableForPlanningMid(rows));
    } else if (button === "Rechazar") {
      result = await swalConfirmation("reject");
      if (result) {
        let values = await swalConfirmation("message");
        if (values) {
          let message = values[1];
          dispatch(
            moveTraceableMid.CancelMultipleTraceableFromPlanning(
              rows,
              message,
              places.Planning
            )
          );
        }
      }
    }
  };

  const onSelect = (value) => {
    // filtrar por orden de trabajo
    dataTableRef.current.UnselectAllTable();
    if (value) onChangeQuery({ projectId: value.id });
    else onChangeQuery({ projectId: "" });
  };

  return (
    <>
      <Tab
        tabs={[
          { name: "Enviar piezas para producción", icon: "puzzle-piece" },
          { name: "Piezas de metrología", icon: "ruler" },
          { name: "Piezas de tratamiento", icon: "hands-helping" },
        ]}
        onClick={onTabSelected}
      >
        <DataTable
          ref={dataTableRef}
          data={tableContent}
          count={count}
          visibleColumn={false}
          onClick={handleSubmit}
          buttons={buttons}
          states={traceableFactory.routeStates}
          columnState={9}
          onSort={onSort}
          onChangeQuery={onChangeQuery}
          pageCount={pageCount}
          hasCheckbox={
            title !== "Piezas de tratamiento" &&
            title !== "Piezas de metrología"
          }
          hasBox={false}
          FilterComponent={
            <ProjectFilter title="Filtrar por projecto" onSelect={onSelect} />
          }
        >
          <h1 className="title is-4 is-flex is-justify-content-center">
            {title}
          </h1>
        </DataTable>
      </Tab>

      <br />
    </>
  );
}

export default WithTabsDataTable(PlanningSendPieces, places.Planning);
