const PROJECT_ID = "PROJECT_ID";

export const setProjectId = (data) => ({
  type: PROJECT_ID,
  payload: data ?? {
    id: "",
    code: "",
    name: "",
    clientCode: "",
  },
});

const projectIdReducer = (
  state = {
    id: "",
    code: "",
    name: "",
    clientCode: "",
  },
  action
) => {
  switch (action.type) {
    case PROJECT_ID:
      return action.payload;
    default:
      return state;
  }
};

export default projectIdReducer;
