/* #region  IMPORTS */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Root } from "../../resources/constants/roles";
import NavBar from "../2-systems/s_NavBar";
import Sidebar from "../2-systems/s_Sidebar";
import authService, {
  AuthorizeService,
} from "../api-authorization/AuthorizeService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { links } from "../../resources/constants/links";
/* #endregion */

function LoggedIn(props) {
  const [isRoot, setIsRoot] = useState(false);
  const [role, setRole] = useState("");
  const projectId = useSelector((state) => state.projectId);
  const history = useHistory();

  useEffect(() => {
    const user = authService.getUser();
    setRole(user.role);
    if (user.role === Root) setIsRoot(true);
    else setIsRoot(false);
  }, []);

  const onLogout = () => {
    const auth = new AuthorizeService();
    auth.deleteCookie();
    history.replace(links.login.to);
  };

  return (
    <div className="is-flex is-fullheight" id="App">
      <Sidebar
        pageWrapId="page-wrap"
        outerContainer={"App"}
        role={role}
        projectSelected={projectId.id ? true : false}
      />
      <main style={{ width: "100%", flexGrow: 1 }}>
        <NavBar isRoot={isRoot} onLogout={onLogout} />
        <div id="page-wrap" className="loggedInPage">
          {props.children}
        </div>
      </main>
    </div>
  );
}

export default LoggedIn;
