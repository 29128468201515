import React, { useEffect, useState } from "react";
import ButtonIcon from "../1-blocks/b_ButtonIcon";

function MultiSelect({leftData, rightData, onSubmit = () => {}}) {
  const [leftValues, setLeftValues] = useState([]);
  const [rightValues, setRightValues] = useState([]);

  useEffect(() => {
    setLeftValues(leftData);
    setRightValues(rightData);
  }, [leftData, rightData]);
  const onClickRight = () => {
    let select = document.getElementById('leftSelect')
    let switchedValues = []
    let options = [...select.options]
      .filter((item) => item.selected)
      .map((item) => item.value);
    setLeftValues([
      ...leftValues.filter(function (value) {
        for (var i = 0; i < options.length; i++) {
          if (options[i] === value.name){ 
            switchedValues.push(value)
            return false;
          }
        }
        return true;
      }),
    ]);
    setRightValues([...rightValues, ...switchedValues ])
  };
  const onClickLeft = () => {
    let select = document.getElementById('rightSelect')
    let switchedValues = []
    let options = [...select.options]
      .filter((item) => item.selected)
      .map(item => item.value);
    setRightValues([
      ...rightValues.filter(function (value) {
        for (var i = 0; i < options.length; i++) {
          if (options[i] === value.name) {
            switchedValues.push(value)
            return false;
          }
        }
        return true;
      }),
    ]);
    setLeftValues([...leftValues, ...switchedValues ])
  };

  const onHandleSubmit = () => {
    onSubmit(rightValues);
  }
  return (
    <div className="columns">
      <div className="column select is-multiple" data-testid="left">
        <select
          className="fullsize-select"
          key="0"
          id="leftSelect"
          aria-label="leftSelect"
          multiple
          size="8"
        >
          {leftValues.map((value) => (
            <option
              key={value.id}
              value={value.name}
              data-testid="select-option-left"
            >
              {value.name}
            </option>
          ))}
        </select>
      </div>
      <div className="column">
        <div className="columns">
          <div className="column">
            <ButtonIcon
              name="Seleccionar"
              className="button is-info is-fullwidth"
              icon="check-circle"
              onClick={onClickRight}
            />
          </div>
          <div className="column">
            <ButtonIcon
              name="Remover"
              className="button is-danger is-fullwidth"
              icon="window-close"
              onClick={onClickLeft}
            />
          </div>
        </div>
        <button
          className="button is-primary is-fullwidth"
          onClick={onHandleSubmit}
        >
          {"Confirmar"}
        </button>
      </div>
      <div className="column select is-multiple" data-testid="right">
        <select
          className="fullsize-select"
          key="1"
          id="rightSelect"
          aria-label="rightSelect"
          multiple
          size="8"
        >
          {rightValues.map((value) => (
            <option
              key={value.id}
              value={value.name}
              data-testid="select-option-right"
            >
              {value.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default MultiSelect;
