const API_REQUEST = "API_REQUEST";
const API_SUCCESS = "API_SUCCESS";
const API_FAIL = "API_FAIL";

export const apiRequest = () => ({
  type: API_REQUEST,
});

export const apiSuccess = (data) => ({
  type: API_SUCCESS,
  payload: data === null || data === undefined ? {} : data,
});

export const apiFail = (error) => ({
  type: API_FAIL,
  payload: error,
});

const initialState = {
  ok: false,
  errorMessage: "",
  data: {},
  loading: true,
};

const apiDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case API_REQUEST:
      return { ...state, ok: false, loading: true, data: {} };
    case API_SUCCESS:
      return {
        ok: true,
        errorMessage: "",
        data: action.payload,
        loading: false,
      };
    case API_FAIL:
      return {
        ok: false,
        errorMessage: action.payload,
        data: {},
        loading: false,
      };
    default:
      return state;
  }
};

export default apiDataReducer;
