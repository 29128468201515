export const asyncResponse = (status, data, error = "") => ({
  status: status,
  data: data,
  error: error,
});

export const POST = "post";
export const GET = "get";
export const PUT = "put";
export const DELETE = "delete";
export const FORM = "form";
