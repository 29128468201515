import { createStore, applyMiddleware } from "redux";
import rootReducer from "./rootReducer";
import { loadState, saveState } from "../Helpers/localStorage";
import throttle from "lodash/throttle";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const persistedState = loadState();

const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(thunk))
);

store.subscribe(
  throttle(() => {
    saveState({
      route: store.getState().route,
      projectId: store.getState().projectId,
      dataTableSelection: store.getState().dataTableSelection,
    });
  }, 1000)
);

export default store;
