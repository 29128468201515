import React, { useEffect } from "react";
import DataTable from "../2-systems/s_DataTable";
import { GetTraceableToSendMid } from "../../redux/middleware/traceables.mid";
import { useParams } from "react-router";
import { sortingTraceable } from "../../Helpers/Factory/traceableFactory";
import useMounted from "../../hooks/useMounted";
import { refresh_route } from "../../redux/ducks/route";
import routes from "../../resources/constants/routes";
import { links } from "../../resources/constants/links";
import WithTablesData from "../HOC/WithTableData";
import ProjectFilter from "../2-systems/s_projectFilter";

function GetTraceablesOnPlace({
  tableContent,
  onSort,
  pageCount,
  onChangeQuery,
  dispatch,
  count,
  query,
}) {
  const mounted = useMounted();
  const { place } = useParams();
  useEffect(() => {
    if (mounted) {
      dispatch(
        refresh_route(
          routes[links.receivePieces.name],
          `/receiveTraceables/${place}`
        )
      );
    }
  }, [place, mounted, dispatch]);

  const onSelect = (value) => {
    // filtrar por orden de trabajo
    if (value) onChangeQuery(query, { projectId: value.id });
    else onChangeQuery(query, { projectId: "" });
  };

  return (
    <div className="is-flex is-justify-content-center">
      <DataTable
        data={tableContent}
        count={count}
        visibleColumn={false}
        onSort={onSort}
        onChangeQuery={onChangeQuery}
        pageCount={pageCount}
        FilterComponent={
          <ProjectFilter title="Filtrar por projecto" onSelect={onSelect} />
        }
      >
        <h1 className="title is-4 is-flex is-justify-content-center">
          Almacén
        </h1>
      </DataTable>
    </div>
  );
}

export default WithTablesData(
  GetTraceablesOnPlace,
  GetTraceableToSendMid,
  null,
  sortingTraceable,
  "ApiMethod"
);
