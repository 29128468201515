/* #region  IMPORTS */
import {
  buildProject,
  buildProjectForSelect,
  buildProjectForTable,
} from "../../Helpers/Factory/projectFactory";
import { workOrderStatus } from "../../Helpers/Factory/workOrderFactory";
import { OK } from "../../resources/constants/messages";
import * as roles from "../../resources/constants/roles";
import { GetTraceablesOnPlaceApi } from "../../Services/MoveTraceableService";
import {
  GetPiecesQuantityApi,
  GetPiecesQuantityMetrologApi,
  GetProducedPiecesByDayApi,
} from "../../Services/ProductivityDataService";
import {
  GetProjectApi,
  GetProjectsApi,
  GetProjectsWithSpecApi,
} from "../../Services/ProjectService";
import { GetWorkOrdersByProjectApi } from "../../Services/WorkOrderService";
import { apiFail, apiRequest, apiSuccess } from "../ducks/apiData";
import { CreateData } from "./httpMethodApi";
import * as places from "../../resources/constants/places";
import { GetUsersByRoleApi } from "../../Services/SuperUserService";
/* #endregion */

export const GetProjectsMid = (query, name, role) => async (dispatch) => {
  dispatch(apiRequest());
  var result = await GetProjectsWithSpecApi(query);
  let filteredProjects = [];
  if (result.status === OK) {
    if (role === roles.Design) {
      result.data.data.forEach((project) =>
        project.designers.map((designer) => {
          if (designer.userName === name) filteredProjects.push(project);
          return true;
        })
      );
      let traceables = CreateData(filteredProjects, buildProjectForTable);
      result.data.data = traceables;
      dispatch(apiSuccess(result.data));
    } else {
      let traceables = CreateData(result.data.data, buildProjectForTable);
      result.data.data = traceables;
      dispatch(apiSuccess(result.data));
    }
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const GetProjectsForSelectMid = () => async (dispatch) => {
  dispatch(apiRequest());
  var result = await GetProjectsApi();
  if (result.status === OK) {
    dispatch(
      apiSuccess({
        projectSelect: CreateData(result.data, buildProjectForSelect),
      })
    );
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const GetProjectsForLineChartMid =
  (id1, id2, date1 = new Date(), date2 = new Date()) =>
  async (dispatch) => {
    dispatch(apiRequest());
    let projects = [];
    let dates = {
      InitialDay: date1,
      FinalDay: date2,
    };
    var result = await GetProducedPiecesByDayApi(id1, dates);
    if (result.status === OK) {
      projects.push(result.data);
      result = await GetProducedPiecesByDayApi(id2, dates);
      if (result.status === OK) {
        projects.push(result.data);
        dispatch(
          apiSuccess({
            productivity: projects,
          })
        );
      } else dispatch(apiFail({ error: result.error, status: result.status }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  };

export const GetProjectsForPieChartMid = (id1, id2) => async (dispatch) => {
  dispatch(apiRequest());
  let projects = {
    quality: [],
    metrology: [],
  };
  var result = await GetPiecesQuantityApi(id1);
  if (result.status === OK) {
    projects.quality.push({
      OK: result.data.item1,
      NOK: result.data.item2,
    });
    result = await GetPiecesQuantityApi(id2);
    if (result.status === OK) {
      projects.quality.push({
        OK: result.data.item1,
        NOK: result.data.item2,
      });
      result = await GetPiecesQuantityMetrologApi(id1);
      if (result.status === OK) {
        projects.metrology.push({
          OK: result.data.item1,
          NOKPass: result.data.item3,
          NOK: result.data.item2,
        });
        result = await GetPiecesQuantityMetrologApi(id2);
        if (result.status === OK) {
          projects.metrology.push({
            OK: result.data.item1,
            NOKPass: result.data.item3,
            NOK: result.data.item2,
          });
          dispatch(
            apiSuccess({
              info: projects,
            })
          );
        } else
          dispatch(apiFail({ error: result.error, status: result.status }));
      } else dispatch(apiFail({ error: result.error, status: result.status }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const GetProjectMid = (projectId) => async (dispatch) => {
  dispatch(apiRequest());
  let projectInfo = {
    project: {},
    table: {},
    progress: {},
    quality: {},
    metrology: {},
    productivity: {},
    auxiliar: {},
  };
  var result = await GetProjectApi(projectId);
  if (result.status === OK) {
    projectInfo.project = buildProject(result.data);
    result = await GetWorkOrdersByProjectApi(projectId);
    if (result.status === OK) {
      let infoWorkOrders = {
        created: 0,
        cancelled: 0,
        inProduction: 0,
        metrology: 0,
        Manufactured: 0,
        finishOk: 0,
        treatment: 0,
        pendant: 0,
        obsolete: 0,
        incomplete: 0,
        planning: 0,
        maqZam: 0,
      };
      result.data.forEach((item) => {
        switch (item.status) {
          case workOrderStatus.created:
            infoWorkOrders.created += 1;
            break;
          case workOrderStatus.cancelled:
            infoWorkOrders.cancelled += 1;
            break;
          case workOrderStatus.inProduction:
            infoWorkOrders.inProduction += 1;
            break;
          case workOrderStatus.metrology:
            infoWorkOrders.metrology += 1;
            break;
          case workOrderStatus.Manufactured:
            infoWorkOrders.Manufactured += 1;
            break;
          case workOrderStatus.finishOk:
            infoWorkOrders.finishOk += 1;
            break;
          case workOrderStatus.treatment:
            infoWorkOrders.treatment += 1;
            break;
          case workOrderStatus.pendant:
            infoWorkOrders.pendant += 1;
            break;
          case workOrderStatus.obsolete:
            infoWorkOrders.obsolete += 1;
            break;
          case workOrderStatus.incomplete:
            infoWorkOrders.incomplete += 1;
            break;
          case workOrderStatus.planning:
            infoWorkOrders.planning += 1;
            break;
          case workOrderStatus.maqZam:
            infoWorkOrders.maqZam += 1;
            break;
          default:
            break;
        }
      });
      let quantity = result.data.length;
      let rest = quantity - infoWorkOrders.finishOk - infoWorkOrders.cancelled;
      projectInfo.progress = {
        Progreso: infoWorkOrders.finishOk,
        Pendiente: rest,
      };
      projectInfo.table = infoWorkOrders;
      result = await GetPiecesQuantityApi(projectId);
      if (result.status === OK) {
        projectInfo.quality = {
          OK: result.data.item1,
          NOK: result.data.item2,
        };
        result = await GetPiecesQuantityMetrologApi(projectId);
        if (result.status === OK) {
          projectInfo.metrology = {
            OK: result.data.item1,
            NOKPass: result.data.item3,
            NOK: result.data.item2,
          };
          let today = new Date();
          let finalDay = new Date();
          finalDay.setDate(today.getDate() - 10);

          let dates = {
            InitialDay: finalDay,
            FinalDay: today,
          };
          result = await GetProducedPiecesByDayApi(projectId, dates);
          if (result.status === OK) {
            projectInfo.productivity = result.data;
            result = await GetTraceablesOnPlaceApi(places.Auxiliar);
            if (result.status === OK) {
              projectInfo.auxiliar = {
                Auxiliar: result.data.length,
              };
              result = await GetTraceablesOnPlaceApi(places.Production);
              if (result.status === OK) {
                projectInfo.auxiliar = {
                  ...projectInfo.auxiliar,
                  Producción: result.data.length,
                };
                result = await GetTraceablesOnPlaceApi(
                  places.ExternalMachining
                );
                if (result.status === OK) {
                  projectInfo.auxiliar = {
                    ...projectInfo.auxiliar,
                    "Proveedor externo": result.data.length,
                  };
                  dispatch(apiSuccess(projectInfo));
                } else
                  dispatch(
                    apiFail({ error: result.error, status: result.status })
                  );
              } else
                dispatch(
                  apiFail({ error: result.error, status: result.status })
                );
            } else
              dispatch(apiFail({ error: result.error, status: result.status }));
          } else
            dispatch(apiFail({ error: result.error, status: result.status }));
        } else
          dispatch(apiFail({ error: result.error, status: result.status }));
      } else dispatch(apiFail({ error: result.error, status: result.status }));
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};

export const GetProjectForEdit = (id) => async (dispatch) => {
  dispatch(apiRequest());
  let project = {};
  let result = await GetProjectApi(id);
  if (result.status === OK) {
    project = result.data;
    result = await GetUsersByRoleApi(roles.ProjectManager);
    if (result.status === OK) {
      dispatch(
        apiSuccess({
          project,
          managers: result.data,
        })
      );
    } else dispatch(apiFail({ error: result.error, status: result.status }));
  } else dispatch(apiFail({ error: result.error, status: result.status }));
};
