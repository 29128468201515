export const recordStatus = {
  Undefined: 0,
  OnDelivery: 1,
  Deliverated: 2,
  Terminated: 3,
  Canceled: 4,
  Rejected: 5,
  Created: 6,
  Lost: 7,
  ChangeRoute: 8,
  Updated: 9,
  PiecesOk: 10,
  PiecesOkWithDefect: 11,
  PiecesWithoutInspection: 12,
  RejectedByAuxiliar: 13,
};
