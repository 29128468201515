export const Design = "Design";
export const Quality = "Quality";
export const Planning = "Planning";
export const Auxiliar = "Auxiliar";
export const Metrology = "Metrology";
export const Completed = "Completed";
export const Finished = "Finished";
export const Production = "Production";
export const ExternalMachining = "ExternalMachining";
export const AllPlaces = [
  Design,
  Quality,
  Planning,
  Auxiliar,
  Planning,
  Metrology,
  Completed,
  Finished,
  Production,
  ExternalMachining,
];
